import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
  styleUrls: ["./input-text.component.scss"],
})
export class InputTextComponent {
  /**
   * Text that should be displayed above the input
   */
  @Input() label: String = "";

  /**
   * Error message
   */
  @Input() errorText: String = "";

  /**
   * Placeholder
   */
  @Input() placeholder: String = "";

  /**
   * Input type
   */
  @Input() type = "text";

  /**
   * Sets the input to required
   */
  @Input() required = false;

  /**
   * changes the input to valid/invalid
   */
  @Input() invalid: boolean = true;

  /**
   * Sets the classes directly
   */
  @Input() classes = "";

  /**
   * main output
   */
  @Output() currentValue: EventEmitter<String> = new EventEmitter<String>();

  public value: String = "";

  public valueChanged() {
    this.currentValue.emit(this.value);
  }
}
