import { createAction, props } from "@ngrx/store";
import { UserPreferenceType } from "./user.type";
import { EmailRecipient, UserPreference } from "./user";

export const enum USER_ACTIONS {
  USER_LOAD_USER = "[USER] LOAD_USER",
  USER_LOAD_DASHBOARD = "[USER] LOAD_DASHBOARD",
  USER_LOAD_DASHBOARD_SUCCESS = "[USER] LOAD_DASHBOARD_SUCCESS",
  USER_LOAD_DASHBOARD_ERROR = "[USER] LOAD_DASHBOARD_ERROR",

  USER_SET_PREFERENCES = "[USER] SET_PREFERENCES",
  USER_SET_PREFERENCES_SUCCESS = "[USER] SET_PREFERENCES_SUCCESS",
  USER_SET_PREFERENCES_CANCEL = "[USER] SET_PREFERENCES_CANCEL",
  USER_SET_PREFERENCES_ERROR = "[USER] SET_PREFERENCES_ERROR",

  USER_SET_FILTER = "[USER] SET_FILTER",
  USER_SET_FILTER_TAGS = "[USER] SET_FILTER_TAGS",
  USER_SET_FILTER_SUCCESS = "[USER] SET_FILTER_SUCCESS",

  USER_TOGGLE_EMAIL_NOTIFICATIONS = "[USER] USER_TOGGLE_EMAIL_NOTIFICATIONS",
  USER_TOGGLE_EMAIL_NOTIFICATIONS_SUCCESS = "[USER] TOGGLE_EMAIL_NOTIFICATIONS_SUCCESS",
  USER_TOGGLE_EMAIL_NOTIFICATIONS_ERROR = "[USER] ENABLE_EMAIL_NOTIFICATIONS_ERROR",
}

export const loadUser = createAction(
  USER_ACTIONS.USER_LOAD_USER,
  props<{
    user: any;
  }>()
);

export const loadDashboard = createAction(
  USER_ACTIONS.USER_LOAD_DASHBOARD,
  props<{
    device_id: string;
    os_infos: string;
    field_firebase_device_token: string;
    status: number;
  }>()
);

export const loadDashboardSuccess = createAction(
  USER_ACTIONS.USER_LOAD_DASHBOARD_SUCCESS,
  props<{
    response: any;
  }>()
);

export const loadDashboardError = createAction(
  USER_ACTIONS.USER_LOAD_DASHBOARD_ERROR,
  props<{
    error: any;
  }>()
);

export const setUserFilterFederalState = createAction(
  USER_ACTIONS.USER_SET_FILTER,
  props<{
    ids: Array<string>;
  }>()
);
export const setUserFilterTags = createAction(
  USER_ACTIONS.USER_SET_FILTER_TAGS,
  props<{
    ids: Array<string>;
  }>()
);
export const setUserPreferences = createAction(
  USER_ACTIONS.USER_SET_PREFERENCES,
  props<{
    id: UserPreferenceType;
    value: Array<number> | Boolean;
  }>()
);

export const cancelSetUserPreferences = createAction(
  USER_ACTIONS.USER_SET_PREFERENCES_CANCEL
);

export const setUserPreferencesSuccess = createAction(
  USER_ACTIONS.USER_SET_PREFERENCES_SUCCESS,
  props<{
    response: any;
    payload: UserPreference;
  }>()
);

export const setUserPreferencesError = createAction(
  USER_ACTIONS.USER_SET_PREFERENCES_ERROR,
  props<{
    error: any;
  }>()
);

export const toggleEmailNotifications = createAction(
  USER_ACTIONS.USER_TOGGLE_EMAIL_NOTIFICATIONS,
  props<{
    value: {
      status: string;
      subscribed_federal_states: Array<string>;
    };
  }>()
);

export const toggleEmailNotificationsSuccess = createAction(
  USER_ACTIONS.USER_TOGGLE_EMAIL_NOTIFICATIONS_SUCCESS,
  props<{
    value: EmailRecipient;
  }>()
);

export const toggleEmailNotificationsError = createAction(
  USER_ACTIONS.USER_TOGGLE_EMAIL_NOTIFICATIONS_ERROR,
  props<{
    error: any;
  }>()
);
