<div class="info_card__header">
    <div class="caption"
         *ngIf="infoDate else noDate">{{infoDate | date:dateTimeStandard}} {{ "STRINGS.TIME" | translate}}
        <ng-container *ngIf="federalStates && federalStates.length">
            –
            <app-federal-state [ids]="federalStates"></app-federal-state>

        </ng-container>
    </div>


    <!-- in case of no date, show a no date info -->
    <ng-template #noDate>
        <div class="caption">{{ "STRINGS.DRAFT" | translate}}</div>
    </ng-template>
    <div *ngIf="new" class="caption-flag">neu</div>
    <app-svg-icon
            *ngIf="declined"
            class="icon"
            [src]="'icons/24/error.svg'"
            [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
    ></app-svg-icon>
</div>
<div class="info_card__content">
    <div class="card-title">{{title}}</div>
    <app-svg-icon
            class="icon"
            [src]="'icons/24/arrow-right.svg'"
            [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
    ></app-svg-icon>
</div>
