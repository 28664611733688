import { Injectable, NgZone } from "@angular/core";
import {
  NavigationStart,
  Router,
  RouterEvent,
  Event as NavigationEvent,
} from "@angular/router";
import { Subject, timer } from "rxjs";
import { BasePageAnimationDirection } from "../animations/animation-base/animation.types";
import { State, Store } from "@ngrx/store";
import { AppStore } from "../store/appStore";
import { cleanUpEntities } from "../io/entity/store/entity.actions";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public animationDirection = new Subject<BasePageAnimationDirection>();

  // defaults to Fade Animation
  private _animationDirection = BasePageAnimationDirection.FADE;

  constructor(
    private _router: Router,
    private _store: Store<AppStore>,
    private zone: NgZone
  ) {
    // clean up entitites deletes all entities
    // that have the ready_for_deletion-property set
    this._router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        this._store.dispatch(cleanUpEntities());
      }
    });
  }

  public setBasePageAnimationDirection(direction: BasePageAnimationDirection) {
    this._animationDirection = direction;
  }

  public getBasePageAnimationDirection(): BasePageAnimationDirection {
    return this._animationDirection;
  }

  public navigateByUrl(
    url: string,
    animationDirection?: BasePageAnimationDirection,
    param?: any,
    from?: string
  ) {
    let direction = animationDirection
      ? animationDirection
      : this._animationDirection;

    this.setBasePageAnimationDirection(direction);

    if (!param && this._queryParams[url]) {
      param = this._queryParams[url];
    }

    if (from) {
      param = { ...{ from: from }, ...param };
    }

    // emit current animation data
    this.animationDirection.next(direction);
    if (param) {
      timer(1).subscribe((i) => {
        this.zone.run(() => {
          this._router.navigate([url], { queryParams: param });
        });
      });
    } else {
      timer(1).subscribe((i) => {
        this.zone.run(() => {
          this._router.navigate([url]);
        });
      });
    }
  }

  private _queryParams: { [key: string]: any } = {};

  public setQueryParams(param: any, page: string) {
    this._queryParams[page] = param;
  }
}
