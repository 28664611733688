import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { baseModalAnimationsEnterLeaveBottom } from "../base-modal/base-modal.animations";
import { ApiConnectorMedicationService } from "../../../io/medifinder/api-connector-medication.service";
import { ButtonOutput } from "../../button/button-output";
import { listAnimation } from "../../../animations/animation-base/element-animation";
import { Observable, Subscription } from "rxjs";
import { PharmacyEntity } from "../../../io/entity/types/entity";
import { AppStore } from "../../../store/appStore";
import { Store } from "@ngrx/store";
import {
  GetUserDisplayName,
  GetUserPharmacies,
} from "../../../store/user/user.selector";
import { transformPhoneNumber } from "../../../helper/helper-functions";
import { ViewHelperService } from "../../../io/entity/view-helper/view-helper.service";
import { selectEntitiesByIds } from "../../../io/entity/store/entity.selector";
import { SearchIcons } from "../../input/input-search-text/input-search-text.component";
import { Capacitor } from "@capacitor/core";
import { ButtonType } from "../../button/button.type";

enum CreationState {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
}

@Component({
  selector: "app-new-medifinder-search-modal",
  templateUrl: "./new-medifinder-search-modal.component.html",
  styleUrls: ["./new-medifinder-search-modal.component.scss"],
  animations: [baseModalAnimationsEnterLeaveBottom, listAnimation],
})
export class NewMedifinderSearchModalComponent
  extends BaseModalComponent
  implements OnDestroy
{
  public result: Array<any> | undefined = [];

  public drugSearchInput: string;
  public searchDrugModal = false;

  public selectedDrug: Drug | undefined;

  public userDisplayName$: Observable<string>;

  private _subscriptions: Subscription[] = [];

  public pharmacies$: Observable<PharmacyEntity[]>;
  public selectedPharmacy: string | undefined;

  public creationState: CreationState = CreationState.INIT;

  public errortext = "";

  public mobile = false;

  public contentInit = false;

  @Output() reloadView: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private _apiConnectorMedifinderService: ApiConnectorMedicationService,
    private _store: Store<AppStore>,
    private _viewHelperService: ViewHelperService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.userDisplayName$ = this._store.select(GetUserDisplayName);

    this.getPharmacies();

    this.mobile =
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android";

    this.mobile = true;
  }

  onDrugInput(value: ButtonOutput) {
    this.drugSearchInput = value.data.value;

    if (this.drugSearchInput.length < 3) {
      this.result = [];

      return;
    }

    this.searchDrugModal = true;
    this._apiConnectorMedifinderService
      .austriaCodexSearch(this.drugSearchInput)
      .subscribe({
        next: (res) => {
          this.searchDrugModal = false;
          try {
            this.result = res.data.result;
          } catch (e) {
            this.result = [];
          }
        },
        error: (error) => {
          this.searchDrugModal = false;
        },
      });
  }

  onSelectDrug($event: InputEvent, drug: Drug) {
    $event.preventDefault();
    this.selectedDrug = drug;
  }

  getPharmacies() {
    this._subscriptions.push(
      this._store.select(GetUserPharmacies).subscribe((res) => {
        if (res && res.length) {
          const view = this._viewHelperService.getViewHelper(
            "medifinder-pharmacy",
            false
          );

          view.setFilter([]);
          view.setUser({ id: res.join(",") });
          view.setView("pharmacies_api", "default");
          view.setActive(false);

          this.pharmacies$ = view.getView$(selectEntitiesByIds) as Observable<
            PharmacyEntity[]
          >;
        }
      })
    );
  }

  createNewSearch() {
    if (!this.selectedPharmacy || !this.selectedDrug) return;

    this.creationState = CreationState.LOADING;
    this._apiConnectorMedifinderService
      .createSearch({
        pzn: this.selectedDrug.pzn,
        owner_pharmacy: this.selectedPharmacy,
        product_name: this.selectedDrug.productname,
        package_name: this.selectedDrug.packagename,
      })
      .subscribe({
        next: (result) => {
          this.reloadView.emit(new Date().getTime());
          this.creationState = CreationState.SUCCESS;
        },
        error: (error: Error) => {
          this.errortext = error.message;
          this.creationState = CreationState.ERROR;
        },
      });
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      try {
        subs.unsubscribe();
      } catch (e) {}
    });
  }

  protected readonly SearchIcons = SearchIcons;
  protected readonly transformPhoneNumber = transformPhoneNumber;
  protected readonly encodeURIComponent = encodeURIComponent;
  protected readonly CreationState = CreationState;

  override Close() {
    this.errortext = "";
    this.creationState = CreationState.INIT;
    this.selectedPharmacy = undefined;
    this.selectedDrug = undefined;
    this.searchDrugModal = false;
    this.result = undefined;
    super.Close();
  }

  protected readonly ButtonType = ButtonType;

  public trackBy(index: number, pharmacy: PharmacyEntity) {
    return pharmacy.id;
  }

  public onContentInit(pharmacies: Array<PharmacyEntity>) {
    if (!this.selectedPharmacy && pharmacies.length) {
      this.selectedPharmacy = pharmacies[0].id;
    }
  }
}

interface Drug {
  packagename: string;
  productname: string;
  pzn: number;
}
