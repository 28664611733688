import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "./auth.actions";

import cloneDeep from "lodash-es/cloneDeep";
import { AUTH_STATUS } from "../auth.states";

export interface State {
  status: string;
  token: string;
}

export const initialState: State = {
  status: AUTH_STATUS.INIT,
  token: "",
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.authLoginSuccess, (state, action) => {
    state = cloneDeep(state);
    state.status = AUTH_STATUS.SUCCESS;
    state.token = action.token ? action.token : "";
    return state;
  }),
  on(AuthActions.authLoginError, (state, action) => {
    state = cloneDeep(state);
    state.status = AUTH_STATUS.ERROR;
    return state;
  })
);

export function AuthReducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
