import { Action, createAction, props } from "@ngrx/store";
import { PushNotificationSchema } from "@capacitor/push-notifications";

export const enum AlertsActions {
  ALERT_ERROR = "ALERT_E",
  ALERT_INFO = "ALERT_I",
  ALERT_SUCCESS = "ALERT_S",
  ALERT_UPDATE = "ALERT_U",
}

export enum ALERT_TYPES {
  ALERT = "Alert",
  INFO = "Info",
  UPDATE = "Update",
  SUCCESS = "Success",
}

export interface Alert {
  id: string;
  duration: any;
  type: any;
  message: string;
  title: string;
  data?: {
    action: "push_notification_click";
    notification?: PushNotificationSchema;
  };
}

export const alertError = createAction(
  AlertsActions.ALERT_ERROR,
  props<{
    title: string;
    message: string;
    duration: number;
  }>()
);

export const alertInfo = createAction(
  AlertsActions.ALERT_INFO,
  props<{
    title: string;
    message: string;
    duration: number;
    data?: {
      action: "push_notification_click";
      notification?: PushNotificationSchema;
    };
  }>()
);

export const alertSuccess = createAction(
  AlertsActions.ALERT_SUCCESS,
  props<{
    title: string;
    message: string;
    duration: number;
  }>()
);

export const alertUpdate = createAction(
  AlertsActions.ALERT_UPDATE,
  props<{
    versionNew: string;
  }>()
);
