import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { ViewType } from "../../io/entity/types/entityTypes";
import { ModerationSate } from "../../store/news-post/news-post";
import { EntityService } from "../../io/entity/entity.service";
import { MODAL_ACTIONS, ModalAction } from "../../ui/modal/modal-actions";
import {
  ViewService,
  ViewsExposedFilter,
  ViewsExposedUser,
} from "../../io/entity/view.service";
import { ButtonOutput } from "../../ui/button/button-output";
import { Store } from "@ngrx/store";
import {
  GetDashboardLoadingStatus,
  GetUserFederalStatesSettingForFilter,
  GetUserId,
} from "../../store/user/user.selector";
import { first, Subject, Subscription, takeUntil } from "rxjs";
import { APP_INIT_STATES } from "../../store/status/status";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { ViewHelper } from "../../io/entity/view-helper/view-helper";
import { selectNewsPostByIds } from "../../store/news-post/news-post.selector";
import { listAnimation } from "../../animations/animation-base/element-animation";
import {
  base64UrlDecode,
  base64UrlEncode,
} from "../../helper/helper-functions";
import { getInfoOverviewTabs } from "../../store/approval/approval.selector";
import { fetchApprovalMetaData } from "../../store/approval/approval.actions";
import { CacheService } from "../../io/entity/cache.service";
import { purgeEntities } from "../../io/entity/store/entity.actions";
import { GetRefreshState } from "../../store/status/store/status.selector";
import { RefreshState } from "../../store/status/store/status.actions";
import Bugsnag from "@bugsnag/js";
import { isArray } from "lodash-es";
import {
  buildArgumentForViewService,
  buildFilterArrayForViewService,
  getFederalStatesFilter,
} from "./info-overview-helper";
import { DeviceService } from "../../io/device/device.service";
import { GenericOverviewComponent } from "../generic-overview/generic-overview.component";

@Component({
  selector: "app-info-overview",
  templateUrl: "./info-overview.component.html",
  styleUrls: [
    "./info-overview.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [listAnimation],
})
export class InfoOverviewComponent
  extends GenericOverviewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private _killFilterSubscription = new Subject();
  public newsPosts$: any;
  public tabs$: any;
  public DashboardRoutesType = DashboardRoutesType;
  public filterModalIsOpen: Boolean = false;
  // current Filters
  private _currentModalFilter: Array<ViewsExposedFilter> = [];
  public currentTextFilter = {
    id: ViewType.SearchText,
    value: "",
  };
  public currentModerationState = ModerationSate.PUBLISHED;

  public viewHelper: ViewHelper;

  private _scrollTo: string;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _entityService: EntityService,
    private _viewService: ViewService,
    protected override _viewHelperService: ViewHelperService,
    protected override _store: Store,
    protected override _navigationService: NavigationService,
    private _cacheService: CacheService,
    private _deviceService: DeviceService,
    protected override _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_navigationService, _changeDetectorRef, _store, _viewHelperService);

    this._appActiveSubscription = this._deviceService.appBecameActive.subscribe(
      () => {
        this._store.dispatch(fetchApprovalMetaData());
      }
    );
  }

  override ngOnInit() {
    super.ngOnInit();

    /**
     * get possible tabs
     */
    this.tabs$ = this._store.select(getInfoOverviewTabs());

    /**
     * check tabs for invalid state
     */
    this._subscriptions.push(
      this.tabs$.subscribe((tabs: any) => {
        if (tabs && isArray(tabs) && tabs.length) {
          const valid = tabs.find(
            (tab: { value: string }) =>
              tab.value === this.currentModerationState
          );

          if (!valid) {
            Bugsnag.leaveBreadcrumb(
              `Invalid tab, switching to published;${JSON.stringify(tabs)};${
                this.currentModerationState
              }`
            );
            this.currentModerationState = ModerationSate.PUBLISHED;
            this.changeView();
          }
        }
      })
    );

    this._subscriptions.push(
      this._route.queryParams.pipe(first()).subscribe((params) => {
        if (params["tab"]) {
          this.currentModerationState = params["tab"];
        }
      })
    );

    this._store
      .select(GetUserFederalStatesSettingForFilter)
      .pipe(takeUntil(this._killFilterSubscription))
      .subscribe((filter) => {
        if (filter && filter.length > 0) {
          this._killFilterSubscription.next(true);
          filter.forEach((filt) => {
            if (filt.id && !!filt.active) {
              this._currentModalFilter.push({
                id: "[federal-state][]",
                value: filt.id,
              });
            }
          });
        }
      });

    this._subscriptions.push(
      getFederalStatesFilter(this._store).subscribe((filter) => {
        this._currentModalFilter = filter;
        this._initFilterSet = true;
        if (this._userIdSet) {
          this.changeView();
        }
      })
    );

    this._subscriptions.push(
      this._store.select(GetRefreshState).subscribe((refresh) => {
        if (refresh) {
          this.refresh();
          this._store.dispatch(RefreshState({ refresh: false }));
        }
      })
    );

    // get url params and check which filters are set
    this._route.queryParams.pipe(first()).subscribe((params) => {
      if (params["filter"]) {
        let decodedParams = this._viewService.buildURI(
          base64UrlDecode(params["filter"])
        );
        this.SetInitFilter(
          decodedParams.exposedFilter,
          decodedParams.exposedUser
        );
      }
      if (params["from"]) {
        this._scrollTo = params["from"];
      }
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  override onUserSet() {
    console.log("onUserSet");
    if (this._initFilterSet) {
      this.changeView();
    }
  }

  ngAfterViewInit() {
    if (this._scrollTo) {
      const el = document.getElementById(this._scrollTo);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  }

  override SetEvent(
    event: ButtonOutput,
    routeType?: DashboardRoutesType,
    id?: number
  ) {
    // first check which button action type
    switch (event.type) {
      case ButtonActionsType.Click:
        if (routeType) {
          // navigate to url based on route type and add id if existing
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            BasePageAnimationDirection.LEFT
          );
        }
        break;
      case ButtonActionsType.Back:
        if (routeType) {
          // navigate to url based on route type and add id if existing
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            BasePageAnimationDirection.RIGHT
          );
        }
        break;
      case ButtonActionsType.Input:
        this.currentTextFilter = event.data;

        this.changeView();
        break;
      case ButtonActionsType.Tab:
        this.currentModerationState = event.data;

        this.changeView();
        break;
    }
  }

  public SetAction(event: ModalAction) {
    switch (event.action) {
      case MODAL_ACTIONS.filter:
        // set current modal filter
        this._currentModalFilter = event.value;

        this.changeView();
        break;
    }
  }

  public openModal() {
    this.filterModalIsOpen = true;
  }

  public AddCurrentFilterToUrlParams(filter?: any) {
    const queryParams = {
      tab: this.currentModerationState,
      filter: base64UrlEncode(filter),
    };

    this._navigationService.setQueryParams(
      queryParams,
      DashboardRoutesType.NewsOverview
    );

    try {
      Bugsnag.leaveBreadcrumb(JSON.stringify(queryParams));
    } catch (e) {}

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: queryParams,
    });
  }

  public SetInitFilter(
    filters: Array<ViewsExposedFilter>,
    user: ViewsExposedUser
  ) {
    filters.forEach((filter) => {
      switch (filter.id) {
        case ViewType.PublishedAtMax:
        case ViewType.PublishedAtMin:
        case ViewType.FederalStates:
        case ViewType.Tags:
          this._currentModalFilter.push({
            id: filter.id,
            value: filter.value,
          });
          break;
        case ViewType.SearchText:
          this.currentTextFilter.value = filter.value;
          break;
        case ViewType.ModerationState:
          // set current tab
          switch (filter.value) {
            case ModerationSate.PUBLISHED:
              this.currentModerationState = user.id
                ? ModerationSate.OWN_PUBLISHED
                : ModerationSate.PUBLISHED;
              break;
            case ModerationSate.WAITING_FOR_APPROVAL:
              this.currentModerationState = user.id
                ? ModerationSate.OWN_WAITING_FOR_APPROVAL
                : ModerationSate.WAITING_FOR_APPROVAL;
              break;
            case ModerationSate.DRAFT:
              this.currentModerationState = filter.value;
              break;
            case ModerationSate.OWN_REJECTED:
              this.currentModerationState = ModerationSate.OWN_REJECTED;
          }
          break;
      }
    });
  }

  public changeView() {
    let filter;

    // all own ignore the filter
    if (this.currentModerationState !== ModerationSate.PUBLISHED) {
      filter = buildFilterArrayForViewService(
        [],
        this.currentTextFilter,
        this.currentModerationState
      );
    } else {
      filter = buildFilterArrayForViewService(
        this._currentModalFilter,
        this.currentTextFilter,
        this.currentModerationState
      );
    }

    const user = buildArgumentForViewService(
      this.currentModerationState,
      this._userID
    );

    //make the viewHelper purgeable when there is a text filter
    //purgeable view helper will be deleted once a new viewHelper is accessed. This helps with performance.
    let purgeable = false;
    try {
      purgeable = this.currentTextFilter.value.length !== 0;
    } catch (e) {}

    this.viewHelper = this._viewHelperService.getViewHelper(
      "info-overview-" +
        base64UrlEncode(JSON.stringify(filter) + JSON.stringify(user)),
      purgeable
    );
    this.viewHelper.setView("global_search", "query");
    this.viewHelper.setFilter(filter);
    this.viewHelper.setUser(user);
    this.viewHelper.setInclude([
      "field_federal_state",
      "field_last_rejected_user",
    ]);
    this.viewHelper.setActive(false);

    this.newsPosts$ = this.viewHelper.getView$(selectNewsPostByIds);

    this.AddCurrentFilterToUrlParams(filter);
  }

  refresh(event: Subject<any> | undefined = undefined, message: string = "") {
    this._store.dispatch(purgeEntities());

    this.changeView();
    if (event) {
      event.next(false);
    }
  }

  onContainerScroll(e: Event): void {
    this.enablePullToRefresh = !(
      e.target && (e.target as HTMLHtmlElement).scrollTop > 50
    );
  }

  protected readonly ModerationSate = ModerationSate;
}
