import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ButtonActionsType } from "../../button/button-actions.type";
import { ButtonOutput } from "../../button/button-output";
import { BackHeaderImageType } from "./back-header.type";
import { NavigationService } from "../../../services/navigation.service";
import { DashboardRoutesType } from "../../../content/dashboard/dashboard-routes.type";
import { BasePageAnimationDirection } from "../../../animations/animation-base/animation.types";

interface SettingsOption {
  tab: string;
  from: DashboardRoutesType;
}

export enum FilterOption {
  enabled = "enabled",
  hidden = "hidden",
  disabled = "disabled",
}

@Component({
  selector: "app-back-header",
  templateUrl: "./back-header.component.html",
  styleUrls: ["./back-header.component.scss"],
})
export class BackHeaderComponent implements OnInit, AfterViewInit {
  /**
   * current page title that gets displayed in header
   */
  @Input() title: string = "";

  @Input() detailView: Boolean = false;

  /**
   * current page title that gets displayed in header
   */
  @Input() buttonImage: BackHeaderImageType = BackHeaderImageType.Default;

  public BackHeaderImageType = BackHeaderImageType;

  public ButtonActions = ButtonActionsType;

  @Input() settingsOption: SettingsOption;
  @Input() filterOption: FilterOption = FilterOption.hidden;

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostBinding("class") get hostClasses() {
    const classList = ["back-header"];
    if (this.detailView) {
      classList.push("detail-view");
    }
    return classList;
  }

  constructor(private _navigationService: NavigationService) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  public SetAction(event: ButtonOutput) {
    switch (event.type) {
      case ButtonActionsType.Click:
        this.action.emit({
          type: ButtonActionsType.Back,
        });
        break;
      default:
        this.action.emit({
          type: event.type,
        });
    }
  }

  public goToSettings() {
    this._navigationService.navigateByUrl(
      DashboardRoutesType.Settings,
      BasePageAnimationDirection.DOWN,
      { tab: this.settingsOption.tab },
      this.settingsOption.from
    );
  }

  protected readonly FilterOption = FilterOption;
}
