import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { GenericDetailComponent } from "../generic-detail/generic-detail.component";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { EntityService } from "../../io/entity/entity.service";
import { AliasService } from "../../io/entity/alias.service";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { NavigationService } from "../../services/navigation.service";
import { Actions, ofType } from "@ngrx/effects";
import { AlertService } from "../../alerts/alert.service";
import { ViewService } from "../../io/entity/view.service";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { DeviceService } from "../../io/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { EntityTypesId } from "../../io/entity/types/entityTypes";
import { CacheRefresh } from "../../io/entity/types/cache";
import { first, Observable } from "rxjs";
import { selectMediFinderSearchById } from "../../store/medifinder/medifinder.selector";
import { MediFinderEntity } from "../../io/entity/types/entity";
import { dateTimeStandard } from "../../config/units";
import { ButtonType } from "../../ui/button/button.type";
import { ModalType } from "../../ui/modal/base-modal/modal.type";
import { ApiConnectorMedicationService } from "../../io/medifinder/api-connector-medication.service";
import { loadEntityError } from "../../io/entity/store/entity.actions";
import { HttpErrorResponse } from "@angular/common/http";

enum AnnulationSteps {
  "INIT",
  "CONFIRM",
  "PROGRESS",
  "ERROR",
  "SUCCESS",
}

@Component({
  selector: "app-medifinder-detail",
  templateUrl: "./medifinder-detail.component.html",
  styleUrls: [
    "./medifinder-detail.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MedifinderDetailComponent
  extends GenericDetailComponent
  implements OnDestroy
{
  public content$;
  public annulationStep = AnnulationSteps.INIT;
  public backToTab = "global";
  public entityError = false;

  constructor(
    private _entityService: EntityService,
    private _aliasService: AliasService,
    protected override _activatedRoute: ActivatedRoute,
    protected override _store: Store,
    protected override _navigationService: NavigationService,
    protected override _actions: Actions,
    protected override _alertService: AlertService,
    protected override _viewService: ViewService,
    protected override _viewHelperService: ViewHelperService,
    private _deviceService: DeviceService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiConnectorMedifinderService: ApiConnectorMedicationService
  ) {
    super(
      _navigationService,
      _activatedRoute,
      _viewService,
      _viewHelperService,
      _actions,
      _alertService,
      _store
    );

    this._route.queryParams.pipe(first()).subscribe((params) => {
      if (params["tab"]) {
        this.backToTab = params["tab"];
      }
    });

    this.content$ = this._entityService.getEntity(
      EntityTypesId.MediFinderSearch,
      this.id,
      selectMediFinderSearchById,
      CacheRefresh.one_minute,
      true
    ) as Observable<MediFinderEntity>;

    this._subscriptions.push(
      this._actions
        .pipe(ofType(loadEntityError.type))
        .subscribe(
          (error: {
            error: HttpErrorResponse;
            data: any;
            type: any;
            suppress?: boolean;
          }) => {
            this.entityError = true;

            this._changeDetectorRef.markForCheck();
          }
        )
    );
  }

  public swipeBack(prevId: string | undefined) {
    if (typeof prevId === "string") {
      this._navigationService.navigateByUrl(
        "/" + DashboardRoutesType.MediFinderDetail + prevId,
        BasePageAnimationDirection.RIGHT
      );
    }
  }

  public swipeNext(nextId: string | undefined) {
    if (typeof nextId === "string") {
      this._navigationService.navigateByUrl(
        "/" + DashboardRoutesType.MediFinderDetail + nextId,
        BasePageAnimationDirection.LEFT
      );
    }
  }

  public SetAction(event: any, tab: string | undefined = undefined) {
    switch (event.type) {
      case ButtonActionsType.Back:
        this._navigationService.navigateByUrl(
          DashboardRoutesType.MediFinder,
          BasePageAnimationDirection.RIGHT,
          tab ? { tab: tab } : undefined,
          this.id
        );
    }
  }

  public contact(medium: string) {
    switch (medium) {
      case "phone":
        // @ts-ignore
        document.getElementById("pharmacy-phone").click();
        break;
      case "email":
        // @ts-ignore
        document.getElementById("pharmacy-email").click();
        break;
    }
  }

  public annulate(id: string) {
    if (this.annulationStep == AnnulationSteps.INIT) {
      this.annulationStep = AnnulationSteps.CONFIRM;
    } else {
      this.annulationStep = AnnulationSteps.PROGRESS;

      this._apiConnectorMedifinderService
        .annulateMedicationSearch(id)
        .subscribe({
          next: (result) => {
            this.annulationStep = AnnulationSteps.SUCCESS;
            this._viewHelperService.purgeViewHelpersWithName("medifinder");
          },
          error: (error) => {
            this.annulationStep = AnnulationSteps.ERROR;
            this._alertService.DispatchInfo(
              "MEDIFINDER.DETAIL.ANNULATION.ERROR.TITLE",
              "MEDIFINDER.DETAIL.ANNULATION.ERROR.MESSAGE"
            );
          },
        });
    }
  }

  backToOverview() {
    this.SetAction(
      { type: ButtonActionsType.Back },
      this.backToTab ? this.backToTab : "global"
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected readonly dateTimeStandard = dateTimeStandard;
  protected readonly ButtonType = ButtonType;
  protected readonly ModalType = ModalType;
  protected readonly AnnulationSteps = AnnulationSteps;
  protected readonly ButtonActionsType = ButtonActionsType;
}
