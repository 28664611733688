import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GuidelineType} from "./guideline.type";

@Component({
  selector: 'app-advice-card',
  templateUrl: './advice-card.component.html',
  styleUrls: ['./advice-card.component.scss']
})
export class AdviceCardComponent implements OnInit {
  /**
   * Visual representation of guideline type
   */
  @Input() guidelineType:GuidelineType = GuidelineType.Cold;

  /**
   * Title of the advice card
   */
  @Input() title:string = "";

  /**
   * New state of advice card
   */
  @Input() new:boolean = false;

  @HostBinding('class') get hostClasses() {
    const classList = ['box'];
    return classList;
  }

  @HostBinding('attr.tabindex')  tabindex = '0';

  constructor() { }

  ngOnInit(): void {
  }

}
