import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { authLoginSuccess } from "../store/status/auth/store/auth.actions";
import { Actions } from "@ngrx/effects";
import { take, takeUntil, takeWhile } from "rxjs";
import { setUserPreferences } from "../store/user/user.actions";
import { InputActionsType } from "../ui/input/input-checkbox/input-actions.type";
import { AppStore } from "../store/appStore";
import { Store } from "@ngrx/store";
import { UserPreferenceType } from "../store/user/user.type";
import {
  GetDashboardLoadingStatus,
  GetUserFederalStatesSettingForDashboard,
  GetUserFederalStatesSettingForPushNotification,
} from "../store/user/user.selector";
import { APP_INIT_STATES } from "../store/status/status";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  private differentVersion = false;

  constructor(private _store: Store<AppStore>) {
    this.differentVersion = this.checkVersion();

    if (this.differentVersion) {
      this.checkAuthentication();
    }
  }

  private checkAuthentication() {
    this._store
      .select(GetDashboardLoadingStatus)
      .subscribe((dashboardLoadingStatus) => {
        if (
          this.differentVersion &&
          dashboardLoadingStatus === APP_INIT_STATES.SUCCESS
        ) {
          this.differentVersion = false;
          localStorage.setItem("version", environment.version);
          this.runMigration();
        }
      });
  }

  private runMigration() {
    console.log("run migration for " + environment.version);

    console.log({
      id: UserPreferenceType.MedifinderPushNotifications,
      value: true,
    });

    switch (environment.version) {
      case "1.1.2 (4)":
        this._store
          .select(GetUserFederalStatesSettingForPushNotification())
          .pipe(take(1))
          .subscribe((federalStates) => {
            this._store.dispatch(
              setUserPreferences({
                id: UserPreferenceType.MedifinderFederalStates,
                value: federalStates
                  .filter((filter: any) => filter.active && !filter.hidden)

                  .map((f: any) => f.id),
              })
            );

            console.log(
              "setting medifinder federal states",
              federalStates
                .filter((filter: any) => filter.active && !filter.hidden)
                .map((f: any) => f.id)
            );
          });
        //run migration
        this._store.dispatch(
          setUserPreferences({
            id: UserPreferenceType.MedifinderPushNotifications,
            value: true,
          })
        );
        console.log("set medifinder push notifications to true");
        break;
      default:
        break;
    }

    localStorage.setItem("version", environment.version);

    console.log("migration done");
  }

  //check localstorage for current app version and compare it with the current version
  private checkVersion(): boolean {
    const currentVersion = localStorage.getItem("version");
    console.log(currentVersion);
    if (currentVersion !== environment.version) {
      return true;
    }
    return false;
  }
}
