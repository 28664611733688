import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../store/status/auth/auth.service";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent {
  constructor(private _router: Router, private _authService: AuthService) {
    this._authService.removeToken();
  }

  login() {
    this._router.navigateByUrl("/");
  }
}
