import { Injectable } from "@angular/core";
import { ApiConnectorAccessService } from "../io/access/api-connector-access.service";
import { AuthService } from "../store/status/auth/auth.service";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { authLoginSuccess } from "../store/status/auth/store/auth.actions";
import { DeviceService } from "../io/device/device.service";
import { ConfigService } from "../config/config.service";
import { CoreConfig } from "../config/core-config.type";
import { BiometricAccessService } from "./biometric-access.service";

@Injectable({
  providedIn: "root",
})
export class AccessService {
  private _config: CoreConfig;
  public blacklist: Array<string> = [];

  public firstActive = true;

  constructor(
    private apiConnectorAccessService: ApiConnectorAccessService,
    private _authService: AuthService,
    private _router: Router,
    private _action$: Actions,
    private _deviceService: DeviceService,
    private _configService: ConfigService,
    private _biometricAccessService: BiometricAccessService
  ) {
    this._config = this._configService.config;

    try {
      const data = localStorage.getItem(
        `${this._config.storage.localStoragePrefix}blacklist`
      );

      if (data) {
        this.blacklist = Array.isArray(JSON.parse(data))
          ? JSON.parse(data)
          : [];
      }
    } catch (e) {
      console.error(e);
    }

    this._action$.subscribe((action) => {
      if (action.type === authLoginSuccess.type) {
        this.checkTokenAndRedirectIfBlacklisted();
      }
    });

    this._deviceService.appBecameActive.subscribe(() => {
      if (!this.firstActive) {
        this.loadAndProcessAccessList(true);
      }
      this.firstActive = false;
    });
  }

  public loadAndProcessAccessList(includeCheck = false) {
    this.apiConnectorAccessService.loadAccessList().subscribe(
      (response) => {
        if (Array.isArray(response.data)) {
          this.blacklist = response.data.map((item) => item.attributes.name);

          localStorage.setItem(
            this._config.storage.localStoragePrefix + "blacklist",
            JSON.stringify(this.blacklist)
          );

          if (includeCheck) {
            this.checkTokenAndRedirectIfBlacklisted();
          }
        }
      },
      (error) => {
        console.log("Error loading access list", error);
      }
    );
  }

  public checkTokenAndRedirectIfBlacklisted() {
    if (this.isBlacklisted(this._authService.user)) {
      this._router.navigateByUrl("/blacklist-error");
    }
  }

  public isBlacklisted(
    token: {
      email: string;
      preferred_username: string;
    } = this._authService.user
  ): boolean {
    if (!this._authService.accessToken) return false;

    return (
      this.checkStringAgainstBlacklist(token.email) ||
      this.checkStringAgainstBlacklist(token.preferred_username)
    );
  }

  private checkStringAgainstBlacklist(str: string): boolean {
    return this.blacklist.includes(str);
  }
}
