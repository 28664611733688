import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { baseModalAnimationsEnterLeaveBottom } from "../../modal/base-modal/base-modal.animations";
import { listAnimation } from "../../../animations/animation-base/element-animation";

@Component({
  selector: "app-input-dropdown",
  templateUrl: "./input-dropdown.component.html",
  styleUrls: ["./input-dropdown.component.scss"],
  animations: [listAnimation],
})
export class InputDropdownComponent implements OnChanges, OnInit {
  /**
   * Text that should be displayed above the input
   */
  @Input() label: String = "";

  /**
   * Error message
   */
  @Input() errorText: String = "";

  /**
   * Placeholder
   */
  @Input() placeholder: String = "";

  /**
   * Input type
   */
  @Input() type = "text";

  /**
   * Sets the input to required
   */
  @Input() required = false;

  /**
   * changes the input to valid/invalid
   */
  @Input() invalid: boolean = true;

  /**
   * Sets the classes directly
   */
  @Input() classes = "";

  @Input() values: Array<{ id: string; text: string }>;

  public dropdownOpen = false;

  /**
   * main output
   */
  @Output() currentValue: EventEmitter<{ id: string; text: string }> =
    new EventEmitter<{ id: string; text: string }>();

  public value: { id: string; text: string };

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  public valueChanged() {
    //this.currentValue.emit(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setValue();
  }

  ngOnInit() {
    this.setValue();
  }

  setValue(value?: { id: string; text: string }) {
    this.value = value ? value : this.values[0];
    this.currentValue.emit(this.value);
    this.dropdownOpen = false;
  }
}
