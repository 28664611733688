import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { Alert, ALERT_TYPES } from "../../store/alerts/alerts.actions";
import { AppStore } from "../../store/appStore";
import { SelectLastAlert } from "../../store/alerts/alerts.reducer";
import { Store } from "@ngrx/store";
import { showAlert } from "./alert-view.animation";
import { PushNotificationService } from "../../io/push-notification.service";

@Component({
  selector: "app-alert-view",
  templateUrl: "./alert-view.component.html",
  styleUrls: ["./alert-view.component.scss"],
  animations: [showAlert],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertViewComponent implements OnInit {
  public activeAlerts: Array<Alert> = [];
  private _timeouts: any = {};

  public alertTypes: typeof ALERT_TYPES = ALERT_TYPES;

  constructor(
    private _store: Store<AppStore>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _pushNotificationService: PushNotificationService
  ) {}

  ngOnInit() {
    this._store.select(SelectLastAlert()).subscribe((alert) => {
      if (alert && window.location.pathname !== "/blacklist-error") {
        this.AddAlert(alert);
      }
    });
  }

  public AddAlert(alert: Alert) {
    if (
      alert.type === ALERT_TYPES.UPDATE &&
      this.activeAlerts.filter((storedAlert) => {
        return storedAlert.type === ALERT_TYPES.UPDATE;
      }).length > 0
    ) {
      return;
    }

    if (
      alert.type === ALERT_TYPES.ALERT &&
      this.activeAlerts.filter((storedAlert) => {
        return storedAlert.type === ALERT_TYPES.ALERT;
      }).length > 0
    ) {
      return;
    }

    this.activeAlerts.push(alert);

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();

    if (alert["duration"] && alert["duration"] > 0) {
      this.StartTimeout(alert["id"], alert["duration"]);
    }
  }

  public RemoveAlert(id: string) {
    this.StopTimeout(id);
    if (id !== "") {
      const index = this.activeAlerts
        .map(function (e) {
          return e["id"];
        })
        .indexOf(id);
      this.activeAlerts.splice(index, 1);
    }

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();

    if (this.activeAlerts.length) {
      this.StartTimeout(
        this.activeAlerts[0]["id"],
        this.activeAlerts[0]["duration"]
      );
    }
  }

  public StopTimeout(id: any) {
    clearTimeout(this._timeouts[id]);
    delete this._timeouts[id];
  }

  public StartTimeout(id: string, duration: number) {
    this._changeDetectorRef.markForCheck();
    const that = this;
    if (duration > 0) {
      this._timeouts[id] = setTimeout(function () {
        that.RemoveAlert(id);
      }, duration * 1000);
    }
  }

  public ReloadToDashboard() {
    window.location.href = "/app/dashboard";
  }

  public Reload() {
    window.location.reload();
  }

  public handlePossibleAction(data: any, id: string) {
    if (!data) return;

    switch (data.action) {
      case "push_notification_click":
        this._pushNotificationService.pushNotificationActionPerformed({
          actionId: "tap",
          notification: data.notification,
        });
        this.RemoveAlert(id);
        break;
    }
  }
}
