import { GetUserFederalStatesSettingForFilter } from "../../store/user/user.selector";
import { Observable, Subject, takeUntil } from "rxjs";
import { AppStore } from "../../store/appStore";
import { Store } from "@ngrx/store";
import {
  ViewsExposedFilter,
  ViewsExposedUser,
} from "../../io/entity/view.service";
import { ViewType } from "../../io/entity/types/entityTypes";
import { ModerationSate } from "../../store/news-post/news-post";

const _killFilterSubscription: Subject<boolean> = new Subject<boolean>();
export function getFederalStatesFilter(
  _store: Store<AppStore>
): Observable<Array<ViewsExposedFilter>> {
  return new Observable((obs) => {
    _store
      .select(GetUserFederalStatesSettingForFilter)
      .pipe(takeUntil(_killFilterSubscription))
      .subscribe((filter) => {
        if (filter && filter.length > 0) {
          _killFilterSubscription.next(true);
          const modalFilter: Array<ViewsExposedFilter> = [];
          filter.forEach((filt) => {
            if (filt.id && !!filt.active) {
              modalFilter.push({
                id: "[federal-state][]",
                value: filt.id,
              });
            }
          });
          obs.next(modalFilter);
          obs.complete();
        }
      });
  });
}

export function buildFilterArrayForViewService(
  modalFilter: Array<ViewsExposedFilter>,
  textFilter: ViewsExposedFilter,
  moderationState: ModerationSate
) {
  let completeFilter: Array<ViewsExposedFilter> = [
    {
      id: ViewType.Bundle,
      value: "news_post",
    },
  ];

  if (modalFilter.length) {
    completeFilter.push(...modalFilter);
  }

  if (textFilter.value) {
    completeFilter.push(textFilter);
  }

  switch (moderationState) {
    case ModerationSate.PUBLISHED:
    case ModerationSate.OWN_PUBLISHED:
      completeFilter.push({
        id: ViewType.ModerationState,
        value: ModerationSate.PUBLISHED,
      });
      break;
    case ModerationSate.DRAFT:
      completeFilter.push({
        id: ViewType.ModerationState,
        value: moderationState,
      });
      break;
    case ModerationSate.WAITING_FOR_APPROVAL:
    case ModerationSate.OWN_WAITING_FOR_APPROVAL:
      completeFilter.push({
        id: ViewType.ModerationState,
        value: ModerationSate.WAITING_FOR_APPROVAL,
      });
      break;
    case ModerationSate.OWN_REJECTED:
      completeFilter.push({
        id: ViewType.ModerationState,
        value: ModerationSate.REJECTED,
      });
      break;
  }

  return completeFilter;
}

export function buildArgumentForViewService(
  moderationState: ModerationSate | undefined,
  user: number
) {
  let argument: ViewsExposedUser = {
    id: null,
  };
  switch (moderationState) {
    case ModerationSate.PUBLISHED:
    case ModerationSate.WAITING_FOR_APPROVAL:
      break;
    case ModerationSate.OWN_PUBLISHED:
    case ModerationSate.DRAFT:
    case ModerationSate.OWN_WAITING_FOR_APPROVAL:
    case ModerationSate.OWN_REJECTED:
    default:
      argument.id = user;
      break;
  }

  return argument;
}
