<div class="modal__background" (click)="Close()"></div>
<div class="modal" @enterLeave *ngIf="open">
    <div class="modal__header">
        <p class="header-title">{{ 'MODAL.MEDIFINDER_INFO.TITLE' | translate }}</p>
        <app-icon-button (action)="Close()" class="icon-button" src="icons/32/check.svg"></app-icon-button>
    </div>
    <div class="modal__content">
        <div class="row">
            <div class="col-xsmall-12" >
                <div class="spacer-1"></div>
                <h3 class="intro">
                    {{ 'MODAL.MEDIFINDER_INFO.INTRO' | translate }}
                </h3>

                <div class="spacer-2"></div>
                <p class="how-it-works"><b>{{'MODAL.MEDIFINDER_INFO.HOW_IT_WORKS' | translate}}</b></p>

                <ol>
                    <li><p><b>
                        {{'MODAL.MEDIFINDER_INFO.STEP_1.TITLE' | translate}}
                    </b></p>
                    <p>
                        {{'MODAL.MEDIFINDER_INFO.STEP_1.TEXT' | translate}}
                    </p></li>
                    <li><p><b>
                        {{'MODAL.MEDIFINDER_INFO.STEP_2.TITLE' | translate}}
                    </b></p>
                        <p>
                            {{'MODAL.MEDIFINDER_INFO.STEP_2.TEXT' | translate}}
                        </p></li>
                    <li><p><b>
                        {{'MODAL.MEDIFINDER_INFO.STEP_3.TITLE' | translate}}
                    </b></p>
                        <p>
                            {{'MODAL.MEDIFINDER_INFO.STEP_3.TEXT' | translate}}
                        </p></li>
                </ol>
            </div>
        </div>
        <div>
        <app-button [label]="'MODAL.MEDIFINDER_INFO.START' | translate" [buttonType]="ButtonType.Primary" [fullWidth]="true" (click)="Close()"></app-button>
            <div class="spacer spacer-6"></div>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
