import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-image-button",
  templateUrl: "./image-button.component.html",
  styleUrls: ["./image-button.component.scss"],
})
export class ImageButtonComponent {
  /**
   * Icon src that should be displayed on the button
   */
  @Input() src = "";

  /**
   * Alt text that should be displayed on the button
   */
  @Input() alt = "";

  /**
   * Disabled state of the button
   */
  @Input() disabled: boolean = false;

  @Input() style = {
    width: "70",
  };

  @HostBinding("class") get hostClasses() {
    const classList = ["image-button"];
    if (this.disabled) {
      classList.push("button--disabled");
    }
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
