import { Injectable } from "@angular/core";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { ApiConnectorService } from "../api-connector.service";
import { Observable } from "rxjs";
import { ApiEntityResponse } from "../entity/types/api-entity-response";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorAccessService extends ApiConnectorService {
  constructor(public configService: ConfigService, public http: HttpClient) {
    super(configService);
  }

  public loadAccessList(): Observable<ApiEntityResponse> {
    return this.http.get(
      this._configService.config.cms.url +
        "/jsonapi/taxonomy_term/login_blacklist"
    ) as Observable<ApiEntityResponse>;
  }
}
