import { Injectable } from "@angular/core";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { ApiConnectorDeviceService } from "./api-connector-device.service";
import { Store } from "@ngrx/store";
import { loadDashboard } from "../../store/user/user.actions";
import { GetAuthStatus } from "../../store/status/auth/store/auth.selector";
import { AUTH_STATUS } from "../../store/status/auth/auth.states";
import { BehaviorSubject, Observable, take } from "rxjs";
import { GetDevice } from "../../store/user/user.selector";
import { environment } from "../../../environments/environment";
import { App } from "@capacitor/app";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  private _authenticated = false;

  get id(): string {
    return this._id.identifier;
  }

  get info(): string {
    return `${this._info.platform} ${this._info.manufacturer} ${this._info.model} ${this._info.operatingSystem} ${this._info.name} ${environment.version} - ${environment.deployment}`;
  }

  private _info: DeviceInfo;
  private _id: DeviceId;
  private _pushNotificationToken: string;

  private _appActive = new Date().getTime();
  private _currentAppActive: BehaviorSubject<number> =
    new BehaviorSubject<number>(this._appActive);
  public appBecameActive = this._currentAppActive.asObservable();

  set pushNotificationToken(value: string) {
    this._pushNotificationToken = value;

    if (this._authenticated) {
      this._store.dispatch(
        loadDashboard({
          device_id: this.id,
          os_infos: this.info,
          field_firebase_device_token: this._pushNotificationToken,
          status: 1,
        })
      );
    }
  }

  constructor(private _api: ApiConnectorDeviceService, private _store: Store) {
    this._store.select(GetAuthStatus).subscribe((status) => {
      if (status === AUTH_STATUS.SUCCESS) {
        this._authenticated = true;
        this.activateDeviceStatus();
      }
    });

    App.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        this._appActive = new Date().getTime();
        this._currentAppActive.next(this._appActive);

        if (this._authenticated) {
          this.activateDeviceStatus();
        }
      }
    });
  }

  public deviceInfo = async () => {
    this._info = await Device.getInfo();

    this._id = await Device.getId();
  };

  public deactivateDeviceHandler(): Observable<any> {
    return new Observable<boolean>((obs) => {
      this._store
        .select(GetDevice)
        .pipe(take(1))
        .subscribe((device: any) => {
          if (device && device.bundle) {
            this._api.deactivateDevice(device.bundle, this.id).subscribe(
              (result) => {
                obs.next(true);
                obs.complete();
              },
              (error) => {
                obs.next(false);
                obs.complete();
              }
            );
          } else {
            obs.next(false);
            obs.complete();
          }
        });
    });
  }

  public activateDeviceStatus() {
    this.deviceInfo().then((result) => {
      this._store.dispatch(
        loadDashboard({
          device_id: this.id,
          os_infos: this.info,
          field_firebase_device_token: this._pushNotificationToken,
          status: 1,
        })
      );
    });
  }
}
