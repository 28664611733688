import { APP_INIT_STATES } from "../status/status";
import { Action, createReducer, on } from "@ngrx/store";
import { loadDashboardSuccess } from "../user/user.actions";
import cloneDeep from "lodash-es/cloneDeep";
import { WaitingForApprovalNewsPost } from "./approval";
import {
  approveOrRejectChamberInfo,
  approveOrRejectChamberInfoError,
  approveOrRejectChamberInfoSuccess,
  fetchApprovalMetaDataSuccess,
} from "./approval.actions";
import { ModerationStateUpdateStatus } from "../news-post/news-post";

export interface State {
  waitingForApprovalPosts: Array<WaitingForApprovalNewsPost>;
  tabAccess?: {
    published?: boolean;
    waiting_for_approval?: boolean;
    own_draft?: boolean;
    own_published?: boolean;
    own_archived?: boolean;
    own_rejected?: boolean;
    own_waiting_for_approval?: boolean;
  };
  deleteFromCache: Array<string>;
  status: APP_INIT_STATES;
}

export const initialState: State = {
  waitingForApprovalPosts: [],
  tabAccess: {},
  deleteFromCache: [],
  status: APP_INIT_STATES.INIT,
};

// @ts-ignore
const reducer = createReducer(
  initialState,
  on(fetchApprovalMetaDataSuccess, (state, action) => {
    state = cloneDeep(state);
    state.waitingForApprovalPosts = action.response.approval_required;
    state.tabAccess = action.response.tab_access;

    return state;
  }),

  on(approveOrRejectChamberInfo, (state, action) => {
    state = cloneDeep(state);
    state.waitingForApprovalPosts.map((post) => {
      if (post.entity.entity_id === action.id) {
        post.currentStatus = ModerationStateUpdateStatus.PENDING;
      }
    });
    return state;
  }),
  on(approveOrRejectChamberInfoSuccess, (state, action) => {
    state = cloneDeep(state);
    state.waitingForApprovalPosts.map((post) => {
      if (post.entity.entity_uuid === action.id) {
        post.currentStatus = ModerationStateUpdateStatus.SUCCESS;
        // prepare this entity for deletion also from cache
        state.deleteFromCache.push(action.id);
      }
    });

    return state;
  }),
  on(approveOrRejectChamberInfoError, (state, action) => {
    state = cloneDeep(state);
    state.waitingForApprovalPosts.map((post) => {
      if (post.entity.entity_uuid === action.id) {
        post.currentStatus = ModerationStateUpdateStatus.ERROR;
        // prepare this entity for deletion also from cache
        state.deleteFromCache.push(action.id);
      }
    });

    return state;
  })
);

export function ApprovalReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
