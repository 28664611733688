import { Action, createReducer, on } from "@ngrx/store";
import {
  loadDashboard,
  loadDashboardSuccess,
  loadUser,
  setUserFilterFederalState,
  setUserFilterTags,
  setUserPreferences,
  setUserPreferencesSuccess,
  toggleEmailNotifications,
  toggleEmailNotificationsSuccess,
} from "./user.actions";
import cloneDeep from "lodash-es/cloneDeep";
import { Event, User, UserEntity } from "./user";
import { UserPreferenceType } from "./user.type";
import { APP_INIT_STATES } from "../status/status";
import { updateEntityReadStatusSuccess } from "../../io/entity/store/entity.actions";
import { authLoginSuccess } from "../status/auth/store/auth.actions";
import jwtDecode from "jwt-decode";

export interface State {
  keycloakUser: {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
    username: string;
  };
  user?: User;
  federalStates: Array<UserEntity>;
  tags: Array<UserEntity>;
  event: Event | undefined;
  status: APP_INIT_STATES;
  unreadNewsPosts: Array<string>;
  waitingForApproval: Array<any>;
  device?: { bundle: string };
  federalStatesFilter: Array<string>;
  tagFilter: Array<string>;
  own_rejected_count: number;
}

export const initialState: State = {
  keycloakUser: {
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    username: "",
  },
  federalStates: [],
  tags: [],
  event: undefined,
  status: APP_INIT_STATES.INIT,
  unreadNewsPosts: [],
  waitingForApproval: [],
  federalStatesFilter: [],
  tagFilter: [],
  own_rejected_count: 0,
};

// @ts-ignore
const reducer = createReducer(
  initialState,
  on(loadUser, (state, action) => {
    state = cloneDeep(state);
    state.keycloakUser = action.user;

    return state;
  }),
  on(authLoginSuccess, (state, action) => {
    state = cloneDeep(state);

    try {
      const token: {
        email: string;
        given_name: string;
        family_name: string;
        preferred_username: string;
      } = jwtDecode(action.token);

      state.keycloakUser = {
        email: token.email,
        firstName: token.given_name,
        lastName: token.family_name,
        username: token.preferred_username,
        id: token.preferred_username,
      };
    } catch (e) {}

    return state;
  }),
  on(loadDashboard, (state) => {
    state = cloneDeep(state);
    state.status = APP_INIT_STATES.LOADING;

    return state;
  }),
  on(loadDashboardSuccess, (state, action) => {
    state = cloneDeep(state);

    let onboardingComplete;
    //explicitly check if onboarding was complete to prevent a second onboarding
    if (state.user && state.user.field_onboarding_completed) {
      onboardingComplete = state.user.field_onboarding_completed;
    }

    state.user = cloneDeep(action.response.user);

    if (state.user && onboardingComplete)
      state.user.field_onboarding_completed = onboardingComplete;

    state.federalStates = action.response.federal_states;
    state.tags = action.response.tags;
    state.event = action.response.event;
    state.status = APP_INIT_STATES.SUCCESS;
    state.unreadNewsPosts = action.response.news_posts.unread;
    state.device = action.response.device;
    state.federalStatesFilter =
      action.response.user.field_dashboard_federal_states;
    state.tagFilter = [];
    state.own_rejected_count = action.response.news_posts.own_rejected_count;
    if (state.user) {
      state.user.email_recipient = action.response.email_recipient;
    }

    return state;
  }),
  on(setUserFilterFederalState, (state, action) => {
    state = cloneDeep(state);
    state.federalStatesFilter = [];
    action.ids.forEach((el) => {
      state.federalStatesFilter.push(el);
    });
    return state;
  }),
  on(setUserFilterTags, (state, action) => {
    state = cloneDeep(state);
    state.tagFilter = [];
    action.ids.forEach((el) => {
      state.tagFilter.push(el);
    });
    return state;
  }),
  on(setUserPreferences, (state, action) => {
    state = cloneDeep(state);
    switch (action.id) {
      case UserPreferenceType.OnboardingCompleted:
        // @ts-ignore
        state.user[action.id] = action.value ? "1" : "0";
        break;
      case UserPreferenceType.PushNotifications:
      case UserPreferenceType.DashboardFederalStates:
      case UserPreferenceType.PushFederalStates:
      case UserPreferenceType.PushTags:
      case UserPreferenceType.MedifinderFederalStates:
      case UserPreferenceType.MedifinderPushNotifications:
        // @ts-ignore
        state.user[action.id] = action.value;
        break;
    }

    return state;
  }),
  on(setUserPreferencesSuccess, (state, action) => {
    state = cloneDeep(state);
    let selectedPreference;
    switch (action.payload.id) {
      case UserPreferenceType.MedifinderPushNotifications:
        selectedPreference =
          action.response.user.data.attributes.field_medifinder_push_enabled;
        break;
      case UserPreferenceType.PushNotifications:
        selectedPreference =
          action.response.user.data.attributes.field_push_notifications;
        break;
      case UserPreferenceType.DashboardFederalStates:
      case UserPreferenceType.PushFederalStates:
      case UserPreferenceType.PushTags:
      case UserPreferenceType.MedifinderFederalStates:
        selectedPreference = action.response.user.data.relationships[
          action.payload.id
        ].data.map((item: any) =>
          item.meta.drupal_internal__target_id.toString()
        );
        break;
      case UserPreferenceType.OnboardingCompleted:
        selectedPreference =
          action.response.user.data.attributes.field_onboarding_completed;
        break;
    }

    // @ts-ignore
    state.user[action.payload.id] = selectedPreference;

    return state;
  }),
  on(toggleEmailNotificationsSuccess, (state, action) => {
    state = cloneDeep(state);
    if (state.user) {
      state.user.email_recipient = action.value;
    }
    return state;
  }),
  on(toggleEmailNotifications, (state, action) => {
    state = cloneDeep(state);
    if (state.user && state.user.email_recipient) {
      state.user.email_recipient.status = action.value.status;
      state.user.email_recipient.subscribed_federal_states =
        action.value.subscribed_federal_states;
    }
    return state;
  }),
  on(updateEntityReadStatusSuccess, (state, action) => {
    state = cloneDeep(state);
    state.unreadNewsPosts = state.unreadNewsPosts.filter(
      (id) => id !== action.id
    );

    return state;
  })
);

export function UserReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
