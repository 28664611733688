import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from "@angular/router";

export class CustomRouteReuseStrategy
  extends BaseRouteReuseStrategy
  implements RouteReuseStrategy
{
  private routeStore = new Map<string, DetachedRouteHandle>();

  override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // do not reuse if kammerinfo
    return (
      future.routeConfig === curr.routeConfig &&
      future.routeConfig?.path !== "kammerinfo/:id" &&
      future.routeConfig?.path !== "medifinder/:id"
    );
  }
}
