import { ChangeDetectorRef, Component } from "@angular/core";
import { AuthService } from "../../store/status/auth/auth.service";
import { Router } from "@angular/router";
import { ApiEmailConnectorService } from "../../store/email/api-email-connector.service";

export enum EmailDataStates {
  INIT,
  PROGRESS,
  SUCCESS,
  ERROR,
  CONFIRMATION,
  CONFIRMATION_SUCCESS,
}

@Component({
  selector: "app-email-self-service",
  templateUrl: "./email-self-service.component.html",
  styleUrls: ["./email-self-service.component.scss"],
})
export class EmailSelfServiceComponent {
  public isLoggedIn = false;
  public errorMessage = "";

  public state: EmailDataStates = EmailDataStates.INIT;
  constructor(
    protected _authService: AuthService,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _router: Router,
    protected _apiEmailService: ApiEmailConnectorService
  ) {
    this._authService.isAuthenticated(false).subscribe((isAuthenticated) => {
      this.isLoggedIn = isAuthenticated;
      this._changeDetectorRef.markForCheck();
    });
  }

  dashboard() {
    this._router.navigateByUrl("/app/dashboard");
  }
}
