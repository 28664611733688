import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { DatePicker, DatePickerTheme } from "@capacitor-community/date-picker";
import { Capacitor } from "@capacitor/core";
const selectedTheme: DatePickerTheme = "light";

@Component({
  selector: "app-input-date",
  templateUrl: "./input-date.component.html",
  styleUrls: ["./input-date.component.scss"],
})
export class InputDateComponent {
  /**
   * Text that should be displayed above the input
   */
  @Input() label: String = "";

  /**
   * Text that should be displayed beside the label
   */
  @Input() caption: String = "";

  /**
   * min date of input
   */
  @Input() min: String = "2020-01-01";

  /**
   * max date of input
   */
  @Input() max: String = "";

  /**
   * max date of input
   */
  @Input() initValue: String = "";

  /**
   * main output
   */
  @Output() currentValue: EventEmitter<String> = new EventEmitter<String>();

  public showOverlay: Boolean = false;

  public value: String = "";

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    if (Capacitor.getPlatform() === "ios") {
      this.showOverlay = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["initValue"] && changes["initValue"].currentValue) {
      this.value = this.initValue.split("T")[0];
      this._changeDetectorRef.markForCheck();
    } else if (!this.initValue) {
      this.value = "";
    }
  }

  public valueChanged() {
    this.currentValue.emit(this.value);
  }

  public DatePicker() {
    const dateToPresent = this.value
      ? this.value.split("-")
      : new Date().toISOString().substring(0, 10).split("-");

    const today = new Date().toISOString().substring(0, 10).split("-");

    DatePicker.present({
      mode: "date",
      locale: "de_DE",
      theme: selectedTheme,
      date: `${dateToPresent[2]}/${dateToPresent[1]}/${dateToPresent[0]}`,
      max: `${today[2]}/${today[1]}/${today[0]}`,
      min: `01/01/2023`,
      ios: {
        format: "dd/MM/yyyy",
        style: "wheels",
      },
      android: {
        format: "dd/MM/yyyy",
      },
    }).then((date) => {
      if (date && date.value) {
        const parts = date.value.split("/");
        this.value = `${parts[2]}-${parts[1]}-${parts[0]}`;
        this.valueChanged();
      }
    });
  }
}
