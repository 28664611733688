import { ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { BackHeaderImageType } from "../../ui/header/back-header/back-header.type";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../store/status/auth/auth.service";
import { ConfigService } from "../../config/config.service";
import { ButtonType } from "../../ui/button/button.type";
import {
  EmailDataStates,
  EmailSelfServiceComponent,
} from "../email-self-service/email-self-service.component";
import { ApiEmailConnectorService } from "../../store/email/api-email-connector.service";

@Component({
  selector: "app-email-data-change",
  templateUrl: "./email-data-change.component.html",
  styleUrls: [
    "./email-data-change.component.scss",
    "../email-self-service/email-self-service.component.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EmailDataChangeComponent extends EmailSelfServiceComponent {
  protected readonly DashboardRoutesType = DashboardRoutesType;
  protected readonly BackHeaderImageType = BackHeaderImageType;

  public emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

  public emailValid = false;
  public newEmail: String = "";
  public formDirty = false;

  constructor(
    private _http: HttpClient,
    private _routeSnapshot: ActivatedRoute,
    protected override _authService: AuthService,
    protected override _changeDetectorRef: ChangeDetectorRef,
    private _configService: ConfigService,
    protected override _router: Router,
    protected override _apiEmailService: ApiEmailConnectorService
  ) {
    super(_authService, _changeDetectorRef, _router, _apiEmailService);

    if (
      this._routeSnapshot.snapshot.params["state"] &&
      this._routeSnapshot.snapshot.params["state"] === "confirm"
    ) {
      this.state = EmailDataStates.CONFIRMATION;
      this.confirmEmail();
    }
  }

  onChange(value: string) {
    this.emailValid = this.emailRegex.test(value);
    this.newEmail = value;
  }

  onSubmit(value: any) {
    this.formDirty = true;

    if (this.emailValid) {
      this.updateEmail();
    }
  }

  private updateEmail() {
    this.state = EmailDataStates.PROGRESS;

    this._apiEmailService
      .updateEmailRecipient({
        ...{ form: { email: this.newEmail } },
        ...this._routeSnapshot.snapshot.queryParams,
      })
      .subscribe({
        next: (response) => {
          this.state = EmailDataStates.SUCCESS;
        },
        error: (error) => {
          this.state = EmailDataStates.ERROR;
          this.errorMessage = `${error.message}`;
        },
      });
  }

  private confirmEmail() {
    this._http
      .patch(
        this._configService.config.cms.url +
          "/api/self-service/email-recipient/update/confirm",

        this._routeSnapshot.snapshot.queryParams
      )
      .subscribe({
        next: (response) => {
          this.state = EmailDataStates.CONFIRMATION_SUCCESS;
        },
        error: (error) => {
          console.log(error);
          this.state = EmailDataStates.ERROR;
          this.errorMessage = `<br /><b>${error.message}</b><br />${error.error.message}`;
        },
      });
  }

  protected readonly EmailDataStates = EmailDataStates;
  protected readonly ButtonType = ButtonType;
}
