import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonType } from "../button.type";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  /**
   * Text that should be displayed on the button
   */
  @Input() label = "";

  /**
   * Visual hierarchy for the button
   */
  @Input() buttonType: ButtonType = ButtonType.Primary;

  /**
   * Disabled state of the button
   */
  @Input() disabled: boolean = false;

  /**
   * Full width state of the button
   */
  @Input() fullWidth: boolean = false;

  /**
   * Extra height
   */
  @Input() extraHeight: boolean = false;

  /**
   * state of the button having an icon
   */
  @Input() icon: boolean = false;

  @HostBinding("class") get hostClasses() {
    const classList = ["button", "button--" + this.buttonType];
    if (this.disabled) {
      classList.push("button--disabled");
    }
    if (this.fullWidth) {
      classList.push("button--full-width");
    }
    if (this.icon) {
      classList.push("button--icon");
    }
    return classList;
  }

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
