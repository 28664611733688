import { Component, EventEmitter, Output } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { filterModalAnimationsEnterLeave } from "./filter-modal.animations";
import { InputType } from "./filter-modal.type";
import { ViewType } from "../../../io/entity/types/entityTypes";
import { EntityService } from "../../../io/entity/entity.service";
import { ButtonOutput } from "../../button/button-output";
import { MODAL_ACTIONS, ModalAction } from "../modal-actions";
import { ActivatedRoute } from "@angular/router";
import {
  ViewService,
  ViewsExposedFilter,
} from "../../../io/entity/view.service";
import { base64UrlEncode } from "../../../helper/helper-functions";
import {
  GetUserFederalStatesSettingForFilter,
  GetUserTagsSettingForFilter,
} from "../../../store/user/user.selector";
import { Store } from "@ngrx/store";
import { AppStore } from "../../../store/appStore";
import {
  setUserFilterFederalState,
  setUserFilterTags,
} from "../../../store/user/user.actions";

@Component({
  selector: "app-filter-modal",
  templateUrl: "./filter-modal.component.html",
  styleUrls: ["./filter-modal.component.scss"],
  animations: [filterModalAnimationsEnterLeave],
})
export class FilterModalComponent extends BaseModalComponent {
  public federalStates$;
  public tags$;

  public today = new Date().toISOString().split("T")[0];
  public fromMax = this.today;

  public fromInputValue = "";
  public toInputValue = "";

  private _federalStatesFilter = [];
  public activeFederalStates: Array<string> = [];
  private _tagsFilter = [];
  public activeTags: Array<string> = [];

  public InputType = InputType;

  public currentFilter: Array<ViewsExposedFilter> = [];

  @Output() output: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  private init = false;

  constructor(
    private _entityService: EntityService,
    private _route: ActivatedRoute,
    private _viewService: ViewService,
    private _store: Store<AppStore>
  ) {
    super();

    this.tags$ = this._store.select(GetUserTagsSettingForFilter);

    this.federalStates$ = this._store.select(
      GetUserFederalStatesSettingForFilter
    );
  }

  public override ngOnInit() {
    super.ngOnInit();

    this._route.queryParams.subscribe((params) => {
      if (params["filter"]) {
        let decodedParams = this._viewService.buildURI(
          base64UrlEncode(params["filter"])
        );
        this.currentFilter = decodedParams.exposedFilter;
        this.SetInitFilter(this.currentFilter);
      }
    });
  }

  public SetInputValue(value: string, type: InputType) {
    switch (type) {
      case InputType.From:
        this.fromInputValue = value;
        break;
      case InputType.To:
        this.toInputValue = value;
        break;
    }
    this.CheckMaxValueForFromInput(value);
  }

  public reset() {
    this.SetInputValue("", InputType.From);
    this.SetInputValue("", InputType.To);
  }

  public CheckMaxValueForFromInput(toValue: string) {
    const from = new Date(this.fromInputValue);
    const to = new Date(this.toInputValue);

    if (from > to && to.getUTCFullYear() > 1900) {
      const newFrom = new Date(to.getTime());

      newFrom.setMonth(to.getMonth() - 1);

      this.SetInputValue(newFrom.toISOString().split("T")[0], InputType.From);
    }
  }

  public SetFilter(event: ButtonOutput, type: InputType) {
    switch (type) {
      case InputType.FederalStates:
        this._federalStatesFilter = event.data;
        let ids: any[] = [];
        event.data.forEach((el: any) => {
          if (el.active === true && el.id) {
            ids.push(el.id);
          }
        });
        this._store.dispatch(setUserFilterFederalState({ ids: ids }));
        break;
      case InputType.Tags:
        this._tagsFilter = event.data;
        this.activeTags = event.data;
        let tids: any[] = [];
        event.data.forEach((el: any) => {
          if (el.active && el.id) {
            tids.push(el.id);
          }
        });
        this._store.dispatch(setUserFilterTags({ ids: tids }));
        break;
    }
  }

  public EmitFilter() {
    let filters = [];

    // FEDERAL STATES FILTER
    this._federalStatesFilter.forEach((filter) => {
      if (filter["active"] || filter["hidden"] === true) {
        filters.push({
          id: ViewType.FederalStates,
          value: filter["id"],
        });
      }
    });

    // TAGS FILTER
    this._tagsFilter.forEach((filter) => {
      if (filter["active"]) {
        filters.push({
          id: ViewType.Tags,
          value: filter["id"],
        });
      }
    });

    /*
     * for filtering by date we need start AND end date
     * therefore we check if both are set - when one is set and the other not then set default date
     * */

    // FROM DATE
    if (this.fromInputValue) {
      filters.push({
        id: ViewType.PublishedAtMin,
        value: new Date(this.fromInputValue).toISOString(),
      });
    } else if (this.toInputValue) {
      // @ToDo another start Date?
      filters.push({
        id: ViewType.PublishedAtMin,
        value: new Date("2023-01-01").toISOString(),
      });
    }

    // TO DATE - set until end of day
    if (this.toInputValue) {
      let tmp = new Date(this.toInputValue).setHours(23, 59, 59);
      filters.push({
        id: ViewType.PublishedAtMax,
        value: new Date(tmp).toISOString(),
      });
    } else if (this.fromInputValue) {
      filters.push({
        id: ViewType.PublishedAtMax,
        value: new Date().toISOString(),
      });
    }

    this.output.emit({
      action: MODAL_ACTIONS.filter,
      value: filters,
    });

    this.Close();
  }

  public SetInitFilter(filters: Array<ViewsExposedFilter>) {
    let tmpActiveFederalStates: Array<string> = [];
    let tmpActiveTags: Array<string> = [];

    filters.forEach((filter) => {
      switch (filter.id) {
        case ViewType.PublishedAtMax:
          this.toInputValue = filter.value;
          break;
        case ViewType.PublishedAtMin:
          this.fromInputValue = filter.value;
          break;
        case ViewType.FederalStates:
          tmpActiveFederalStates.push(filter.value);
          break;
        case ViewType.Tags:
          tmpActiveTags.push(filter.value);
          break;
      }
    });

    this.activeFederalStates = tmpActiveFederalStates;
    this.activeTags = tmpActiveTags;
  }
}
