import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap } from "rxjs/operators";
import { EMPTY, takeUntil } from "rxjs";
import { addEntities, loadEntity, } from "./entity.actions";
import { Store } from "@ngrx/store";
import { AppStore } from "../../../store/appStore";
import { isArray } from "lodash-es";
import { ResponseStructureError } from "../../../error/types/response-structure-error";
import { loadView, VIEW_ACTIONS } from "./view.actions";
import { ApiConnectorViewService } from "../api-connector-view.service";
import { CacheService } from "../cache.service";
import { Entity } from "../types/entity";
import { AliasService } from "../alias.service";
import Bugsnag from "@bugsnag/js";
import { AlertService } from "../../../alerts/alert.service";
import { NavigationService } from "../../../services/navigation.service";
import { BasePageAnimationDirection } from "../../../animations/animation-base/animation.types";

@Injectable()
export class ViewEffects {
  loadView$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadView),
      mergeMap((initialPayload) => {
        return this._viewApi.loadView(initialPayload).pipe(
          map((payload) => {
            try {
              const data = payload.data;
              if (!isArray(data)) {
                return addEntities({
                  entities: [],
                  url: initialPayload.url,
                  raw: payload,
                });
              }

              this._cacheService.addToCache(
                initialPayload.url,
                isArray(data.map((entity: Entity) => entity.id))
                  ? data.map((entity: Entity) => entity.id)
                  : [data.map((entity: Entity) => entity.id)],
                false
              );

              this._aliasService.addAliasFromEntities(
                isArray(data) ? data : [data]
              );

              const included = payload.included ? payload.included : [];
              return addEntities({
                entities: [...data, ...included],
                included: included,
                url: initialPayload.url,
                raw: payload,
              });
            } catch (e) {
              Bugsnag.notify(
                `Error parsing view, ${e.message}, ${JSON.stringify(
                  initialPayload
                )}`
              );
              throw ResponseStructureError(loadEntity.type, e);
            }
          }),
          catchError((e:Error) => {
            Bugsnag.notify(
              `Error loading view, ${e.message}, ${JSON.stringify(
                initialPayload
              )}`
            );
            if(e.name === "TimeoutError"){
              this._navigationService.navigateByUrl("/timeout-error", BasePageAnimationDirection.FADE, {error:"timeout"});
            }else {
              this._alertService.DispatchAlert(
                "Fehler beim Laden",
                "Es ist ein Fehler beim Laden aufgetreten. Bitte versuchen Sie es später erneut. (" +
                e.name +
                "/" +
                e.message +
                ")"
              );
            }
            return EMPTY;
          }),
          takeUntil(this._actions$.pipe(ofType(VIEW_ACTIONS.CANCEL_LOAD_VIEW)))
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _viewApi: ApiConnectorViewService,
    private _cacheService: CacheService,
    private _aliasService: AliasService,
    private _alertService: AlertService,
    private _navigationService:NavigationService
  ) {}
}
