import { createAction, props } from "@ngrx/store";
import { Entity } from "../types/entity";
import { ApiEntityResponse } from "../types/api-entity-response";

export const loadEntity = createAction(
  "[Entity] Load Entity",
  props<{ url: string; suppressError?: boolean }>()
);

export const addEntity = createAction(
  "[Entity] Add Entity",
  props<{ entity: any; included?: Array<any> }>()
);

export const addEntities = createAction(
  "[Entity] Add Entities",
  props<{
    entities: any;
    included?: Array<any>;
    url?: string;
    raw?: ApiEntityResponse;
  }>()
);

export const addEntitiesFromView = createAction(
  "[Entity] Add Entities from View",
  props<{ entities: any; view: string }>()
);

export const loadEntityError = createAction(
  "[Entity] Load Entity ERROR",
  props<{ error: any; data?: any; suppress?: boolean }>()
);

export const updateEntityReadStatus = createAction(
  "[Entity] Update Entity Read Status",
  props<{ id: string }>()
);

export const updateEntityReadStatusSuccess = createAction(
  "[Entity] Update Entity Read Status SUCCESS",
  props<{ id: string }>()
);

export const updateEntityModerationStatusSuccess = createAction(
  "[Entity] Update Entity Moderation Status SUCCESS",
  props<{ id: string; response: any }>()
);

export const cleanUpEntities = createAction("[Entity] clean up");
export const cleanUpEntitiesSuccess = createAction("[Entity] clean up SUCCESS");

export const purgeEntities = createAction("[Entity] purgeEntities");
