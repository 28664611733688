import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import {
  addEntities,
  addEntity,
  loadEntity,
  loadEntityError,
  cleanUpEntities,
  cleanUpEntitiesSuccess,
  updateEntityReadStatus,
  updateEntityReadStatusSuccess,
} from "./entity.actions";
import { Store } from "@ngrx/store";
import { AppStore } from "../../../store/appStore";
import { ApiConnectorEntityService } from "../api-connector-entity.service";
import { ApiEntityResponse } from "../types/api-entity-response";
import { isArray } from "lodash-es";
import { ResponseStructureError } from "../../../error/types/response-structure-error";
import { AliasService } from "../alias.service";
import { CacheService } from "../cache.service";
import Bugsnag from "@bugsnag/js";
import { AlertService } from "../../../alerts/alert.service";

@Injectable()
export class EntityEffects {
  loadEntities$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadEntity),
      mergeMap((requestPayload) => {
        return this._entityApi.loadEntities(requestPayload).pipe(
          map((payload) => {
            try {
              const data = [
                ...(isArray(payload.data) ? payload.data : [payload.data]),
                ...(payload.included ? payload.included : []),
              ];

              this._aliasService.addAliasFromEntities(
                isArray(data) ? data : [data]
              );

              (isArray(data) ? data : [data]).forEach((data) => {
                if (data && data.id) {
                  this._cacheService.addToCache(requestPayload.url, data);
                }
              });

              return isArray(data)
                ? addEntities({ entities: data, included: payload.included })
                : addEntity({ entity: data, included: payload.included });
            } catch (e) {
              throw ResponseStructureError(loadEntity.type, e);
            }
          }),
          catchError((e: any) => {
            if (!requestPayload.suppressError) {
              Bugsnag.notify(`Error loading entity, ${e.message}`);
              this._alertService.DispatchAlert(
                "Fehler beim Laden",
                "Es ist ein Fehler beim Laden aufgetreten. Bitte versuchen Sie es später erneut. (" +
                  e.name +
                  "/" +
                  e.message +
                  ")"
              );
            }
            return of(
              loadEntityError({
                error: e,
                suppress: requestPayload.suppressError,
              })
            );
          })
        );
      })
    )
  );

  updateEntityReadStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateEntityReadStatus),
      mergeMap((payload) => {
        return this._entityApi.updateEntityReadStatus(payload.id).pipe(
          map(() => {
            try {
              return updateEntityReadStatusSuccess({ id: payload.id });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError((e) => {
            Bugsnag.notify(`Error updating read status, ${e.message}`);
            this._alertService.DispatchAlert(
              "Fehler beim Laden",
              "Es ist ein Fehler beim setzen des Lesestatus aufgetreten. Bitte versuchen Sie es später erneut. (" +
                e.name +
                "/" +
                e.message +
                ")"
            );
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _entityApi: ApiConnectorEntityService,
    private _aliasService: AliasService,
    private _cacheService: CacheService,
    private _alertService: AlertService
  ) {}
}
