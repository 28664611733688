import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { debounceTime, Subject } from "rxjs";
import { ButtonActionsType } from "../../button/button-actions.type";
import { ViewType } from "../../../io/entity/types/entityTypes";
import { ButtonOutput } from "../../button/button-output";

export enum SearchIcons {
  search = "search",
  book = "menu-learn",
}

@Component({
  selector: "app-input-search-text",
  templateUrl: "./input-search-text.component.html",
  styleUrls: ["./input-search-text.component.scss"],
})
export class InputSearchTextComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() id: String = "";
  @Input() name: String = "";
  @Input() placeholder: String = "";
  @Input() disabled = false;
  @Input() closeInputOption = false;
  @Input() icon: SearchIcons = SearchIcons.search;
  /*
   * set input in focus when true
   * */
  @Input() autofocus = false;

  /*
   * set init value of search field
   * */
  @Input() initValue: string = "";

  @ViewChild("ngModel", { static: true }) value: any;
  @ViewChild("inputValue") element: ElementRef;

  public searchNotifier = new Subject();

  public currentVal = "";

  @Output() action: EventEmitter<ButtonOutput> = new EventEmitter();

  @Input() bottomEffectOnInput = false;

  ngOnInit() {
    this.searchNotifier
      .pipe(debounceTime(400))
      .subscribe((data) => this.EmitTerm());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["initValue"] && changes["initValue"].currentValue) {
      this.value.model = this.initValue;
      this.currentVal = this.initValue;
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      const that = this;
      setTimeout(() => {
        that.element.nativeElement.focus();
      }, 100);
    }
  }

  public EmitTerm() {
    this.currentVal = this.value.model;
    let term = {
      id: ViewType.SearchText,
      value: this.value.model,
    };
    this.action.emit({
      type: ButtonActionsType.Input,
      data: term,
    });
  }
  public clearAndEmit() {
    this.value.model = "";
    this.EmitTerm();
    if (this.closeInputOption) {
      this.action.emit({
        type: ButtonActionsType.Close,
      });
    }
  }

  private clear() {
    this.value.model = "";
  }

  ngOnDestroy() {
    this.searchNotifier.complete();
    this.clear();
  }
}
