import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { CoreConfig } from "../config/core-config.type";
import { DeviceService } from "../io/device/device.service";
import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { Capacitor } from "@capacitor/core";
import { Actions } from "@ngrx/effects";
import { authLoginSuccess } from "../store/status/auth/store/auth.actions";
import { BehaviorSubject, Observable, Subscriber, take } from "rxjs";
import { AuthService } from "../store/status/auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Environments } from "../../environments/environments";

@Injectable({
  providedIn: "root",
})
export class BiometricAccessService {
  public nextBiometricCheck = 0;
  public BIOMETRIC_CHECK_EVERY_X_MINUTES =
    environment.deployment === Environments.production ? 360 : 1;
  private _config: CoreConfig;

  private _biometryCheckActive = false;
  private _currentBiometryCheckActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this._biometryCheckActive);
  public biometryCheckActive$ = this._currentBiometryCheckActive.asObservable();

  private _biometricSecuredPaths = ["app/"];

  public forceCheck = false;

  constructor(
    private _configService: ConfigService,
    private _deviceService: DeviceService,
    private _authService: AuthService,
    private _actions$: Actions,
    private _router: Router
  ) {
    this._config = this._configService.config;

    // load next biometric check from local storage if present
    const temp = localStorage.getItem(
      this._config.storage.localStoragePrefix + "biometricCheck"
    );
    this.nextBiometricCheck = temp ? parseInt(temp, 10) : 0;

    // check biometry on app start and every time the app gets active
    this._deviceService.appBecameActive.subscribe(() => {
      this.checkBiometry()
        .pipe(take(1))
        .subscribe((res) => {});
    });

    // if the user logs in successfully, the timeout for checking the bioemtry is reset
    this._actions$.subscribe((action) => {
      if (action.type === authLoginSuccess.type) {
        this.biometryChecked();
      }
    });
  }

  /*
   * Checks the biometry and redirects to the biometric-access-error page if the biometry is not available
   */
  public checkBiometry() {
    return new Observable((observer: Subscriber<boolean>) => {
      // check if biometry is needed
      if (!this.biometricCheckNeeded() && !this.forceCheck) {
        this._biometryCheckActive = false;
        this._currentBiometryCheckActive.next(this._biometryCheckActive);

        observer.next(false);
        observer.complete();
        return;
      }

      this.forceCheck = false;

      // check if biometry is available
      this._biometryCheckActive = true;
      this._currentBiometryCheckActive.next(this._biometryCheckActive);

      // if biometry is available, verify the identity
      NativeBiometric.verifyIdentity({
        reason: "Bitte bestätigen Sie Ihre Identität um die App zu nutzen",
        useFallback: true,
      })
        .then((result) => {
          observer.next(true);
          observer.complete();
          this.biometryChecked();
        })
        .catch((error) => {
          observer.next(false);
          observer.complete();
          this._router.navigateByUrl("/biometric-access-error?error=verify");
        });
    });
  }

  /*
  Checks if the path should be secured by biometry
   */
  public isBiometricSecuredPath(path: string = this._router.url): boolean {
    let found = false;

    if (this._router.url === "/") return true;

    this._biometricSecuredPaths.forEach((securedPath) => {
      if (path.indexOf(securedPath) >= 0) {
        found = true;
      }
    });

    return found;
  }

  /*

   */
  public biometricCheckNeeded(): boolean {
    // if the user is not logged in, no biometric check is needed
    if (this._authService.isAuthTokenExpired()) return false;

    // if the user is not on a mobile device, no biometric check is needed
    if (Capacitor.getPlatform() === "web") return false;

    // if the user is not on a biometric secured path, no biometric check is needed
    if (!this.isBiometricSecuredPath()) return false;

    // if the user has already checked the biometry, no biometric check is needed for some time
    console.log(this.nextBiometricCheck < new Date().getTime());
    return this.nextBiometricCheck < new Date().getTime();
  }

  /*
   * Sets the next biometric check to the current time + x minutes and saves it to local storage
   */
  public biometryChecked() {
    // set the check to false
    this._biometryCheckActive = false;
    this._currentBiometryCheckActive.next(this._biometryCheckActive);

    // set the next biometric check to the current time + x minutes
    this.nextBiometricCheck =
      new Date().getTime() + this.BIOMETRIC_CHECK_EVERY_X_MINUTES * 60 * 1000;
    localStorage.setItem(
      this._config.storage.localStoragePrefix + "biometricCheck",
      this.nextBiometricCheck.toString()
    );

    // log the next biometric check
    console.log(
      "next biometric check scheduled for",
      new Date(this.nextBiometricCheck)
    );
  }
}
