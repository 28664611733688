import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./content/dashboard/dashboard.component";
import { AuthGuard } from "./auth/auth-guard";
import { InfoOverviewComponent } from "./content/info-overview/info-overview.component";
import { InfoDetailComponent } from "./content/info-detail/info-detail.component";
import { EventOverviewComponent } from "./content/event-overview/event-overview.component";
import { SettingsComponent } from "./content/settings/settings.component";
import { OnboardingComponent } from "./content/onboarding/onboarding.component";
import { AliasGuard } from "./io/entity/alias.guard";
import { LoginComponent } from "./content/login/login.component";
import { LoginErrorComponent } from "./content/login-error/login-error.component";
import { AuthGuardWebGuard } from "./auth/auth-guard-web.guard";
import { LogoutComponent } from "./content/logout/logout.component";
import { InfoPageComponent } from "./content/info-page/info-page.component";
import { LoginBlacklistComponent } from "./content/login-blacklist/login-blacklist.component";
import { BiometricAccessErrorComponent } from "./content/biometric-access-error/biometric-access-error.component";
import { ErrorPageComponent } from "./content/error-page/error-page.component";
import { EmailDataChangeComponent } from "./content/email-data-change/email-data-change.component";
import { MedifinderOverviewComponent } from "./content/medifinder-overview/medifinder-overview.component";
import { MedifinderDetailComponent } from "./content/medifinder-detail/medifinder-detail.component";
import { EmailUnsubscribeComponent } from "./content/email-unsubscribe/email-unsubscribe.component";
import { EmailDataConfirmComponent } from "./content/email-data-confirm/email-data-confirm.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/app/dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "login-error",
    component: LoginErrorComponent,
  },
  {
    path: "blacklist-error",
    component: LoginBlacklistComponent,
  },
  {
    path: "timeout-error",
    component: ErrorPageComponent,
  },

  {
    path: "biometric-access-error",
    component: BiometricAccessErrorComponent,
  },
  {
    path: "self-service/email-recipient/update",
    component: EmailDataChangeComponent,
  },
  {
    path: "self-service/email-recipient/update/:state",
    component: EmailDataChangeComponent,
  },
  {
    path: "self-service/email-recipient/register/confirm",
    component: EmailDataConfirmComponent,
  },
  {
    path: "self-service/email-recipient/unsubscribe",
    component: EmailUnsubscribeComponent,
  },
  {
    path: "kammerinfo/:id",
    redirectTo: "/app/kammerinfo/:id",
  },
  {
    path: "app",
    canActivate: [AuthGuard, AuthGuardWebGuard],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "medifinder",
        component: MedifinderOverviewComponent,
      },
      {
        path: "medifinder/:id",
        component: MedifinderDetailComponent,
      },
      {
        path: "kammerinfos",
        component: InfoOverviewComponent,
      },
      {
        path: "kammerinfo/:id",
        component: InfoDetailComponent,
        //canActivate: [AliasGuard],
      },
      {
        path: "event",
        component: EventOverviewComponent,
      },
      {
        path: "settings",
        component: SettingsComponent,
      },
      {
        path: "welcome",
        component: OnboardingComponent,
      },
      {
        path: "info/:type",
        component: InfoPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
