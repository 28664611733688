<ng-container *ngIf="(pharmacy$ | async) as pharmacy; else loading">

    <p class="caption">
    {{"MEDIFINDER.DETAIL.CONTACT" | translate }}
    </p>
    <p class="header-title">
        {{pharmacy.attributes.name}}
    </p>
    <p class="address">
        {{pharmacy.attributes.address}},
        {{pharmacy.attributes.zip}} {{ pharmacy.attributes.city}}
    </p>
    <p class="person" *ngIf="owner$ | async as owner">
        {{owner.attributes.field_display_name}}
    </p>
    <div class="pharmacy__link">
        <app-svg-icon
                class="icon"
                [src]="'icons/32/phone.svg'"
                [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
        ></app-svg-icon>
        <a id="pharmacy-phone" class="button-text"
           href="tel:{{transformPhoneNumber(pharmacy.attributes.phone)}}">{{pharmacy.attributes.phone}}</a>
    </div>
    <div class="pharmacy__link">
        <app-svg-icon
                class="icon"
                [src]="'icons/32/mail.svg'"
                [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
        ></app-svg-icon>
        <a id="pharmacy-email" class="button-text" href="mailto:{{pharmacy.attributes.email}}?{{('MEDIFINDER.EMAIL.TEXT' | translate:{drug: encodeURIComponent(drugTitle)} )}}">{{pharmacy.attributes.email}}</a>
    </div>

</ng-container>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>


