import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { EntityTypesId } from "../../io/entity/types/entityTypes";
import { Observable } from "rxjs";
import { EntityService } from "../../io/entity/entity.service";
import { Entity } from "../../io/entity/types/entity";
import { selectMediaDocument } from "../../store/media/media-document.selector";
import { ConfigService } from "../../config/config.service";
import { selectApoDepartment } from "../../store/apo-department/apo-department.selector";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit {
  @Input() data: Array<Entity>;

  public attachments$: any;
  public attachments: Array<any>;
  public cmsURL: string;

  public isBrowser = Capacitor.getPlatform() === "web";

  @HostBinding("class") get hostClasses() {
    return ["attachments"];
  }

  constructor(
    private _entityService: EntityService,
    private _configService: ConfigService
  ) {
    this.cmsURL = this._configService.config.cms.url;
  }

  ngOnInit() {
    this.attachments = this._entityService.getMultipleEntities(
      EntityTypesId.MediaDocument,
      this.data.map((id) => id.id),
      selectMediaDocument
    );
  }

  download(url: string) {
    Browser.open({ url: url, presentationStyle: "popover" });
  }

  GetIds(data: Array<Entity>) {
    const ids: Array<string> = [];

    data.forEach((d) => {
      ids.push(d.id);
    });

    return ids;
  }
}
