<div class="modal__background" (click)="Close()"></div>
<div class="modal" @enterLeave *ngIf="open">
    <div class="modal__header">
        <p class="header-title">{{ 'MODAL.INFO_FILTER.TITLE' | translate }}</p>
        <app-icon-button (action)="EmitFilter()" class="icon-button" src="icons/32/check.svg"></app-icon-button>
    </div>
    <div class="modal__content">
        <div class="row">
            <div class="col-xsmall-12">
                <div class="settings__section">

                    <div class="spacer-3"></div>
                    <h2>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER.TITLE' | translate}}</h2>
                    <div class="spacer"></div>
                    <p>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER.TEXT' | translate}}</p>
                    <div class="spacer-and-half"></div>
                    <div class="settings__filter">
                        <app-filter [transformedItems]="mediFederalStates$ | async"
                                    (action)="SetFilter($event, UserPreferenceType.MedifinderFederalStates)"></app-filter>
                    </div>
                    <div class="spacer-4"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xsmall-12">
                <div class="settings__section">
                    <div class="line"></div>
                    <div class="spacer-3"></div>
                    <div class="settings__section-header">
                        <div class="settings__section-header--left">
                            <h2>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.TITLE' | translate}}</h2>
                            <p class="bold">{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.SUBTITLE' | translate}}</p>
                        </div>
                        <div class="settings__section-header--right">
                            <app-input-checkbox [checked]="mediPushNotifications$ | async"
                                                (action)="SetFilter($event, UserPreferenceType.MedifinderPushNotifications)"></app-input-checkbox>
                        </div>

                    </div>
                    <div>
                        <div class="spacer-2"></div>
                        <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.TEXT' | translate"></p>
                    </div>
                    <div class="spacer-4"></div>
                </div>
            </div>
        </div>

    </div>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
