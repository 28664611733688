import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from "@angular/core";
import { EntityTypesId } from "../../../io/entity/types/entityTypes";
import {
  selectEntitiesByType,
  selectEntity,
} from "../../../io/entity/store/entity.selector";
import { CacheRefresh } from "../../../io/entity/types/cache";
import { EntityService } from "../../../io/entity/entity.service";
import { Observable } from "rxjs";
import { Entity } from "../../../io/entity/types/entity";
import { transformPhoneNumber } from "../../../helper/helper-functions";
import { AppStore } from "../../../store/appStore";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-pharmacy",
  templateUrl: "./pharmacy.component.html",
  styleUrls: ["./pharmacy.component.scss"],
})
export class PharmacyComponent {
  @Input() id: string;

  @Input() drugTitle: string;

  @Input() ownerId: string;
  public owner$: Observable<Entity | undefined>;

  public pharmacy$: Observable<Entity>;

  constructor(
    private _entityService: EntityService,
    private _store: Store<AppStore>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setPharmacy();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setPharmacy();
  }

  public setPharmacy() {
    this.pharmacy$ = this._entityService.getEntity(
      EntityTypesId.Pharmacy,
      this.id,
      selectEntity,
      CacheRefresh.one_hour
    );

    this.owner$ = this._store.select(selectEntity(this.ownerId));

    this._changeDetectorRef.detectChanges();
  }

  protected readonly transformPhoneNumber = transformPhoneNumber;
  protected readonly encodeURIComponent = encodeURIComponent;
}
