import { createAction, props } from "@ngrx/store";

export const enum AUTH_ACTIONS {
  AUTH_LOGIN_SUCCESS = "[AUTH] Login Success",
  AUTH_LOGIN_ERROR = "[AUTH] Login Error",
}

export const authLoginSuccess = createAction(
  AUTH_ACTIONS.AUTH_LOGIN_SUCCESS,
  props<{
    token: string;
  }>()
);
export const authLoginError = createAction(
  AUTH_ACTIONS.AUTH_LOGIN_ERROR,
  props<{
    error: any;
  }>()
);
