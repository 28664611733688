<p class="label-form">{{label}}</p>
<div class="spacer" *ngIf="values.length !== 1"></div>
<div class="input_result">
    <div class="input" [ngClass]="{'flat-bottom': dropdownOpen, 'single-result': values.length === 1}" (click)="dropdownOpen = !dropdownOpen">{{value.text}}
    <div class="input__icon"></div></div>
    <div class="input_result__result" *ngIf="dropdownOpen">
        <div class="input_result__result__inner" *ngIf="values.length"
             [@listAnimation]="values.length">

            <div *ngFor="let value of values" class="input_result__result__value" (click)="setValue(value)">
                {{value.text}}
                <app-svg-icon
                        class="icon"
                        [src]="'icons/24/arrow-right.svg'"
                        [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
                ></app-svg-icon>
            </div>
        </div>
    </div>
</div>
<p class="error" *ngIf="invalid">{{errorText}}</p>

