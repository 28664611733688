import { Injectable } from "@angular/core";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiEntityResponse } from "./types/api-entity-response";
import { ApiConnectorService } from "../api-connector.service";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorViewService extends ApiConnectorService {
  constructor(public configService: ConfigService, public http: HttpClient) {
    super(configService);
  }

  public loadView(payload: any): Observable<ApiEntityResponse> {
    return this.http.get(
      this._configService.config.cms.url + "/jsonapi/" + payload.url
    ) as Observable<ApiEntityResponse>;
  }
}
