import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as AlertActions from "./alerts.actions";
import { ALERT_TYPES } from "./alerts.actions";
import cloneDeep from "lodash-es/cloneDeep";

export interface State {
  messages: Array<{
    title: string;
    message: string;
    type: string;
    duration: number;
    data?: any;
  }>;
}

export const initialState: State = {
  messages: [],
};

const alertReducer = createReducer(
  initialState,
  on(AlertActions.alertError, (state, action) => {
    state = cloneDeep(state);
    state.messages = [
      {
        title: action.title,
        message: action.message,
        duration: action.duration,
        type: ALERT_TYPES.ALERT,
      },
    ];
    return state;
  }),
  on(AlertActions.alertSuccess, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: action.title,
      message: action.message,
      duration: action.duration,
      type: ALERT_TYPES.SUCCESS,
    });
    return state;
  }),
  on(AlertActions.alertInfo, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: action.title,
      message: action.message,
      duration: action.duration,
      type: ALERT_TYPES.INFO,
      data: action.data,
    });
    return state;
  }),
  on(AlertActions.alertUpdate, (state, action) => {
    state = cloneDeep(state);
    state.messages.push({
      title: "ALERTS.UPDATE.TITLE",
      message: "ALERTS.UPDATE.MESSAGE",
      duration: -1,
      type: ALERT_TYPES.UPDATE,
      data: {
        versionNew: action.versionNew,
      },
    });
    return state;
  })
);
export function reducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}
export function AlertsReducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}

export const GetLastAlert = createFeatureSelector<any>("alerts");

export const SelectLastAlert = () =>
  createSelector(GetLastAlert, (alerts) => {
    if (alerts.messages.length) {
      return alerts.messages[alerts.messages.length - 1];
    } else {
      return;
    }
  });
export const SelectAllAlerts = () =>
  createSelector(GetLastAlert, (alerts) => {
    return alerts.messages;
  });
