export enum EntityTypesId {
  NewsPost = "node--news_post",
  MediaImage = "media--image",
  ApoDepartment = "apo_department--apo_department",
  FederalState = "taxonomy_term--federal_states",
  Tags = "taxonomy_term--tags",
  MediaDocument = "media--document",
  MediFinderSearch = "apo_medication_search--apo_medication_search",
  Pharmacy = "apo_pharmacy--apo_pharmacy",
}

export enum ViewType {
  Bundle = "[bundle][]",
  ModerationState = "[state]",
  FederalStates = "[federal-state][]",
  Tags = "[tags][]",
  PublishedAtMin = "[published-at][min]",
  PublishedAtMax = "[published-at][max]",
  SearchText = "[search-text]",
}

export interface EntityType {
  id: EntityTypesId;
  path?: string;
}

export const entityTypes: Array<EntityType> = [
  {
    id: EntityTypesId.NewsPost,
    path: "kammerinfo",
  },
  {
    id: EntityTypesId.Pharmacy,
    path: "apotheke",
  },
];

export function getEntityTypeById(id: EntityTypesId): EntityType | undefined {
  return entityTypes.find((et) => et.id === id);
}
