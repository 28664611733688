import { Entity, EntityAttributes } from "../../io/entity/types/entity";

export interface NewsPost extends Entity {
  attributes: NewsPostAttributes;
}

export interface NewsPostAttributes extends EntityAttributes {
  field_intro_text: {
    value: string;
    format: string;
    processed: string;
  };
  field_main_content: {
    value: string;
    format: string;
    processed: string;
  };
  field_main_image?: any;
  federalStates: Array<string>; //Array of federal state ids from relationship field_federal_states (added in selector)
  field_rejected_reason: string;
}

export enum ModerationSate {
  PUBLISHED = "published",
  WAITING_FOR_APPROVAL = "waiting_for_approval",
  DRAFT = "draft",
  REJECTED = "rejected",
  // following aren't actual states - but need them in info-overview
  OWN_PUBLISHED = "own_published",
  OWN_WAITING_FOR_APPROVAL = "own_waiting_for_approval",
  OWN_REJECTED = "own_rejected",
  HIDDEN = "hidden",
}

export enum ModerationStateUpdateStatus {
  INIT = "init",
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}
