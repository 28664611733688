import { CUSTOM_ERRORS } from "../core-error-handler";

export function ResponseStructureError(
  message: string = "",
  error: any = null
) {
  return {
    name: "ERROR.RESPONSE_STRUCTURE_ERROR.TITLE",
    message: message,
    customType: CUSTOM_ERRORS.RESPONSE_STRUCTURE_ERROR,
    detail: error,
    toString: function () {
      return `ResponseStructureError: The server response didn't match the expected structure: ${message}`;
    },
  };
}
