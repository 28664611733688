import { ChangeAppInitStatus, RefreshState } from "./status.actions";
import { APP_INIT_STATES } from "../status";
import { AUTH_STATUS } from "../auth/auth.states";
import { Action, createReducer, on } from "@ngrx/store";

import cloneDeep from "lodash-es/cloneDeep";
import { WaitingForApprovalNewsPost } from "../../approval/approval";

export interface State {
  appInitStatus: APP_INIT_STATES;
  authStatus: {
    status: AUTH_STATUS;
    error: any;
    pending: boolean;
  };
  refreshState: boolean;
}

const initialState: State = {
  appInitStatus: APP_INIT_STATES.INIT,
  authStatus: {
    status: AUTH_STATUS.INIT,
    error: null,
    pending: false,
  },
  refreshState: false,
};

const reducer = createReducer(
  initialState,
  on(ChangeAppInitStatus, (state, action) => {
    state = cloneDeep(state);

    state.appInitStatus = action.state;
    return state;
  }),
  on(RefreshState, (state, action) => {
    state = cloneDeep(state);

    state.refreshState = action.refresh;
    return state;
  })
);

export function AppStatusReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
