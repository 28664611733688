<h2>{{'PAGES.INFO_DETAIL.ATTACHMENTS.TITLE' | translate}}</h2>

<ng-container *ngFor="let attachment$ of attachments; let i = index">
    <ng-container *ngIf="(attachment$ | async) as attachment; else loading">

        <div class="spacer"></div>
        <div class="attachments__attachment">
            <app-svg-icon
                    class="icon"
                    [src]="'icons/32/clip.svg'"
                    [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
            ></app-svg-icon>
            <ng-container *ngIf="isBrowser">
                <a class="button-text" target="_blank" href="{{cmsURL}}{{attachment?.attributes?.url}}">{{ attachment?.attributes?.name }} ({{ attachment?.relationships?.field_media_document?.data?.attributes?.filesize | fileSize }})</a>
            </ng-container>
            <ng-container *ngIf="!isBrowser">
                <a class="button-text" (click)="download(cmsURL+attachment?.attributes?.url)">{{ attachment?.attributes?.name }} ({{ attachment?.relationships?.field_media_document?.data?.attributes?.filesize | fileSize }})</a>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #loading>
        <ng-container *ngIf="i === 0">
            <app-loading></app-loading>
        </ng-container>
    </ng-template>
</ng-container>

<div class="spacer-2"></div>
