import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileSize",
})
export class FileSizePipe implements PipeTransform {
  transform(size: string, extension: string = "MB") {
    return (parseInt(size) / (1024 * 1024)).toFixed(2) + extension;
  }
}
