import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./user.reducer";
import { clone } from "lodash-es";

export const GetUser = createFeatureSelector<State>("user");

export const GetUserName = createSelector(GetUser, (user: State) => {
  return user.keycloakUser.firstName;
});

export const GetUserFullName = createSelector(GetUser, (user: State) => {
  return user.keycloakUser.firstName + " " + user.keycloakUser.lastName;
});

export const GetUserDisplayName = createSelector(GetUser, (user: State) => {
  if (user.user) {
    return user.user.field_display_name;
  } else {
    return "";
  }
});

export const GetUserPharmacies = createSelector(GetUser, (user: State) => {
  if (user.user) {
    return user.user.field_assigned_pharmacies;
  } else {
    return undefined;
  }
});

export const GetUserInitials = createSelector(GetUser, (user: State) => {
  return (
    (user.keycloakUser.firstName ? user.keycloakUser.firstName.charAt(0) : "") +
    (user.keycloakUser.lastName ? user.keycloakUser.lastName.charAt(0) : "")
  );
});

export const GetUserUUID = createSelector(GetUser, (user: State) => {
  return user.user?.uuid;
});

export const GetDevice = createSelector(GetUser, (user) => {
  return user.device;
});

export const GetUserFederalStatesSettingForDashboard = (
  field = "field_dashboard_federal_states"
) =>
  createSelector(GetUser, (user: State) => {
    let dynamicKey = field as keyof typeof user.user;

    return user.federalStates.map((federalState) => {
      if (!user || !user.user) return undefined;
      return {
        label: federalState.name,
        active: !!(
          Array.isArray(user.user[dynamicKey]) ? user.user[dynamicKey] : []
        ).find((state) => state === federalState.tid),
        hidden:
          federalState.field_is_configurable === null ||
          federalState.field_is_configurable === "0",
        id: federalState.tid,
      };
    });
  });

export const GetUserFederalStatesSettingForFilter = createSelector(
  GetUser,
  (user: State) => {
    return user.federalStates.map((federalState) => {
      return {
        label: federalState.name,
        active: !!user.federalStatesFilter.find(
          (state) => state === federalState.tid
        ),
        hidden: false,
        id: federalState.tid,
      };
    });
  }
);

export const GetUserMedifinderFederalStatesSettingForFilter = createSelector(
  GetUser,
  (user: State) => {
    return user.federalStates.map((federalState) => {
      return {
        label: federalState.name,
        active: !!user.user?.field_medifinder_federal_states.find(
          (state) => state === federalState.tid
        ),
        hidden: false,
        id: federalState.tid,
      };
    });
  }
);

export const GetUserTagsSettingForFilter = createSelector(
  GetUser,
  (user: State) => {
    return user.tags.map((tag) => {
      return {
        label: tag.name,
        active: user.tagFilter.find((state) => state === tag.tid),
        id: tag.tid,
      };
    });
  }
);

export const GetUserFederalStatesSettingForPushNotification = (
  field = "field_push_federal_states"
) =>
  createSelector(GetUser, (user: State) => {
    return user.federalStates.map((federalState) => {
      return {
        label: federalState.name,
        active: user.user?.field_push_federal_states.find(
          (state) => state === federalState.tid
        ),
        hidden:
          federalState.field_is_configurable === undefined ||
          federalState.field_is_configurable === "0",
        id: federalState.tid,
      };
    });
  });

export const GetUserFederalStatesSettingForEmailNotifications = () =>
  createSelector(GetUser, (user: State) => {
    return user.federalStates.map((federalState) => {
      const hidden =
        federalState.field_is_configurable === undefined ||
        federalState.field_is_configurable === "0";

      return {
        label: federalState.name,
        active:
          user.user?.email_recipient?.subscribed_federal_states.find(
            (state) => state === federalState.tid
          ) || hidden,
        hidden: hidden,
        id: federalState.tid,
      };
    });
  });

export const GetUserTagsSettingForPushNotification = createSelector(
  GetUser,
  (user: State) => {
    return user.tags.map((tag) => {
      return {
        label: tag.name,
        active: user.user?.field_push_tags.find((state) => state === tag.tid),
        id: tag.tid,
      };
    });
  }
);

export const GetUserSettingForPushNotification = (
  field = "field_push_notifications"
) =>
  createSelector(GetUser, (user: State) => {
    if (!user || !user.user) return undefined;

    let dynamicKey = field as keyof typeof user.user;

    return user.user[dynamicKey] === true || user.user[dynamicKey] === "1";
  });

export const GetUserSettingForEmailNotification = (raw = false) =>
  createSelector(GetUser, (user: State) => {
    if (!user || !user.user) return undefined;

    if (raw && !user.user.email_recipient) {
      return undefined;
    }
    return user.user.email_recipient?.status === "1";
  });

export const GetUserFederalStatesPreferencesForDashboard = createSelector(
  GetUser,
  (user: State) => {
    return user.user?.field_dashboard_federal_states;
  }
);

export const GetUserOnboardingCompleteStatus = createSelector(
  GetUser,
  (user: State) => {
    return user.user?.field_onboarding_completed;
  }
);

export const GetEvent = createSelector(GetUser, (user: State) => {
  return user.event;
});

export const GetEventUrl = createSelector(GetUser, (user: State) => {
  return user.event?.field_lineupr_iframe_link;
});

export const GetDashboardLoadingStatus = createSelector(
  GetUser,
  (user: State) => {
    return user.status;
  }
);

export const GetUserId = createSelector(GetUser, (user: State) => {
  return user.user?.uid;
});

export const getNumberOfOwnRejectedNewsPosts = () =>
  createSelector(GetUser, (state) => {
    return clone({ count: state.own_rejected_count });
  });
