import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-speech-bubble',
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss']
})
export class SpeechBubbleComponent implements OnInit {
  /**
   * Text that gets displayed in the speech bubble
   */
  @Input() text:string = "";

  @HostBinding('class') get hostClasses() {
    const classList = ['speech-bubble'];
    return classList;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
