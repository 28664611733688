<app-back-header title="{{ 'PAGES.EMAIL_DATA_CHANGE.HEADER' | translate }}"
                 [buttonImage]="BackHeaderImageType.None"></app-back-header>
<div class="container">
    <!-- INIT -->
    <ng-container *ngIf="state === EmailDataStates.INIT">
        <form (ngSubmit)="onSubmit($event)">

            <p> {{'PAGES.EMAIL_DATA_CHANGE.TEXT' | translate}}</p>
            <div class="spacer spacer-4"></div>
            <app-input-text (currentValue)="onChange($event)"
                            [placeholder]="'PAGES.EMAIL_DATA_CHANGE.EMAIL.PLACEHOLDER' | translate"
                            [label]="'PAGES.EMAIL_DATA_CHANGE.EMAIL.LABEL'  | translate"
                            [errorText]="'PAGES.EMAIL_DATA_CHANGE.EMAIL.ERROR' | translate" type="email"
                            [required]="true" [invalid]="!emailValid && formDirty"></app-input-text>

            <div class="spacer spacer-2"></div>
            <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.SUBMIT' | translate" (action)="onSubmit($event)"
                        [fullWidth]="true">
            </app-button>
            <div class="spacer spacer-2"></div>
            <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.CANCEL' | translate" [buttonType]="ButtonType.Secondary" (action)="dashboard()"
                        [fullWidth]="true">
            </app-button>

        </form>
    </ng-container>

    <!-- PROGRESS -->
    <ng-container *ngIf="state === EmailDataStates.PROGRESS || state === EmailDataStates.CONFIRMATION">
        <div class="spacer spacer-8"></div>
        <app-loading></app-loading>
        <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.CANCEL' | translate" [buttonType]="ButtonType.Secondary" (action)="dashboard()"
                    [fullWidth]="true">
        </app-button>
    </ng-container>

    <!-- SUCCESS -->
    <ng-container *ngIf="state === EmailDataStates.SUCCESS">
        <div class="spacer spacer-8"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/124/circle-check.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
        <div class="spacer spacer-2"></div>
        <p class="center">{{'PAGES.EMAIL_DATA_CHANGE.SUCCESS' | translate}}</p>
        <div class="spacer spacer-2"></div>
        <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.BACK_TO_DASHBOARD' | translate" (action)="dashboard()"
                    [fullWidth]="true">

        </app-button>
    </ng-container>

    <!-- ERROR -->
    <ng-container *ngIf="state === EmailDataStates.ERROR">
        <div class="spacer spacer-8"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/124/circle-error.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
        <div class="spacer spacer-2"></div>
        <p class="center" [innerHTML]="'PAGES.EMAIL_DATA_CHANGE.ERROR' | translate : {error: errorMessage}"></p>
        <div class="spacer spacer-2"></div>
        <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.BACK_TO_DASHBOARD' | translate" (action)="dashboard()"
                    [fullWidth]="true">

        </app-button>
    </ng-container>

    <!-- SUCCESS -->
    <ng-container *ngIf="state === EmailDataStates.CONFIRMATION_SUCCESS">
        <div class="spacer spacer-8"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/124/circle-check.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
        <div class="spacer spacer-2"></div>
        <p class="center">{{'PAGES.EMAIL_DATA_CHANGE.CONFIRMATION_SUCCESS' | translate}}</p>
        <div class="spacer spacer-2"></div>
        <app-button [label]="'PAGES.EMAIL_DATA_CHANGE.BACK_TO_DASHBOARD' | translate" (action)="dashboard()"
                    [fullWidth]="true">

        </app-button>
    </ng-container>
</div>
