import { Injectable } from "@angular/core";
import { Entity } from "./types/entity";

@Injectable({
  providedIn: "root",
})
export class AliasService {
  private _aliases: { [key: string]: string };

  get cache(): any {
    return this._aliases;
  }

  constructor() {
    this._aliases = {};
  }

  /**
   * adds a resource to the alias object
   * @param resource
   * @param alias
   */
  public addAlias(resource: string, alias: string) {
    if (resource && alias) {
      this._aliases[resource] = alias;
    }
  }

  public addAliasFromEntities(entities: Array<Entity>) {
    entities.forEach((entity) => {
      try {
        this.addAlias(entity.id, entity.attributes.path.alias);
      } catch (e) {}
    });
  }

  /**
   * retrieve an alias by resource
   * @param resource
   */
  public retrieveAlias(resource: string): string | undefined {
    return this._aliases[resource];
  }

  public retrieveResourceFromAlias(alias: string): string | undefined {
    const flip = (data: { [key: string]: string }) =>
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => [value, key])
      );

    return flip(this._aliases)[alias];
  }
}
