<div class="modal__background" (click)="Close()"></div>
<div class="modal" @enterLeave *ngIf="open" [ngClass]="{'mobile': mobile}">
    <div class="modal__header">
        {{ "MEDIFINDER.NEW_SEARCH.TITLE" | translate }}
        <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
    </div>
    <div class="modal__content modal__content--{{creationState}}">

        <ng-container *ngIf="creationState === CreationState.INIT">
            <div>
            <!-- drug selection -->
            <div class="drug-search">
                <label class="label-form">{{"MEDIFINDER.NEW_SEARCH.DRUG.LABEL" | translate}}</label>

                <div class="drug-search__search-and-result" [ngClass]="{'drug-selected': selectedDrug}">
                    <div class="drug-search__search">
                        <app-input-search-text (action)="onDrugInput($event)"
                                               [placeholder]="'MEDIFINDER.NEW_SEARCH.DRUG.PLACEHOLDER' | translate"
                                               [bottomEffectOnInput]="true"
                                               [icon]="SearchIcons.book"></app-input-search-text>
                        <div class="drug-search__result"
                             *ngIf="drugSearchInput && drugSearchInput.length > 0">
                            <div class="drug-search__result__inner" *ngIf="result && result.length else noContent"
                                 [@listAnimation]="result.length">

                                <a href="#" *ngFor="let drug of result" class="drug-search__result__drug"
                                   (click)="onSelectDrug($event, drug)">
                                    {{drug.packagename}}
                                    <app-svg-icon
                                            class="icon"
                                            [src]="'icons/24/arrow-right.svg'"
                                            [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
                                    ></app-svg-icon>
                                </a>
                            </div>
                            <ng-template #noContent>
                                <app-loading *ngIf="searchDrugModal"></app-loading>
                                <p *ngIf="!searchDrugModal && drugSearchInput.length > 2"
                                   class="center no-results">{{"MEDIFINDER.NEW_SEARCH.DRUG.NO_RESULT" | translate}}</p>
                                <p *ngIf="!searchDrugModal && drugSearchInput.length <= 2"
                                   class="center no-results">{{"MEDIFINDER.NEW_SEARCH.DRUG.MORE_THAN_2" | translate}}</p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="drug-search__selected-drug">
                        <div class="box" *ngIf="selectedDrug">
                            <p class="caption">{{selectedDrug.productname}}</p>
                            <p class="package">{{selectedDrug.packagename}}</p>
                            <app-svg-icon
                                    class="icon"
                                    [src]="'icons/24/close_circle.svg'"
                                    [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
                                    (click)="selectedDrug = undefined"
                            ></app-svg-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="spacer spacer-6"></div>

            <!--pharmacy selection -->
            <div class="person-selection">
                <label class="caption">{{"MEDIFINDER.NEW_SEARCH.PERSON.LABEL" | translate}}</label>
                <p class="color--secondary bold">{{ (userDisplayName$ | async) }}</p>

                <ng-container *ngIf="pharmacies$ | async as pharmacies">

                    <div *ngFor="let pharmacy of pharmacies; trackBy: trackBy; let last = last" class="pharmacy-box" [ngClass]="{'pharmacy-box--active': selectedPharmacy == pharmacy.id, 'pharmacy-box--single': pharmacies.length === 1}" (click)="selectedPharmacy = pharmacy.id">
                            <p>{{pharmacy.attributes.name}}</p>
                            <div class="pharmacy__link">
                                <app-svg-icon
                                        class="icon"
                                        [src]="'icons/32/phone.svg'"
                                        [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
                                ></app-svg-icon>
                                <a id="pharmacy-phone" class="button-text"
                                   href="tel:{{transformPhoneNumber(pharmacy.attributes.phone)}}">{{pharmacy.attributes.phone}}</a>
                            </div>
                            <div class="pharmacy__link">
                                <app-svg-icon
                                        class="icon"
                                        [src]="'icons/32/mail.svg'"
                                        [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
                                ></app-svg-icon>
                                <a id="pharmacy-email" class="button-text"
                                   href="mailto:{{pharmacy.attributes.email}}">{{pharmacy.attributes.email}}</a>
                            </div>

                        <ng-container *ngIf="last && !contentInit">
                            {{ onContentInit(pharmacies) }}
                        </ng-container>
                    </div>

                </ng-container>

            </div>


            <div class="spacer spacer-10"></div>
            </div>

            <div>
            <app-button [fullWidth]="true" [label]="'MEDIFINDER.NEW_SEARCH.SUBMIT' | translate"
                        (click)="createNewSearch()" [disabled]="!(selectedDrug && selectedPharmacy)"></app-button>

                <div class="spacer spacer-4"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="creationState === CreationState.LOADING">
            <app-loading></app-loading>
        </ng-container>

        <ng-container *ngIf="creationState === CreationState.SUCCESS">
            <div class="flex-center">

                <app-svg-icon
                        class="icon"
                        [src]="'icons/124/circle-check.svg'"
                        [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
                ></app-svg-icon>
                <div class="spacer-2"></div>
                <p>{{'MEDIFINDER.NEW_SEARCH.CREATION.SUCCESS' | translate}}</p>

            </div>
            <div>
                <div [innerHTML]="'MEDIFINDER.NEW_SEARCH.CREATION.INFO' | translate" class="info"></div>
                <div class="spacer spacer-6"></div>
            <app-button label="{{'MEDIFINDER.NEW_SEARCH.CREATION.CLOSE' | translate}}"
                        (action)="Close()" [fullWidth]="true" [buttonType]="ButtonType.Secondary"></app-button>
                <div class="spacer-3"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="creationState === CreationState.ERROR">
            <div class="flex-center">
                <div class="spacer-3"></div>
                <app-svg-icon
                        class="icon"
                        [src]="'icons/124/circle-error.svg'"
                        [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
                ></app-svg-icon>
                <div class="spacer-3"></div>
                <p>{{'MEDIFINDER.NEW_SEARCH.CREATION.ERROR' | translate}}</p>
                <p class="caption">{{ errortext }}</p>
                <div class="spacer-3"></div>
                <app-button label="{{'MEDIFINDER.NEW_SEARCH.CREATION.CLOSE' | translate}}"
                            (action)="Close()" [fullWidth]="true"></app-button>
            </div>
        </ng-container>
    </div>

</div>
