<h1>{{'PAGES.INFO_DETAIL.FOOTER.TITLE' | translate}}</h1>

<div class="spacer-3"></div>


<ng-container *ngFor="let department$ of departments; let i = index">
    <ng-container *ngIf="(department$ | async) as department; else loading">

        <app-speech-bubble [text]="departments.length === 1 ? department.attributes.infos : ('DEPARTMENT_FOOTER.MULTIPLE' | translate)" *ngIf="i === 0"></app-speech-bubble>
        <div class="spacer-3"></div>
        <p class="header-title">{{department.attributes.name}}</p>
        <div class="spacer"></div>
        <div class="department-footer__link">
            <app-svg-icon
                    class="icon"
                    [src]="'icons/32/phone.svg'"
                    [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
            ></app-svg-icon>
            <a class="button-text"
               href="tel:{{transformPhoneNumber(department.attributes.phone)}}">{{department.attributes.phone}}</a>
        </div>
        <div class="department-footer__link">
            <app-svg-icon
                    class="icon"
                    [src]="'icons/32/mail.svg'"
                    [svgStyle]="{ 'width.px': '32', 'height.px': '32' }"
            ></app-svg-icon>
            <a class="button-text" href="mailto:{{department.attributes.email}}">{{department.attributes.email}}</a>
        </div>
    </ng-container>
    <ng-template #loading>
        <ng-container *ngIf="i === 0">
            <app-loading></app-loading>
        </ng-container>
    </ng-template>
</ng-container>


<div class="spacer-2"></div>
