<div class="alert-container">
    <ng-container *ngFor="let alert of activeAlerts; let i = index">
        <div
                class="alert"
                [@reveal]
                (mouseover)="StopTimeout(alert.id)"
                [ngClass]="{
        error: alert.type === alertTypes.ALERT,
        success: alert.type === alertTypes.SUCCESS
      }"
        >
            <ng-container
                    *ngIf="
          alert.type !== alertTypes.UPDATE && alert.type !== alertTypes.ALERT
        "
            >
                <div class="close" (click)="RemoveAlert(alert.id)">
                    <app-svg-icon
                            [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                            [src]="'icons/24/close.svg'"
                    ></app-svg-icon>
                </div>

                <div class="wrapper" (click)="handlePossibleAction(alert.data, alert.id)">
                    <div class="indicator">
                        <app-svg-icon
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                                src="icons/24/info.svg"
                        ></app-svg-icon>
                    </div>

                    <div class="content">
                        <h3
                                *ngIf="alert.title"
                                class="alert__title"
                                innerHTML="{{ alert.title | translate }}"
                        ></h3>
                        <div
                                class="alert__message"
                                innerHTML="{{ alert.message | translate }}"
                        ></div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="alert.type === alertTypes.ALERT">
                <div class="close" (click)="RemoveAlert(alert.id)">
                    <app-svg-icon
                            [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                            src="icons/24/close.svg"
                    ></app-svg-icon>
                </div>

                <div class="wrapper">
                    <div class="indicator">
                        <app-svg-icon
                                [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                                src="icons/24/error.svg"
                        ></app-svg-icon>
                    </div>
                    <div class="content">
                        <h3
                                *ngIf="alert.title"
                                class="alert__title schmuck"
                                innerHTML="{{ alert.title | translate }}"
                        ></h3>
                        <div
                                class="alert__message"
                                innerHTML="{{ alert.message | translate }}"
                        ></div>
                        <div class="spacer"></div>
                        <div class="button-container">
                        <app-button [label]="'ALERTS.RELOAD'" (action)="Reload()">
                        </app-button>
                        <app-button [label]="'ALERTS.GOTODASHBOARD'" (action)="ReloadToDashboard()">
                        </app-button>
                        </div>

                    </div>
                </div>


            </ng-container>
            <ng-container *ngIf="alert.type === alertTypes.UPDATE">


                <h3 class="alert__title" innerHTML="{{ alert.title | translate }}"></h3>
                <div
                        class="alert__message"
                        innerHTML="{{ alert.message | translate: alert.data }}"
                ></div>
                <div class="spacer"></div>
                <button class="button" (click)="Reload()">
                    {{ "ALERTS.UPDATE.YES" | translate }}
                </button>
                <button class="button button-secondary" (click)="RemoveAlert(alert.id)">
                    {{ "ALERTS.UPDATE.NO" | translate }}
                </button>
            </ng-container>
        </div>
    </ng-container>
</div>
