import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BiometricAccessService } from "../../services/biometric-access.service";
import { take } from "rxjs";

@Component({
  selector: "app-biometric-access-error",
  templateUrl: "./biometric-access-error.component.html",
  styleUrls: ["./biometric-access-error.component.scss"],
})
export class BiometricAccessErrorComponent implements OnInit {
  public errorString = "";
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _biometricAccessService: BiometricAccessService
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((params) => {
      const error = params["error"] ? params["error"] : "default";

      this.errorString = "BIOMETRIC_ACCESS.ERROR." + error;
    });
  }

  public retry() {
    this._biometricAccessService.forceCheck = true;

    this._biometricAccessService
      .checkBiometry()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this._router.navigateByUrl("/app/dashboard");
        }
      });
  }
}
