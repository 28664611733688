import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ResponseStructureError } from "../../error/types/response-structure-error";
import { EMPTY, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStore } from "../appStore";
import {
  loadDashboard,
  loadDashboardSuccess,
  setUserPreferences,
  setUserPreferencesSuccess,
  toggleEmailNotifications,
  toggleEmailNotificationsSuccess,
  USER_ACTIONS,
} from "./user.actions";
import { ApiConnectorDeviceService } from "../../io/device/api-connector-device.service";
import { ApiConnectorDashboardService } from "../dashboard/api-connector-dashboard.service";
import Bugsnag from "@bugsnag/js";
import { AlertService } from "../../alerts/alert.service";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { ApiEmailConnectorService } from "../email/api-email-connector.service";
import { EmailRecipient } from "./user";
import { QuestionnaireService } from "../../services/questionnaire.service";

@Injectable()
export class UserEffects {
  loadDashboard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadDashboard),
      mergeMap((initialPayload) => {
        return this._deviceApi.setDevice(initialPayload).pipe(
          map((payload) => {
            try {
              if (payload && payload.user) {
                Bugsnag.setUser(
                  payload.user.uid,
                  payload.user.mail,
                  payload.user.field_display_name
                );
              }
            } catch (e) {}

            try {
              this._questionnaireService.parseData(payload.paperform);
            } catch (e) {
              console.error("Error parsing paperform data", e.message);
            }

            try {
              return loadDashboardSuccess({ response: payload });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError((e) => {
            Bugsnag.notify(`Error parsing dashboard, ${e.message}`);
            if (e.name === "TimeoutError") {
              this._navigationService.navigateByUrl(
                "/timeout-error",
                BasePageAnimationDirection.FADE,
                { error: "timeout" }
              );
            } else {
              this._alertService.DispatchAlert(
                "Fehler beim Laden",
                "Es ist ein Fehler beim Laden des Dashboards aufgetreten. Bitte versuchen Sie es später erneut. (" +
                  e.name +
                  "/" +
                  e.message +
                  ")"
              );
            }
            return EMPTY;
          })
        );
      })
    )
  );

  setUserPreferences$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setUserPreferences),
      mergeMap((initialPayload) => {
        return this._dashboardApi.setUserPreferences(initialPayload).pipe(
          map((payload) => {
            try {
              return setUserPreferencesSuccess({
                response: payload,
                payload: initialPayload,
              });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError(() => EMPTY),
          takeUntil(
            this._actions$.pipe(
              ofType(USER_ACTIONS.USER_SET_PREFERENCES_CANCEL)
            )
          )
        );
      })
    )
  );

  toggleEmailNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(toggleEmailNotifications),
      mergeMap((initialPayload) => {
        return this._emailApi.setEmailNotifications(initialPayload.value).pipe(
          map((payload) => {
            try {
              return toggleEmailNotificationsSuccess({
                value: payload as EmailRecipient,
              });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError(() => EMPTY),
          takeUntil(
            this._actions$.pipe(
              ofType(USER_ACTIONS.USER_SET_PREFERENCES_CANCEL)
            )
          )
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _deviceApi: ApiConnectorDeviceService,
    private _emailApi: ApiEmailConnectorService,
    private _dashboardApi: ApiConnectorDashboardService,
    private _alertService: AlertService,
    private _navigationService: NavigationService,
    private _questionnaireService: QuestionnaireService
  ) {}
}
