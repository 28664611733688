import { Pipe, PipeTransform } from "@angular/core";
import { Entity } from "../../../io/entity/types/entity";

@Pipe({
  name: "pharmacyToDropdown",
})
export class PharmacyToDropdownPipe implements PipeTransform {
  transform(value: Array<Entity>, ...args: unknown[]): unknown {
    console.log(value);
    return value.map((pharmacy) => {
      return {
        id: pharmacy.id,
        text: pharmacy.attributes.name,
      };
    });
  }
}
