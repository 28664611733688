import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { easingIn, easingOut } from "./animations";

export const listAnimation = trigger("listAnimation", [
  transition("* => *", [
    query(
      ":enter",
      [
        style({ opacity: 0, transform: "translateY(50px)" }),
        stagger(5, [
          animate(
            "250ms " + easingIn,
            style({ opacity: 1, transform: "translateY(0px)" })
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
]);
