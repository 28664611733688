<div class="container">
    <div class="onboarding content">
        <ng-container *ngIf="(sliderContent$ | async) as sliderContent">
            <div class="row">
                <div class="col-xsmall-12">
                    <app-svg-icon
                        class="icon"
                        [ngClass]="{show: currentSlide}"
                        [src]="'logo.svg'"
                        [svgStyle]="{ 'width.px': '76', 'height.px': '47' }"
                    ></app-svg-icon>
                </div>
            </div>
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="navigation-wrapper">
                        <div class="keen-slider keen-slider--vertical" [ngClass]="{show: elementsLoaded}" #sliderRef>
                            <div class="keen-slider__slide number-slide{{i}}" *ngFor="let slide of sliderContent; index as i">
                                <ng-container
                                    [ngTemplateOutlet]="slide.id === 'federal_states_configuration' ? configurationSlide : defaultSlide"
                                    [ngTemplateOutletContext]="{ slide: slide }"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="dots">
                        <button
                            (click)="HandleSliderNavigation(i, sliderContent)"
                            *ngFor="let slide of dotHelper; let i = index"
                            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
                        ></button>
                    </div>
                </div>
            </div>
            <div *ngIf="sliderContent.length" class="onboarding__button-wrapper" [ngClass]="{'flex-wrapper': sliderContent[currentSlide].id === 'push_notification_configuration'}">
                <ng-container
                    [ngTemplateOutlet]="currentSlide === dotHelper.length - 1 ? lastSlideButtons : (sliderContent[currentSlide].id === 'push_notification_configuration' ? configurationButtons : defaultButtons)"
                    [ngTemplateOutletContext]="{ configurationSlide: sliderContent[currentSlide].id === 'federal_states_configuration' }"
                ></ng-container>
            </div>
        </ng-container>
    </div>
</div>

<!-- slide templates -->
<ng-template #defaultSlide let-slide="slide">
    <div class="image"><img [src]="'assets/img/onboarding/' + slide.id + '.png'" [alt]="slide.title"></div>
    <h1>{{slide.title}}</h1>
    <div class="spacer-2"></div>
    <p class="paragraph-highlight">{{slide.text}}</p>
</ng-template>

<ng-template #configurationSlide let-slide="slide">
    <div class="spacer-4"></div>
    <h1>{{slide.title}}</h1>
    <div class="spacer-2"></div>
    <p class="paragraph-highlight">{{slide.text}}</p>
    <div class="spacer-4"></div>
    <div class="onboarding__filter">
        <app-filter [transformedItems]="federalStates$ | async" (action)="SetPreference($event, OnboardingActionsType.DashboardFederalStates)"></app-filter>
    </div>
</ng-template>

<!-- button templates -->
<ng-template #defaultButtons let-configurationSlide="configurationSlide">
    <p *ngIf="configurationSlide" class="label-form-helper center">{{ 'PAGES.ONBOARDING.FEDERAL_STATES_PRE_BUTTON_TEXT' | translate }}</p>
    <div *ngIf="configurationSlide" class="spacer"></div>
    <app-button (action)="(configurationSlide && !federalStatesSelected) ? null: HandleSliderNavigation(currentSlide+1  )" [disabled]="configurationSlide && !federalStatesSelected" [fullWidth]="true" [buttonType]="buttonType.Primary" label="{{'PAGES.ONBOARDING.BUTTON.NEXT' | translate}}"></app-button>
</ng-template>

<ng-template #configurationButtons>
    <app-button (action)="SetPreference($event, OnboardingActionsType.PushNotificationDecline)" [fullWidth]="true" [buttonType]="buttonType.Secondary" label="{{'PAGES.ONBOARDING.BUTTON.DECLINE' | translate}}"></app-button>
    <app-button (action)="SetPreference($event, OnboardingActionsType.PushNotificationAccept)" [fullWidth]="true" [buttonType]="buttonType.Primary" label="{{'PAGES.ONBOARDING.BUTTON.ACCEPT' | translate}}"></app-button>
</ng-template>

<ng-template #lastSlideButtons>
    <app-button (action)="navigateToDashboard()" [fullWidth]="true" [buttonType]="buttonType.Primary" label="{{'PAGES.ONBOARDING.BUTTON.START' | translate}}"></app-button>
</ng-template>
