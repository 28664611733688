import { CoreConfig } from "./core-config.type";
import { environment } from "../../environments/environment";
import { Environments } from "../../environments/environments";

export const coreConfig: CoreConfig = {
  type: Environments.local,
  version: environment.version,
  auth: {
    url: "https://idm-test.gehaltskasse.at/auth/",
    tokenEndpoint: "realms/GK/protocol/openid-connect/auth",
    accessTokenEndpoint: "realms/GK/protocol/openid-connect/token",
    logoutEndpoint: "realms/GK/protocol/openid-connect/logout",
    realm: "GK",
    clientId: "apoapp-frontend",
    redirectUri: "http://localhost:4200",
    redirectUriiOS: "at.liechtenecker.apoapp://login",
    redirectUriiOSLogout: "at.liechtenecker.apoapp://logout",
    redirectUriAndroid: "",
  },

  storage: {
    localStoragePrefix: "apoapp-dev-",
    accessToken: "access-token",
    refreshToken: "refresh-token",
    idToken: "id-token",
  },
  cms: {
    url: "https://apoapp-cms-develop.liechtenecker.dev",
  },
};

export const coreConfigLocalCms = {
  cms: {
    url: "http://localhost:50018",
  },
};

export const coreConfigProdCms = {
  cms: {
    url: "https://cms.app.apothekerkammer.at",
  },
};

//these two configs for testing | production should only include parts of the config that
//need to be overwritten and shouldn't be a whole config object
export const coreConfigDevelop = {
  type: Environments.develop,
  auth: {
    redirectUri: "https://apoapp-develop.liechtenecker.dev",
    url: "https://idm-test.gehaltskasse.at/auth/",
    tokenEndpoint: "realms/GK/protocol/openid-connect/auth",
    accessTokenEndpoint: "realms/GK/protocol/openid-connect/token",
    logoutEndpoint: "realms/GK/protocol/openid-connect/logout",
    realm: "GK",
    clientId: "apoapp-frontend",
    redirectUriiOS: "at.liechtenecker.apoapp://login",
    redirectUriAndroid: "at.liechtenecker.apoapp://login",
    redirectUriiOSLogout: "at.liechtenecker.apoapp://logout",
  },
};

export const coreConfigStaging = {
  type: Environments.staging,
  auth: {
    redirectUri: "https://apoapp-staging.liechtenecker.dev",
    url: "https://idm-test.gehaltskasse.at/auth/",
    tokenEndpoint: "realms/GK/protocol/openid-connect/auth",
    accessTokenEndpoint: "realms/GK/protocol/openid-connect/token",
    logoutEndpoint: "realms/GK/protocol/openid-connect/logout",
    realm: "GK",
    clientId: "apoapp-frontend",
    redirectUriiOS: "at.liechtenecker.apoapp-staging://login",
    redirectUriAndroid: "at.liechtenecker.apoapp-staging://login",
    redirectUriiOSLogout: "at.liechtenecker.apoapp-staging://logout",
  },
  storage: {
    localStoragePrefix: "apoapp-staging-",
    accessToken: "access-token",
    refreshToken: "refresh-token",
    idToken: "id-token",
  },
  cms: {
    url: "https://apoapp-cms-staging.liechtenecker.dev",
  },
};

export const coreConfigRc = {
  type: Environments.rc,
  auth: {
    redirectUri: "https://apoapp-rc.liechtenecker.dev",
    url: "https://idm-test.gehaltskasse.at/auth/",
    tokenEndpoint: "realms/GK/protocol/openid-connect/auth",
    accessTokenEndpoint: "realms/GK/protocol/openid-connect/token",
    logoutEndpoint: "realms/GK/protocol/openid-connect/logout",
    realm: "GK",
    clientId: "apoapp-frontend",
    redirectUriiOS: "at.liechtenecker.apoapp-rc://login",
    redirectUriAndroid: "at.liechtenecker.apoapp-rc://login",
    redirectUriiOSLogout: "at.liechtenecker.apoapp-rc://logout",
  },
  storage: {
    localStoragePrefix: "apoapp-rc-",
    accessToken: "access-token",
    refreshToken: "refresh-token",
    idToken: "id-token",
  },
  cms: {
    url: "https://apoapp-cms-rc.liechtenecker.dev",
  },
};

export const coreConfigProduction = {
  type: Environments.production,
  storage: {
    localStoragePrefix: "apoapp-production-",
    accessToken: "access-token",
    refreshToken: "refresh-token",
    idToken: "id-token",
  },
  auth: {
    url: "https://idm.gehaltskasse.at/auth/",
    tokenEndpoint: "realms/GK/protocol/openid-connect/auth",
    accessTokenEndpoint: "realms/GK/protocol/openid-connect/token",
    logoutEndpoint: "realms/GK/protocol/openid-connect/logout",
    realm: "GK",
    clientId: "apoapp-frontend",
    redirectUriiOS: "at.liechtenecker.apoapp://login",
    redirectUriiOSLogout: "at.liechtenecker.apoapp://logout",
    redirectUriAndroid: "at.liechtenecker.apoapp://login",
    redirectUri: "https://app.apothekerkammer.at",
  },
  cms: {
    url: "https://cms.app.apothekerkammer.at",
  },
};
