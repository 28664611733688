<ng-container *ngIf="viewHelper && viewHelper.hasNextPage() && (!hideOnElements || (hideOnElements && viewHelper.loadedEntities < hideOnElements))">
    <ng-container *ngIf="showPager && viewHelper.loadedEntities !== 0">
        <p class="pager-text">{{"LOAD_MORE.INFO" | translate:{total: viewHelper.totalEntities, loaded: viewHelper.loadedEntities} }}</p>
        <div class="pager">
            <div class="pager__item" *ngFor="let pagerItemState of viewHelper.pagerArray" [ngClass]="{'pager__item--active': pagerItemState}" ></div>
        </div>
    </ng-container>
    <div class="button__wrapper" tabindex="0" (click)="viewHelper.loadNextPage()" [ngClass]="{disabled: viewHelper.state === viewHelperViewState.LOADING}" >

        <ng-container *ngIf="viewHelper.state === viewHelperViewState.DEFAULT && (viewHelper.totalEntities - viewHelper.loadedEntities) !== 1">
            {{label | translate:{count: (viewHelper.totalEntities - viewHelper.loadedEntities) > viewHelper.entriesPerPage ? viewHelper.entriesPerPage : (viewHelper.totalEntities - viewHelper.loadedEntities)} }}
        </ng-container>
        <ng-container *ngIf="viewHelper.state === viewHelperViewState.DEFAULT && (viewHelper.totalEntities - viewHelper.loadedEntities) === 1">
            {{labelSingular | translate }}
        </ng-container>
        <ng-container *ngIf="viewHelper.state === viewHelperViewState.LOADING">
            {{labelLoading | translate}}
        </ng-container>

    </div>
</ng-container>
