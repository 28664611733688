import { Observable, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStore } from "../../store/appStore";
import { ViewsExposedFilter } from "../../io/entity/view.service";
import {
  GetUserFederalStatesSettingForFilter,
  GetUserMedifinderFederalStatesSettingForFilter,
} from "../../store/user/user.selector";

const _killFilterSubscription: Subject<boolean> = new Subject<boolean>();
export function getMedifinderFederalStatesFilter(
  _store: Store<AppStore>
): Observable<Array<ViewsExposedFilter>> {
  return new Observable((obs) => {
    _store
      .select(GetUserMedifinderFederalStatesSettingForFilter)
      .pipe(takeUntil(_killFilterSubscription))
      .subscribe((filter) => {
        if (filter && filter.length > 0) {
          _killFilterSubscription.next(true);
          const modalFilter: Array<ViewsExposedFilter> = [];
          filter.forEach((filt) => {
            if (filt.id && !!filt.active) {
              modalFilter.push({
                id: "[federal-state][]",
                value: filt.id,
              });
            }
          });
          obs.next(modalFilter);
          obs.complete();
        }
      });
  });
}
