import { Entity } from "./entity";

export enum CacheRefresh {
  always,
  never,
  ten_seconds,
  one_minute,
  ten_minutes,
  one_hour
}
