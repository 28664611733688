import { Injectable } from "@angular/core";
import { Observable, Subscriber, Subscription, take } from "rxjs";
import { Selector, Store } from "@ngrx/store";
import { selectNewsPostEntitiesByIds } from "./store/entity.selector";
import { CacheRefresh } from "./types/cache";
import { CacheService } from "./cache.service";
import { AppStore } from "../../store/appStore";
import { cancelLoadView, loadView } from "./store/view.actions";
import { Actions, ofType } from "@ngrx/effects";
import { addEntities, addEntity } from "./store/entity.actions";
import { Router } from "@angular/router";
import { ViewHelper } from "./view-helper/view-helper";

export interface ViewsExposedFilter {
  id: string;
  value: string;
}

export interface ViewsExposedUser {
  id: number | null | string;
}

@Injectable({
  providedIn: "root",
})
export class ViewService {
  private _subscriptions: { [key: string]: Subscription } = {};

  private _activeViewHelper: ViewHelper | undefined;

  get activeViewHelper() {
    return this._activeViewHelper;
  }

  set activeViewHelper(value) {
    this._activeViewHelper = value;
  }

  public unsetActiveViewHelper() {
    this._activeViewHelper = undefined;
  }

  constructor(
    private _cacheService: CacheService,
    private _store: Store<AppStore>,
    private _actions$: Actions,
    private _router: Router
  ) {
    this._actions$.pipe(ofType("[Entity] purgeEntities")).subscribe((res) => {
      this.unsubscribe();
    });
  }

  private unsubscribe() {
    Object.values(this._subscriptions).forEach((value: Subscription) => {
      if (value) {
        value.unsubscribe();
      }
    });
    this._subscriptions = {};
  }

  public loadView(
    viewId: string,
    viewDisplay: string,
    exposedFilter: Array<ViewsExposedFilter> = [],
    exposedUser: ViewsExposedUser = { id: null },
    selector: Selector<any, any> = selectNewsPostEntitiesByIds,
    cacheRefresh: CacheRefresh = CacheRefresh.always,
    page: number,
    include: Array<string> = []
  ): boolean {
    const viewURI = this.resolveURI(
      viewId,
      viewDisplay,
      exposedFilter,
      exposedUser,
      page,
      include,
      false
    );

    /*
    check the CacheService if this view has already been loaded. If it was already loaded in the past,
    the cache service will have all entity ids for this view cached
   */
    const cacheData = this._cacheService.retrieveFromCache(viewURI);
    let cache = cacheData.cache;

    //if the cache has no information about this view, load the view
    if (
      !cache ||
      (this._cacheService.shouldLoad(
        viewURI,
        cacheRefresh,
        cacheData.requestedPageInCache
      ) &&
        !cache.intermediate)
    ) {
      //add an intermediate version to the cache to prevent multiple requests for the same view
      this._cacheService.addToCache(viewURI, undefined, true);
      //load the view
      this.dispatchLoad(viewURI);

      return true;
    }

    return false;
  }

  /**
   * dispatches the correct entity load action
   * @param uri
   */
  public dispatchLoad(uri: string) {
    //this._store.dispatch(cancelLoadView());
    this._store.dispatch(loadView({ url: uri }));
  }

  public resolveURI(
    viewId: string,
    viewDisplay: string,
    exposedFilter: Array<ViewsExposedFilter>,
    exposedUser: ViewsExposedUser,
    page: number,
    include: Array<string> = [],
    ignorePage: boolean = false
  ): string {
    const exposedFilterString = exposedFilter
      .map((filter) => `views-filter${filter.id}=${filter.value}`)
      .join("&");

    const exposedUserString =
      exposedUser && exposedUser.id
        ? `&views-argument[]=${exposedUser.id}`
        : ``;

    let includeString = "";
    if (include.length) {
      includeString = "&include=" + include.join(",");
    }

    const uri = `views/${viewId}/${viewDisplay}?${exposedFilterString}${exposedUserString}&page=${page}${includeString}`;

    if (ignorePage) {
      return uri.replace(/\&page=\d{0,3}/gm, "");
    }

    return uri;
  }

  public stripPageFromURI(uri: string): string {
    return uri.replace(/\&page=\d{0,3}/gm, "");
  }

  public buildURI(url: string) {
    let filter: Array<ViewsExposedFilter> = [];
    let user: ViewsExposedUser = { id: null };

    // search for everything that starts with 'views-filter' and ends with '&' or end of string
    const matches = [...url.matchAll(/(?:views-filter)(.*?)(?=&|$)/g)];

    // build ViewExposedFilter Object for each found views-filter
    matches.forEach((match) => {
      let splittedMatch = match[0].split("=");
      filter.push({
        id: splittedMatch[0],
        value: splittedMatch[1],
      });
    });

    // search for everything that starts with 'views-argument' and ends with '&' or end of string
    const argumentMatch = url.match("(?:views-argument)(.*?)(?=&|$)");

    // build ViewExposedUser Object
    if (argumentMatch) {
      let splittedArgumentMatch = argumentMatch[0].split("=");
      user.id = Number(splittedArgumentMatch[1]);
    }

    return { exposedFilter: filter, exposedUser: user };
  }
}
