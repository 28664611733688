import { Component, OnDestroy } from "@angular/core";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { ButtonType } from "../../ui/button/button.type";
import { AuthService } from "../../store/status/auth/auth.service";
import { Store } from "@ngrx/store";
import {
  GetUserFederalStatesSettingForDashboard,
  GetUserFederalStatesSettingForEmailNotifications,
  GetUserFederalStatesSettingForPushNotification,
  GetUserFullName,
  GetUserSettingForEmailNotification,
  GetUserSettingForPushNotification,
  GetUserTagsSettingForPushNotification,
} from "../../store/user/user.selector";
import { InputActionsType } from "../../ui/input/input-checkbox/input-actions.type";
import { EntityService } from "../../io/entity/entity.service";
import { UserPreferenceType } from "../../store/user/user.type";
import {
  cancelSetUserPreferences,
  setUserPreferences,
  toggleEmailNotifications,
} from "../../store/user/user.actions";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { DeviceService } from "../../io/device/device.service";
import { environment } from "../../../environments/environment";
import Bugsnag from "@bugsnag/js";
import { TabItem } from "../../ui/tabs/tabs";
import { ButtonOutput } from "../../ui/button/button-output";
import { first } from "rxjs";
import { Environments } from "../../../environments/environments";
import { QuestionnaireService } from "../../services/questionnaire.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: [
    "./settings.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
})
export class SettingsComponent
  extends AnimationBaseComponent
  implements OnDestroy
{
  public fullName$;
  public tags$;
  public federalStates$;
  public federalStatesPush$;
  public pushNotifications$;

  public emailNotifications$;
  public federalStateEmail$;

  public mediFederalStates$;
  public mediPushNotifications$;

  public ButtonType = ButtonType;
  public UserPreferenceType = UserPreferenceType;

  public version = environment.version;

  public activeTab = "news";
  public tabs: Array<TabItem> = [
    {
      label: "PAGES.SETTINGS.TABS.NEWS",
      active: true,
      value: "news",
      weight: 0,
    },
    {
      label: "PAGES.SETTINGS.TABS.MEDIFINDER",
      active: false,
      value: "medifinder",
      weight: 0,
    },
  ];

  public environment = environment.deployment;
  public environments = Environments;

  public backTo: DashboardRoutesType;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _store: Store,
    private _entityService: EntityService,
    protected override _navigationService: NavigationService,
    private _deviceService: DeviceService,
    private _activatedRoute: ActivatedRoute,
    public questionnaireService: QuestionnaireService
  ) {
    super(_navigationService);

    this.fullName$ = this._store.select(GetUserFullName);

    this.federalStates$ = this._store.select(
      GetUserFederalStatesSettingForDashboard()
    );
    this.federalStatesPush$ = this._store.select(
      GetUserFederalStatesSettingForPushNotification()
    );
    this.federalStateEmail$ = this._store.select(
      GetUserFederalStatesSettingForEmailNotifications()
    );

    this.tags$ = this._store.select(GetUserTagsSettingForPushNotification);

    this.pushNotifications$ = this._store.select(
      GetUserSettingForPushNotification()
    );

    this.emailNotifications$ = this._store.select(
      GetUserSettingForEmailNotification()
    );

    this.mediFederalStates$ = this._store.select(
      GetUserFederalStatesSettingForDashboard("field_medifinder_federal_states")
    );

    this.mediPushNotifications$ = this._store.select(
      GetUserSettingForPushNotification("field_medifinder_push_enabled")
    );

    this._activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      if (params["tab"]) {
        this.activeTab = params["tab"];

        this.tabs.forEach((tab) => {
          tab.active = tab.value === this.activeTab;
        });
      }

      if (params["from"]) {
        this.backTo = params["from"] as DashboardRoutesType;
      }
    });
  }

  public SetEvents(event: any) {
    let route = this.backTo ? this.backTo : DashboardRoutesType.Dashboard;

    switch (event.type) {
      case ButtonActionsType.Click:
        this._navigationService.navigateByUrl(
          route,
          BasePageAnimationDirection.UP
        );
        break;
    }
  }

  public SetFilter(event: any, preference: UserPreferenceType, data?: any) {
    switch (preference) {
      case UserPreferenceType.DashboardFederalStates:
      case UserPreferenceType.PushFederalStates:
      case UserPreferenceType.PushTags:
      case UserPreferenceType.MedifinderFederalStates:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          setUserPreferences({
            id: preference,
            value: event.data
              .filter((filter: any) => filter.active)
              .map((f: any) => f.id),
          })
        );
        break;
      case UserPreferenceType.PushNotifications:
      case UserPreferenceType.MedifinderPushNotifications:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          setUserPreferences({
            id: preference,
            value: event.type == InputActionsType.Checked,
          })
        );
        break;
      case UserPreferenceType.EmailNotifications:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          toggleEmailNotifications({
            value: {
              subscribed_federal_states: data
                .filter((f: { active: Boolean }) => f.active)
                .map((f: any) => f.id),
              status: event.type === InputActionsType.Checked ? "1" : "0",
            },
          })
        );
        break;
      case UserPreferenceType.EmailNotificationStates:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          toggleEmailNotifications({
            value: {
              subscribed_federal_states: event.data
                .filter((f: { active: Boolean }) => f.active)
                .map((f: any) => f.id),
              status: "1",
            },
          })
        );
        break;
    }
  }

  public Logout() {
    this._deviceService
      .deactivateDeviceHandler()
      .subscribe((deviceDeactivated: boolean) => {
        setTimeout(() => {
          this._router.navigateByUrl("login");
        }, 100);
        this._authService.logout();
      });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  public debug() {
    Bugsnag.notify("Debug", (event) => {
      event.addMetadata("debug", {
        atkn: this._authService.accessToken
          ? btoa(this._authService.accessToken)
          : "",
        rtkn: this._authService.refreshToken
          ? btoa(this._authService.refreshToken)
          : "",
      });
    });
    alert("Debug-Informationen wurden gesendet.");
  }

  public setTab(action: ButtonOutput) {
    this.activeTab = action.data;
  }

  protected readonly InputActionsType = InputActionsType;
  protected readonly Environments = Environments;
}
