import { Injectable } from "@angular/core";
import { ConfigService } from "../../../config/config.service";
import { AppStore } from "../../appStore";
import { Store } from "@ngrx/store";
import {
  OAuth2AuthenticateOptions,
  OAuth2Client,
} from "@byteowls/capacitor-oauth2";
import { Observable, Observer, take } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CoreConfig } from "../../../config/core-config.type";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { Environments } from "../../../../environments/environments";
import { authLoginSuccess } from "./store/auth.actions";
import { Capacitor } from "@capacitor/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { Browser } from "@capacitor/browser";
import Bugsnag from "@bugsnag/js";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  get accessToken() {
    const token = localStorage.getItem(
      this._config.storage.localStoragePrefix + this._config.storage.accessToken
    );
    return token ? token : "";
  }

  get refreshToken() {
    return localStorage.getItem(
      this._config.storage.localStoragePrefix +
        this._config.storage.refreshToken
    );
  }

  get idToken() {
    return localStorage.getItem(
      this._config.storage.localStoragePrefix + this._config.storage.idToken
    );
  }

  get user() {
    return {
      ...new JwtHelperService().decodeToken(
        this.accessToken ? this.accessToken : ""
      ),
      ...{ token: this.accessToken },
    };
  }

  get eMail() {
    return this.user.upn;
  }

  private _config: CoreConfig;
  private _authenticationRunning = false;
  private _initialRequest = true;
  private _oauth2Options: OAuth2AuthenticateOptions;

  get oauth2Options(): OAuth2AuthenticateOptions {
    return this._oauth2Options;
  }

  get initialRequest(): boolean {
    return this._initialRequest;
  }

  constructor(
    private _configService: ConfigService,
    private _store: Store<AppStore>,
    private _router: Router,
    private _keycloakService: KeycloakService
  ) {
    this._config = this._configService.config;

    this._oauth2Options = {
      authorizationBaseUrl:
        this._config.auth.url + this._config.auth.tokenEndpoint,
      scope: "openid profile",
      accessTokenEndpoint:
        this._config.auth.url + this._config.auth.accessTokenEndpoint,
      pkceEnabled: true,
      appId: this._config.auth.clientId,
      logsEnabled:
        environment.deployment === Environments.develop ||
        environment.deployment === Environments.staging,
      web: {
        appId: this._config.auth.clientId,
        responseType: "code",
        pkceEnabled: true,
        redirectUrl: this._config.auth.redirectUri,
        windowOptions: "height=600,left=0,top=0",
      },
      ios: {
        appId: this._config.auth.clientId,
        responseType: "code",
        redirectUrl: this._config.auth.redirectUriiOS,
        pkceEnabled: true,
      },
      android: {
        appId: this._config.auth.clientId,
        responseType: "code",
        redirectUrl: this._config.auth.redirectUriAndroid,
        pkceEnabled: true,
      },
    };

    if (Capacitor.getPlatform() === "web") {
      if (this._keycloakService.isLoggedIn()) {
        this._keycloakService.getToken().then((token: string) => {
          this._store.dispatch(authLoginSuccess({ token: token }));
          this.saveToken(token);
        });
      }

      this._keycloakService.keycloakEvents$.subscribe({
        next: (e: any) => {
          if (e.type == KeycloakEventType.OnTokenExpired) {
            this._keycloakService.updateToken(20);
          }
        },
      });
    } else {
      this.isAuthenticated()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this._store.dispatch(authLoginSuccess({ token: this.accessToken }));
          }
          this._initialRequest = true;
        });
    }
  }

  public authenticationObservable: Observable<boolean>;
  public authenticationObservableCompleted = true;

  public isAuthenticated(setInitial = false) {
    if (!this.authenticationObservableCompleted) {
      return this.authenticationObservable;
    }

    this.authenticationObservable = new Observable((obs) => {
      if (setInitial) this._initialRequest = false;

      //if access token is valid then everything is fine
      if (this.accessToken && !this.isAuthTokenExpired()) {
        obs.next(true);
        this.authenticationObservableCompleted = true;
        obs.complete();
      } else if (this.refreshToken && !this.isRefreshTokenExpired()) {
        if (Capacitor.getPlatform() === "web") {
          this._keycloakService.updateToken(20).then((res: boolean) => {
            obs.next(res);
            this.authenticationObservableCompleted = true;
            obs.complete();
          });
        } else {
          OAuth2Client.refreshToken({
            refreshToken: this.refreshToken,
            appId: this._oauth2Options.appId ? this._oauth2Options.appId : "",
            accessTokenEndpoint: this._oauth2Options.accessTokenEndpoint
              ? this._oauth2Options.accessTokenEndpoint
              : "",
          })
            .then((response) => {
              this.saveToken(
                response.access_token,
                response.refresh_token,
                response.id_token ? response.id_token : null
              );
              obs.next(true);
              this.authenticationObservableCompleted = true;
              obs.complete();
            })
            .catch((err) => {
              if (this.accessToken && !this.isAuthTokenExpired()) {
                //there is a chance that the token was refreshed in the meantime
                console.log("Token was refreshed in the meantime");
                obs.next(true);
                this.authenticationObservableCompleted = true;
                obs.complete();
              } else {
                obs.next(false);
                this.authenticationObservableCompleted = true;
                obs.complete();
                this.removeToken();
                window.location.reload();
              }
            });
        }
      } else {
        obs.next(false);
        this.authenticationObservableCompleted = true;
        obs.complete();
      }
    });

    return this.authenticationObservable;
  }

  public isAuthTokenExpired() {
    if (!this.accessToken) return true;

    return new JwtHelperService().isTokenExpired(this.accessToken);
  }

  public isRefreshTokenExpired() {
    return new JwtHelperService().isTokenExpired(this.refreshToken);
  }

  public authenticate(obs: Observer<boolean>) {
    if (this._authenticationRunning) {
      obs.next(false);
      obs.complete();
    }
    this._authenticationRunning = true;

    if (Capacitor.getPlatform() === "web") {
    } else {
      OAuth2Client.authenticate(this.oauth2Options)
        .then((response) => {
          if (
            response &&
            response.access_token_response &&
            response.access_token_response.access_token
          ) {
            this.saveToken(
              response.access_token_response.access_token,
              response.access_token_response.refresh_token,
              response.access_token_response.id_token
                ? response.access_token_response.id_token
                : null
            );
            obs.next(true);
            obs.complete();
            this._authenticationRunning = false;
            this._store.dispatch(authLoginSuccess({ token: this.accessToken }));
          } else {
            console.error("OAuth invalid");
            this._authenticationRunning = false;

            obs.next(false);
            obs.complete();
            this._router.navigateByUrl("/login-error?error=INVALID_RESPONSE");
          }
        })
        .catch((reason) => {
          console.error(reason.toString());

          this._authenticationRunning = false;
          obs.next(false);
          obs.complete();

          this._router.navigateByUrl(
            "/login-error?error=" + reason.toString().replace("Error: ", "")
          );
        });
    }
  }

  public saveRefreshTokenResponse(refreshTokenResponse: {
    access_token: string;
    refresh_token: string;
  }) {
    if (refreshTokenResponse.access_token) {
      this.saveToken(
        refreshTokenResponse.access_token,
        refreshTokenResponse.refresh_token
      );
    } else {
      console.error("OAuth Refresh invalid");
    }
  }

  private saveToken(
    access_token: string,
    refresh_token: string | null = null,
    id_token: string | null = null
  ) {
    try {
      localStorage.setItem(
        this._config.storage.localStoragePrefix +
          this._config.storage.accessToken,
        access_token
      );

      if (refresh_token) {
        localStorage.setItem(
          this._config.storage.localStoragePrefix +
            this._config.storage.refreshToken,
          refresh_token
        );
      }

      if (id_token !== null) {
        localStorage.setItem(
          this._config.storage.localStoragePrefix +
            this._config.storage.idToken,
          id_token
        );
      }
    } catch (e) {
      console.error("Couldn't save token");
    }
  }

  public logout(): void {
    if (Capacitor.getPlatform() === "web") {
      this._keycloakService
        .logout(this._config.auth.redirectUri + "/logout")
        .then(() => {
          this.removeToken();
        })
        .catch((err) => {
          this.removeToken();
        });
    } else {
      OAuth2Client.logout(this._oauth2Options)
        .then((res) => {
          this.customLogout();
        })
        .catch((err) => {
          this.customLogout();
        });
    }
  }

  private customLogout() {
    let encodedRedirectUri = encodeURIComponent(
      this._config.auth.redirectUri + "/logout"
    );
    // when ios use redirecturiios
    if (Capacitor.getPlatform() === "ios") {
      encodedRedirectUri = encodeURIComponent(this._config.auth.redirectUriiOS);
    } else if (Capacitor.getPlatform() === "android") {
      encodedRedirectUri = encodeURIComponent(
        this._config.auth.redirectUriAndroid
      );
    }

    const encodedIdToken = this.idToken;

    let logoutUrl =
      this._config.auth.url +
      this._config.auth.logoutEndpoint +
      "?post_logout_redirect_uri=" +
      encodedRedirectUri +
      "&client_id=" +
      this._config.auth.clientId +
      "&id_token_hint=" +
      encodedIdToken;

    this.removeToken();

    Browser.open({ url: logoutUrl }).then((res) => {});
  }

  public removeToken() {
    localStorage.removeItem(
      this._config.storage.localStoragePrefix + this._config.storage.accessToken
    );

    localStorage.removeItem(
      this._config.storage.localStoragePrefix +
        this._config.storage.refreshToken
    );
    localStorage.removeItem(
      this._config.storage.localStoragePrefix + this._config.storage.idToken
    );
  }
}
