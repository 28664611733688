import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

let animationTime = "500ms";

export const showAlert = trigger("reveal", [
  state(
    "void",
    style({ bottom: "-3em", opacity: 0, transform: "rotateX(-35deg)" })
  ),
  state(
    "visible",
    style({
      bottom: "0em",
      opacity: 1,
      transform: "rotateX(0deg)",
    })
  ),
  transition("void => *", animate(animationTime + " ease-out")),
  transition("* => void", animate(animationTime + " ease-out")),
]);
