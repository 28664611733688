<div class="modal__background" @enterLeaveFade (click)="Close()"></div>
<div class="modal {{type}}" @enterLeave *ngIf="open" [ngSwitch]="type">

    <!--    Approve modal -->
    <ng-container *ngSwitchCase="ModalType.Approve">
        <div class="modal__header modal__header--col" *ngIf="(moderationStatus$ | async) === ModerationStatus.SUCCESS">
            <div class="spacer-3"></div>
            <app-svg-icon
                    class="icon"
                    [src]="'icons/124/circle-check.svg'"
                    [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
            ></app-svg-icon>
            <p class="header-title center">{{ 'MODAL.APPROVAL_PROCESS.INFO_APPROVE.TITLE' | translate }}</p>
            <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
        </div>
        <div class="modal__header modal__header--col"
             *ngIf="(moderationStatus$ | async) === ModerationStatus.PENDING || !(moderationStatus$ |async)">
            <div class="spacer-3"></div>
            <app-loading></app-loading>
            <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
        </div>

        <div class="modal__header modal__header--col" *ngIf="(moderationStatus$ | async) === ModerationStatus.ERROR ">
            <div class="spacer-3"></div>
            <p>{{'MODAL.APPROVAL_PROCESS.INFO_APPROVE.ERROR.TITLE' | translate}}</p>
            <p>{{'MODAL.APPROVAL_PROCESS.INFO_APPROVE.ERROR.TEXT' | translate}}</p>
            <div class="spacer-3"></div>
            <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
        </div>

        <div class="spacer-and-half"></div>
        <ng-container [ngSwitch]="toApproveNumber">
            <p *ngSwitchCase="0"></p>
            <p class="center" *ngSwitchCase="1">{{'MODAL.APPROVAL_PROCESS.INFO_APPROVE.TEXT_ONE' | translate}}</p>
            <p class="center"
               *ngSwitchDefault>{{'MODAL.APPROVAL_PROCESS.INFO_APPROVE.TEXT_MORE' | translate: {value: toApproveNumber} }}</p>
        </ng-container>
        <div class="spacer-3"></div>
        <app-button *ngIf="nextPostPath" (action)="SetEvent($event, DashboardRoutesType.NewsDetail, nextPostPath)"
                    [buttonType]="ButtonType.Primary"
                    [label]="'MODAL.APPROVAL_PROCESS.INFO_APPROVE.BUTTON_NEXT' | translate" [fullWidth]="true"
                    [extraHeight]="true"></app-button>
        <div class="spacer-2" *ngIf="nextPostPath"></div>
        <app-button (action)="SetEvent($event, DashboardRoutesType.NewsOverview)" [buttonType]="ButtonType.Secondary"
                    [label]="'MODAL.APPROVAL_PROCESS.INFO_APPROVE.BUTTON_OVERVIEW' | translate" [fullWidth]="true"
                    [extraHeight]="true"></app-button>
    </ng-container>

    <!--    Decline modal  -->
    <ng-container *ngSwitchCase="ModalType.Decline">

        <ng-container *ngIf="(moderationStatus$ | async) === ModerationStatus.PENDING || (moderationStatus$ | async) === ModerationStatus.ERROR
|| (moderationStatus$ | async) === ModerationStatus.SUCCESS else noModerationState ">


            <ng-container *ngIf="(moderationStatus$ | async) === ModerationStatus.SUCCESS ">
                <div class="modal__header">
                    <p class="header-title">{{ 'MODAL.APPROVAL_PROCESS.INFO_DECLINE.SUCCESS.TITLE' | translate }}</p>
                    <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
                </div>
                <div class="spacer-and-half"></div>
                <p class="center">{{'MODAL.APPROVAL_PROCESS.INFO_DECLINE.SUCCESS.TEXT' | translate}}</p>
                <div class="spacer-3"></div>
                <app-button (action)="SetEvent($event, DashboardRoutesType.NewsOverview)"
                            [buttonType]="ButtonType.Secondary"
                            [label]="'MODAL.APPROVAL_PROCESS.INFO_APPROVE.BUTTON_OVERVIEW' | translate"
                            [fullWidth]="true" [extraHeight]="true"></app-button>
                <div class="spacer-3"></div>
            </ng-container>


            <ng-container *ngIf="(moderationStatus$ | async) === ModerationStatus.ERROR ">
                <div class="modal__header">
                    <p class="header-title">{{ 'MODAL.APPROVAL_PROCESS.INFO_DECLINE.ERROR.TITLE' | translate }}</p>
                    <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
                </div>
                <div class="spacer-and-half"></div>
                <p class="center">{{'MODAL.APPROVAL_PROCESS.INFO_DECLINE.ERROR.TEXT' | translate}}</p>
            </ng-container>


            <ng-container *ngIf="(moderationStatus$ | async) === ModerationStatus.PENDING ">
                <div class="modal__header">
                    <p class="header-title">{{ 'MODAL.APPROVAL_PROCESS.INFO_DECLINE.PENDING.TITLE' | translate }}</p>
                    <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
                </div>
                <div class="spacer-and-half"></div>
                <p class="center">{{'MODAL.APPROVAL_PROCESS.INFO_DECLINE.PENDING.TEXT' | translate}}</p>
            </ng-container>

        </ng-container>


        <ng-template #noModerationState>

                <div class="modal__header">
                    <p class="header-title">{{ 'MODAL.APPROVAL_PROCESS.INFO_DECLINE.TITLE' | translate }}</p>
                    <app-icon-button (action)="Close()" class="icon-button" src="icons/32/close.svg"></app-icon-button>
                </div>
                <div class="spacer-and-half"></div>
                <p class="center">{{'MODAL.APPROVAL_PROCESS.INFO_DECLINE.TEXT' | translate}}</p>
                <div class="spacer-2"></div>
            <ng-container *ngIf="preDecline">
                <app-input-text-area (currentValue)="declineReason = $event"
                                     [label]="'MODAL.APPROVAL_PROCESS.INFO_DECLINE.REASON' | translate"></app-input-text-area>
                <div class="spacer-3"></div>
                <app-button [fullWidth]="true" (action)="Decline($event)" [disabled]="declineReason.length === 0"
                            [label]="'MODAL.APPROVAL_PROCESS.INFO_DECLINE.BUTTON' | translate"
                            [extraHeight]="true"></app-button>
            </ng-container>
            <ng-container *ngIf="!preDecline">
                <app-loading></app-loading>
            </ng-container>

        </ng-template>

    </ng-container>
</div>
