import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../config/config.service";
import { ApiConnectorService } from "../../io/api-connector.service";

@Injectable({
  providedIn: "root",
})
export class ApiEmailConnectorService extends ApiConnectorService {
  constructor(
    private http: HttpClient,
    protected override _configService: ConfigService
  ) {
    super(_configService);
  }

  setEmailNotifications(emailNotifications: any) {
    return this.http.patch(
      this._configService.config.cms.url +
        "/api/self-service/email-recipient?_format=json",

      emailNotifications
    );
  }

  updateEmailRecipient(data: any) {
    return this.http.patch(
      this._configService.config.cms.url +
        "/api/self-service/email-recipient/update",
      data
    );
  }

  updateStatus(data: any) {
    return this.http.patch(
      this._configService.config.cms.url +
        "/api/self-service/email-recipient/update-status",
      data
    );
  }
}
