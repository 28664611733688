<input
    class="input-search-text"
    #inputValue
    [ngClass]="{
        'has-error':
          (ngModel.touched && ngModel.invalid),
          'flat-bottom': bottomEffectOnInput && currentVal.length
      }"
    autocapitalize="none"
    type="text"
    id="{{ id }}"
    name="{{ name }}"
    placeholder="{{ placeholder }}"
    [attr.disabled]="disabled ? true : null"
    required="false"
    [(ngModel)]="currentVal"
    (ngModelChange)="searchNotifier.next(true)"
    #ngModel="ngModel"
/>
<span class="input-icon">
  <app-svg-icon
      class="icon"
      src="icons/24/{{icon}}.svg"
      [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
  ></app-svg-icon>
</span>
<span
    class="input-clear"
    *ngIf="closeInputOption || currentVal !== ''"
    (click)="clearAndEmit()"
>
  <app-svg-icon
      class="icon"
      src="icons/24/close.svg"
      [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
  ></app-svg-icon>
</span>
