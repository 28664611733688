<div class="advice-card__header">
    <div class="advice-card__caption">
        <div class="circle {{guidelineType}}"></div>
        <div class="caption">{{ ("STATES.ADVICE_CARD." + guidelineType) | translate }}</div>
    </div>
    <div *ngIf="new" class="caption-flag">neu</div>
</div>
<div class="spacer-half"></div>
<div class="advice-card__content">
    <div class="card-title">{{title}}</div>
    <app-svg-icon
        class="icon"
        [src]="'icons/arrow_right.svg'"
        [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
    ></app-svg-icon>
</div>
