<div class="container ">
    <div class="info content">
    <div class="row">
        <div class="col-xsmall-12">
            <div class="info__action-wrapper">
                <app-icon-button (action)="SetEvents($event)" src="icons/32/close.svg"></app-icon-button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xsmall-12">
           <ng-container [ngSwitch]="infoType">
               <div *ngSwitchCase="'entity_approval_expired'">
                   <h3>{{'MODAL.APPROVAL_PROCESS.WITHDRAWN.TITLE' | translate}}</h3>
                   <p>{{'MODAL.APPROVAL_PROCESS.WITHDRAWN.MESSAGE' | translate}}</p>
               </div>
           </ng-container>
        </div>
    </div>
    </div>

</div>

