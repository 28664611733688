<label class="input-checkbox-label checkbox">
  <input
    type="checkbox"
    class="checkbox__input"
    [checked]="checked"
    (change)="valueChanged($event)"
  />
  <div class="checkbox__mark"></div>
</label>

