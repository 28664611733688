import { animate, style, transition, trigger } from "@angular/animations";

export const baseModalAnimationsAppear = trigger("appear", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("250ms 0ms cubic-bezier(.4, 0, .2, 1)", style({ opacity: 1 })),
  ]),
  transition(":leave", [
    animate("250ms 100ms cubic-bezier(.4, 0, .2, 1)", style({ opacity: 0 })),
  ]),
]);

export const baseModalAnimationsEnterLeave = trigger("enterLeave", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateX(100%)" }),
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 1, transform: "translateX(0%)" })
    ),
  ]),
  transition(":leave", [
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 0, transform: "translateX(100%)" })
    ),
  ]),
]);

export const baseModalAnimationsEnterLeaveBottom = trigger("enterLeave", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(100%)" }),
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 1, transform: "translateY(0%)" })
    ),
  ]),
  transition(":leave", [
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 0, transform: "translateY(100%)" })
    ),
  ]),
]);
