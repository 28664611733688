import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MessageType} from "./message.type";

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  /**
   * Text that gets displayed in the message box
   */
  @Input() message:string = "";

  /**
   * Depending on Message Type suitable icon gets displayed
   */
  @Input() messageType:MessageType = MessageType.Error;

  @HostBinding('class') get hostClasses() {
    const classList = ['box'];
    return classList;
  }

  @HostBinding('attr.tabindex')  tabindex = '0';


  constructor() { }

  ngOnInit(): void {
  }

}
