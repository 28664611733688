import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../../button/button-output";
import { ButtonActionsType } from "../../button/button-actions.type";
import { dateTimeStandard } from "../../../config/units";
import { Capacitor } from "@capacitor/core";
import { Event } from "../../../store/user/user";

@Component({
  selector: "app-info-card",
  templateUrl: "./info-card.component.html",
  styleUrls: ["./info-card.component.scss"],
})
export class InfoCardComponent implements OnInit {
  /**
   * Title of the info card
   */
  @Input() title: string | undefined = "";

  /**
   * Title of the info card
   */
  @Input() infoDate: string | undefined = "";

  /**
   * New state of info card
   */
  @Input() new: boolean = false;

  /**
   * Declined state of info card
   */
  @Input() declined: boolean = false;

  /**
   * Array of federal state ids
   */
  @Input() federalStates: Array<string> = [];

  private _recentlyAdded = true;

  @HostBinding("class") get hostClasses() {
    const classList = ["box", this._recentlyAdded ? "highlight" : ""];
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  public dateTimeStandard = dateTimeStandard;

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  constructor(private el: ElementRef) {
    setTimeout(() => {
      this._recentlyAdded = false;
    }, 1000);

    el.nativeElement.addEventListener("click", () => {
      this.action.emit({
        type: ButtonActionsType.Click,
      });
    });
  }

  ngOnInit(): void {}
}
