import {Injectable} from "@angular/core";
import {ApiConnectorService} from "../api-connector.service";
import {ConfigService} from "../../config/config.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiEntityResponse} from "./types/api-entity-response";

@Injectable({
    providedIn: "root",
})
export class ApiConnectorEntityService extends ApiConnectorService {
    constructor(public configService: ConfigService, public http: HttpClient) {
        super(configService);
    }

    public loadEntities(payload: any): Observable<ApiEntityResponse> {
        return this.http.get(
            this._configService.config.cms.url + "/jsonapi/" + payload.url
        ) as Observable<ApiEntityResponse>;
    }

    public updateEntityReadStatus(payload: any): Observable<any> {
        return this.http.patch(
            this._configService.config.cms.url + "/api/entity/read-tracker/node/" + payload,
            {}
        ) as Observable<any>;
    }

    public approveEntity(payload: { actionURI: string; id: string; type: string; reason?: string }): Observable<any> {
        return this.http.post(
            this.configService.config.cms.url + payload.actionURI,
            {reason: payload.reason ? payload.reason : null}
        ) as Observable<ApiEntityResponse>;
    }

    public declineEntity(payload: { actionURI: string; reason: string }): Observable<any> {
        return this.http.post(
            this.configService.config.cms.url + payload.actionURI,
            {reason: payload.reason}
        ) as Observable<any>;
    }

}
