import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { NavigationService } from "../../services/navigation.service";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";

enum InfoTypes {
  "APPROVAL_EXPIRED" = "entity_approval_expired",
  "ENITITY_APPROVED" = "entity_approved",
}

@Component({
  selector: "app-info-page",
  templateUrl: "./info-page.component.html",
  styleUrls: [
    "./info-page.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
})
export class InfoPageComponent
  extends AnimationBaseComponent
  implements OnDestroy
{
  public infoType: string | null;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    protected override _navigationService: NavigationService
  ) {
    super(_navigationService);

    this.infoType = this._route.snapshot.queryParamMap.get("info");
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  public SetEvents(event: any) {
    switch (event.type) {
      case ButtonActionsType.Click:
        this._navigationService.navigateByUrl(
          DashboardRoutesType.Dashboard,
          BasePageAnimationDirection.FADE
        );
        break;
    }
  }
}
