import { ApiConnectorService } from "../../io/api-connector.service";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { UserPreference } from "../user/user";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorDashboardService extends ApiConnectorService {
  public headerData = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Content-Type",
    Accept: "application/json",
  };

  constructor(
    private http: HttpClient,
    protected override _configService: ConfigService
  ) {
    super(_configService);
  }

  public setUserPreferences(settings: UserPreference) {
    return this.http.patch(this._configService.config.cms.url + "/api/user-preferences", {
      [settings.id]: settings.value
    })
  }

  public loadOnboardingScreens() {
    return this.http.get(this._configService.config.cms.url + "/api/onboarding-screens");
  }
}
