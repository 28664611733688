<app-icon-button *ngIf="buttonImage === BackHeaderImageType.Default" (action)="SetAction($event)" [src]="'icons/32/arrow-left.svg'"></app-icon-button>
<app-image-button *ngIf="buttonImage === BackHeaderImageType.Logo" (action)="SetAction($event)" [style]="{width: '70px'}" src="assets/img/logo.svg" alt="Zurück zum Dashboard"></app-image-button>
<span *ngIf="buttonImage === BackHeaderImageType.None"></span>
<div  class="{{ detailView ? 'label' : 'header-title'}}" (click)="SetAction({type: ButtonActions.Click})">{{ title }}</div>
<app-svg-icon
        *ngIf="settingsOption"
        class="settings"
        [src]="'icons/24/settings.svg'"
        [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
        (click)="goToSettings()"
></app-svg-icon>
<app-svg-icon
        *ngIf="filterOption !== FilterOption.hidden"
        class="filter {{filterOption}}"
        [src]="'icons/24/filter.svg'"
        [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
        (click)="SetAction({type: ButtonActions.Filter})"
></app-svg-icon>
