import { Component, OnDestroy, OnInit } from "@angular/core";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "../../io/entity/view.service";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { ViewHelper } from "../../io/entity/view-helper/view-helper";
import { Observable, Subscription, take } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { loadEntityError } from "../../io/entity/store/entity.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertService } from "../../alerts/alert.service";
import { buildArgumentForViewService } from "../info-overview/info-overview-helper";
import { ModerationSate } from "../../store/news-post/news-post";
import { base64UrlEncode } from "../../helper/helper-functions";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-generic-detail",
  templateUrl: "./generic-detail.component.html",
  styleUrls: ["./generic-detail.component.scss"],
})
export class GenericDetailComponent
  extends AnimationBaseComponent
  implements OnInit, OnDestroy
{
  public id: string;

  //View to determine next and previous elements
  public viewHelper: ViewHelper | undefined;
  //indicate if the viewHelper was set, because the user was on a view before.
  //try not to override the viewHelper if it was set from the view
  protected _viewHelperIsSetFromView = false;

  protected _subscriptions: Array<Subscription> = [];
  private _errorSubscription: Subscription;

  public viewHelperPrevNext: {
    prevId: string | undefined;
    nextId: string | undefined;
    prev: boolean;
    next: boolean;
  } = {
    prevId: undefined,
    nextId: undefined,
    prev: false,
    next: false,
  };

  public leftSwipePosition = 0;

  constructor(
    protected override _navigationService: NavigationService,
    protected _activatedRoute: ActivatedRoute,
    protected _viewService: ViewService,
    protected _viewHelperService: ViewHelperService,
    protected _actions: Actions,
    protected _alertService: AlertService,
    protected _store: Store
  ) {
    super(_navigationService);

    this.id = this._activatedRoute.snapshot.params["id"];

    //check if there is an active view helper (e.g. if the user came from the overview (and not from push notifications)
    this.viewHelper = this._viewService.activeViewHelper;
    if (this.viewHelper) this._viewHelperIsSetFromView = true;

    //in case the user hasn't seen a view yet, we need to create a new one (default info-overview)
    if (this.viewHelper) {
      this.getViewHelperPrevNext();
    }

    this._errorSubscription = this._actions
      .pipe(ofType(loadEntityError.type))
      .subscribe(
        (error: {
          error: HttpErrorResponse;
          data: any;
          type: any;
          suppress?: boolean;
        }) => {
          if (error.suppress) return;

          switch (error.error.status) {
            case 404:
              this._alertService.DispatchAlert(
                "ERRORS.404.TITLE",
                "ERRORS.404.TEXT",
                -1
              );
              this._errorSubscription.unsubscribe();
              break;
            case 403:
              this._alertService.DispatchAlert(
                "ERRORS.403.TITLE",
                "ERRORS.403.TEXT",
                -1
              );
              this._errorSubscription.unsubscribe();
              break;
            default:
              console.error(error.error);
          }
        }
      );
  }

  protected getViewHelperPrevNext() {
    new Observable((obs) => {
      if (this.viewHelper) {
        this.viewHelperPrevNext = {
          prevId: this.viewHelper.previousEntity(this.id),
          nextId: this.viewHelper.nextEntity(this.id, obs),
          prev: typeof this.viewHelper.previousEntity(this.id) == "string",
          next: typeof this.viewHelper.nextEntity(this.id) == "string",
        };
      }
    })
      .pipe(take(1))
      .subscribe((res) => {
        this.getViewHelperPrevNext();
      });
  }

  protected createViewHelper(
    viewId: string,
    viewDisplay: string,
    viewHelperId: string
  ) {
    const user = buildArgumentForViewService(ModerationSate.PUBLISHED, -1);

    const filter: any[] = [];

    this.viewHelper = this._viewHelperService.getViewHelper(
      viewHelperId +
        base64UrlEncode(JSON.stringify(filter) + JSON.stringify(user)),
      false
    );

    this.viewHelper.setView(viewId, viewDisplay);
    this.viewHelper.setFilter(filter);
    this.viewHelper.setUser(user);
    this.viewHelper.setInclude([]);

    new Observable((obs) => {
      if (this.viewHelper) {
        this.viewHelper.setActive(false, obs);
      } else {
        obs.complete();
      }
    })
      .pipe(take(2))
      .subscribe((res) => {
        this.getViewHelperPrevNext();
      });
  }

  ngOnInit() {}

  override ngOnDestroy() {
    try {
      this._subscriptions.forEach((s) => s.unsubscribe());
    } catch (e) {}

    try {
      this._errorSubscription.unsubscribe();
    } catch (e) {}

    super.ngOnDestroy();
  }
}
