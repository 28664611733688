import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { dateTimeStandard } from "../../../config/units";
import { ButtonOutput } from "../../button/button-output";
import { ButtonActionsType } from "../../button/button-actions.type";
import { Store } from "@ngrx/store";
import { AppStore } from "../../../store/appStore";
import { selectEntity } from "../../../io/entity/store/entity.selector";
import { Observable } from "rxjs";
import { Entity } from "../../../io/entity/types/entity";

@Component({
  selector: "app-medifinder-search-card",
  templateUrl: "./medifinder-search-card.component.html",
  styleUrls: ["./medifinder-search-card.component.scss"],
})
export class MedifinderSearchCardComponent implements OnInit {
  /**
   * Title of the info card
   */
  @Input() title: string | undefined = "";

  /**
   * Title of the info card
   */
  @Input() infoDate: string | undefined | number = "";

  @Input() pharmacyId: string = "";

  public pharmacy$: Observable<Entity | undefined>;

  public dateTimeStandard = dateTimeStandard;

  @Input() own: boolean = false;

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  constructor(private el: ElementRef, private _store: Store<AppStore>) {
    el.nativeElement.addEventListener("click", () => {
      this.action.emit({
        type: ButtonActionsType.Click,
      });
    });
  }

  ngOnInit() {
    this.pharmacy$ = this._store.select(selectEntity(this.pharmacyId));
  }
}
