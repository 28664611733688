import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-notification-banner",
  templateUrl: "./notification-banner.component.html",
  styleUrls: ["./notification-banner.component.scss"],
})
export class NotificationBannerComponent {
  @Input() count: Number = 1;

  @HostBinding("class") get hostClasses() {
    const classList = ["notification-banner"];
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
