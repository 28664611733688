import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { EntityService } from "../../io/entity/entity.service";

import { ButtonType } from "../../ui/button/button.type";
import { ModalType } from "../../ui/modal/base-modal/modal.type";
import {
  EntityTypesId,
  getEntityTypeById,
  ViewType,
} from "../../io/entity/types/entityTypes";
import { selectNewsPostById } from "../../store/news-post/news-post.selector";
import { Observable, Subscription, take } from "rxjs";
import { ModerationSate, NewsPost } from "../../store/news-post/news-post";
import { dateTimeStandard } from "../../config/units";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  loadEntityError,
  updateEntityReadStatus,
} from "../../io/entity/store/entity.actions";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { AliasService } from "../../io/entity/alias.service";
import { Actions, ofType } from "@ngrx/effects";
import { HttpErrorResponse } from "@angular/common/http";
import { fetchApprovalMetaData } from "../../store/approval/approval.actions";
import { filterModalAnimationsEnterLeaveChild } from "../../ui/modal/filter-modal/filter-modal.animations";
import { AlertService } from "../../alerts/alert.service";
import { GetApprovalPosts } from "../../store/approval/approval.selector";
import { CacheRefresh } from "../../io/entity/types/cache";
import { ViewService } from "../../io/entity/view.service";
import { ViewHelper } from "../../io/entity/view-helper/view-helper";
import {
  buildArgumentForViewService,
  buildFilterArrayForViewService,
  getFederalStatesFilter,
} from "../info-overview/info-overview-helper";
import { base64UrlEncode } from "../../helper/helper-functions";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { DeviceService } from "../../io/device/device.service";
import { TranslateService } from "@ngx-translate/core";
import { GenericDetailComponent } from "../generic-detail/generic-detail.component";

enum RejectMessages {
  "NONE",
  "OWN",
  "RESUBMITTED",
}

@Component({
  selector: "app-info-detail",
  templateUrl: "./info-detail.component.html",
  styleUrls: [
    "../../animations/animation-base/animation-base-page.scss",
    "./info-detail.component.scss",
  ],
  animations: [filterModalAnimationsEnterLeaveChild],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InfoDetailComponent
  extends GenericDetailComponent
  implements OnInit, OnDestroy, OnChanges
{
  public content$;

  public dateTimeStandard = dateTimeStandard;
  public ButtonType = ButtonType;
  public ModalType = ModalType;
  public ModerationState = ModerationSate;
  public moderationState: any;

  public rejectMessageStates = RejectMessages;
  public rejectMessageState = RejectMessages.NONE;
  public rejector: string | undefined = "";
  public rejectedReason: string | undefined = "";

  public moderationStates = ModerationSate;

  public approvalModalIsOpen: boolean = false;
  public currentModalType = ModalType.Decline;
  public approvalNecessary = false;

  public approvalWithdrawnModalIsOpen: boolean = true;
  public approvalWithdrawn = false;
  private _approvalSubscription: Subscription;

  private _approvalModalLocked = false;

  private _appActiveSubscription: Subscription;

  private _checkApprovalLoop: any;

  @HostBinding("class") get hostClasses() {
    // for page animation to work, we need special styles for the host
    // -> but because of the encapsulation view none, the styles are missing,
    // therefore we need to add the class manually
    const classList = ["host"];
    return classList;
  }

  constructor(
    private _entityService: EntityService,
    private _aliasService: AliasService,
    protected override _activatedRoute: ActivatedRoute,
    protected override _store: Store,
    protected override _navigationService: NavigationService,
    protected override _actions: Actions,
    protected override _alertService: AlertService,
    protected override _viewService: ViewService,
    protected override _viewHelperService: ViewHelperService,
    private _deviceService: DeviceService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {
    super(
      _navigationService,
      _activatedRoute,
      _viewService,
      _viewHelperService,
      _actions,
      _alertService,
      _store
    );

    this._appActiveSubscription = this._deviceService.appBecameActive.subscribe(
      () => {
        this.checkApproval();
      }
    );

    this.content$ = this._entityService.getEntity(
      EntityTypesId.NewsPost,
      this.id,
      selectNewsPostById,
      CacheRefresh.one_minute
    ) as Observable<NewsPost>;

    //Read Status
    this._subscriptions.push(
      this.content$
        .pipe(map((c) => c?.attributes?.is_read))
        .subscribe((isRead) => {
          // using 'isRead === false' instead of '!isRead' to be sure that entity is already loaded
          if (isRead === false) {
            if (this._entityService.isUUID(this.id)) {
              this._store.dispatch(updateEntityReadStatus({ id: this.id }));
            } else {
              // if id is alias and not uuid --> get uuid
              const _uuid = this._aliasService.retrieveResourceFromAlias(
                "/" +
                  getEntityTypeById(EntityTypesId.NewsPost)?.path +
                  "/" +
                  this.id
              );
              this._store.dispatch(updateEntityReadStatus({ id: _uuid || "" }));
            }
          }
        })
    );

    //content update
    this._subscriptions.push(
      this.content$.pipe(map((c) => c)).subscribe((content) => {
        if (!content) return;

        const moderationState = content.attributes.moderation_state;

        if (
          !this.moderationState &&
          moderationState &&
          moderationState !== ModerationSate.PUBLISHED
        ) {
          this._entityService
            .getEntity(
              EntityTypesId.NewsPost,
              this.id,
              selectNewsPostById,
              CacheRefresh.always
            )
            .pipe(take(1))
            .subscribe((res) => {
              this._changeDetectorRef.markForCheck();
            });
        }

        this.moderationState = moderationState;

        this.checkRejectMessageState(content);

        if (this.moderationState) {
          this.createViewHelper();
        }

        this._changeDetectorRef.markForCheck();
      })
    );
  }

  override createViewHelper() {
    //if the viewHelper was set from the view, don't override it
    if (this._viewHelperIsSetFromView) return;

    getFederalStatesFilter(this._store).subscribe((federalStates) => {
      let moderationState = this.moderationState;

      switch (moderationState) {
        case ModerationSate.REJECTED:
          moderationState = ModerationSate.OWN_REJECTED;
      }

      const filter = buildFilterArrayForViewService(
        federalStates,
        {
          id: ViewType.SearchText,
          value: "",
        },
        moderationState ? moderationState : ModerationSate.PUBLISHED
      );

      const user = buildArgumentForViewService(ModerationSate.PUBLISHED, -1);

      this.viewHelper = this._viewHelperService.getViewHelper(
        "info-overview-" +
          base64UrlEncode(JSON.stringify(filter) + JSON.stringify(user)),
        false
      );

      this.viewHelper.setView("global_search", "query");
      this.viewHelper.setFilter(filter);
      this.viewHelper.setUser(user);
      this.viewHelper.setInclude([
        "field_federal_state",
        "field_last_rejected_user",
      ]);

      new Observable((obs) => {
        if (this.viewHelper) {
          this.viewHelper.setActive(false, obs);
        } else {
          obs.complete();
        }
      })
        .pipe(take(2))
        .subscribe((res) => {
          this.getViewHelperPrevNext();
        });
    });
  }

  public checkApproval() {
    this.id = this._activatedRoute.snapshot.params["id"];

    try {
      if (this._approvalSubscription) {
        this._approvalSubscription.unsubscribe();
      }
    } catch (e) {}

    //**
    //** check if info needs to get approved
    this._approvalSubscription = this._store
      .select(GetApprovalPosts)
      .subscribe((approvalPosts) => {
        if (this._approvalModalLocked) return;

        if (
          approvalPosts.map((el) => el.entity.entity_uuid).includes(this.id)
        ) {
          this.approvalNecessary = true;
        } else {
          if (this.approvalNecessary) {
            this._alertService.DispatchInfo(
              this._translateService.instant(
                "MODAL.APPROVAL_PROCESS.WITHDRAWN.TITLE"
              ),
              this._translateService.instant(
                "MODAL.APPROVAL_PROCESS.WITHDRAWN.MESSAGE"
              )
            );
          }
          this.approvalNecessary = false;
        }

        this._changeDetectorRef.markForCheck();

        if (!this._checkApprovalLoop && this.approvalNecessary) {
          if (this._checkApprovalLoop) clearInterval(this._checkApprovalLoop);

          this._checkApprovalLoop = setInterval(() => {
            this.checkApproval();
          }, 5000);
        }
      });

    // load approval meta data
    this._store.dispatch(fetchApprovalMetaData());
  }

  public setContent() {}

  override ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges() {}

  public override ngOnDestroy() {
    try {
      this._approvalSubscription.unsubscribe();
    } catch (e) {}

    try {
      this._appActiveSubscription.unsubscribe();
    } catch (e) {}

    try {
      if (this._checkApprovalLoop) {
        clearInterval(this._checkApprovalLoop);
      }
    } catch (e) {
      console.error(e);
    }

    super.ngOnDestroy();
  }

  public SetAction(event: any) {
    let tab;

    switch (this.moderationState) {
      case ModerationSate.WAITING_FOR_APPROVAL:
        tab = this.moderationState;
        break;
    }

    switch (event.type) {
      case ButtonActionsType.Back:
        this._navigationService.navigateByUrl(
          DashboardRoutesType.NewsOverview,
          BasePageAnimationDirection.RIGHT,
          tab ? { tab: tab } : undefined,
          this.id
        );
    }
  }

  public swipeBack(prevId: string | undefined) {
    if (typeof prevId === "string") {
      console.log("b");
      this._navigationService.navigateByUrl(
        "/" + DashboardRoutesType.NewsDetail + prevId,
        BasePageAnimationDirection.RIGHT
      );
    }
  }

  public swipeNext(nextId: string | undefined) {
    if (typeof nextId === "string") {
      this._navigationService.navigateByUrl(
        "/" + DashboardRoutesType.NewsDetail + nextId,
        BasePageAnimationDirection.LEFT
      );
    }
  }

  public OpenApprovalModal(type: ModalType) {
    this.currentModalType = type;
    this.approvalModalIsOpen = true;
  }

  protected readonly ButtonActionsType = ButtonActionsType;

  private checkRejectMessageState(content: NewsPost) {
    try {
      if (this.moderationState === ModerationSate.REJECTED) {
        this.rejectMessageState = RejectMessages.OWN;
        this.rejector = this.getRejectedUser(content);
        this.rejectedReason = content.attributes.field_rejected_reason;
      } else if (
        this.moderationState !== ModerationSate.PUBLISHED &&
        this.moderationState !== ModerationSate.REJECTED &&
        content.attributes.field_rejected_reason
      ) {
        this.rejectMessageState = RejectMessages.RESUBMITTED;
        this.rejector = this.getRejectedUser(content);
        this.rejectedReason = content.attributes.field_rejected_reason;
      } else {
        this.rejectMessageState = RejectMessages.NONE;
      }
    } catch (e) {
      this.rejectMessageState = RejectMessages.NONE;
      this.rejectedReason = "";
      this.rejector = undefined;
    }
  }

  private getRejectedUser(content: NewsPost) {
    try {
      return content.relationships.field_last_rejected_user.data.attributes.display_name
        .replace(/@/gm, "@&shy;")
        .replace(/\./gm, ". &shy;");
    } catch (e) {
      return undefined;
    }
  }

  public onApprovalOrRejection(approval: boolean) {
    this._approvalModalLocked = true;

    try {
      if (this._checkApprovalLoop) {
        clearInterval(this._checkApprovalLoop);
      }
    } catch (e) {}
  }
}
