<ng-container *ngIf="event?.field_icon else defaultIcon">
    <img [src]="publicFilesURL + event.field_icon" width="40">
</ng-container>

<ng-template #defaultIcon>
    <app-svg-icon
            class="icon"
            [src]="'icons/40/' + type + '.svg'"
            [svgStyle]="{ 'width.px': '40', 'height.px': '40' }"
    ></app-svg-icon>
</ng-template>

<div class="dashboard-card__text">
    <div class="card-title">{{ title }}</div>
    <ng-container
        *ngIf="type === DashboardCardType.Event"
        [ngTemplateOutlet]="eventCard"
    ></ng-container>
    <ng-container
            *ngIf="type === DashboardCardType.MediFinder"
            [ngTemplateOutlet]="mediFinderCard"
    ></ng-container>
</div>

<ng-template #defaultCard>
    <div class="card-title">{{ title }}</div>
</ng-template>

<ng-template #eventCard>
    <div class="caption" *ngIf="event.field_start_date && event.field_end_date">Programm {{ event.field_location }} {{ event.field_start_date | date:dateTimeEvent }} bis {{ event.field_end_date | date:dateTimeEvent }}</div>
    <div class="caption freetext" *ngIf="event.field_freetext" [innerHTML]="event.field_freetext"></div>
</ng-template>

<ng-template #mediFinderCard>
    <div class="caption freetext" [innerHTML]="text"></div>
</ng-template>
