<app-back-header title="{{ 'PAGES.EMAIL_DATA_CONFIRM.HEADER' | translate }}"
                 [buttonImage]="BackHeaderImageType.None"></app-back-header>
<div class="container">


    <!-- PROGRESS -->
    <ng-container *ngIf="state === EmailDataStates.CONFIRMATION">
        <div class="spacer spacer-8"></div>
        <app-loading></app-loading>
        <app-button [label]="'PAGES.EMAIL_DATA_CONFIRM.CANCEL' | translate" [buttonType]="ButtonType.Secondary" (action)="dashboard()"
                    [fullWidth]="true">
        </app-button>
    </ng-container>


    <!-- ERROR -->
    <ng-container *ngIf="state === EmailDataStates.ERROR">
        <div class="spacer spacer-8"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/124/circle-error.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
        <div class="spacer spacer-2"></div>
        <p class="center" [innerHTML]="'PAGES.EMAIL_DATA_CONFIRM.ERROR' | translate : {error: errorMessage}"></p>
        <div class="spacer spacer-2"></div>
        <app-button [label]="'PAGES.EMAIL_DATA_CONFIRM.BACK_TO_DASHBOARD' | translate" (action)="dashboard()"
                    [fullWidth]="true">

        </app-button>
    </ng-container>

    <!-- SUCCESS -->
    <ng-container *ngIf="state === EmailDataStates.CONFIRMATION_SUCCESS">
        <div class="spacer spacer-8"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/124/circle-check.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
        <div class="spacer spacer-2"></div>
        <p class="center">{{'PAGES.EMAIL_DATA_CONFIRM.CONFIRMATION_SUCCESS' | translate}}</p>
        <div class="spacer spacer-2"></div>
        <app-button [label]="'PAGES.EMAIL_DATA_CONFIRM.BACK_TO_DASHBOARD' | translate" (action)="dashboard()"
                    [fullWidth]="true">

        </app-button>
    </ng-container>
</div>
