import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from "@ngrx/entity";
import { Entity } from "../types/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as EntityActions from "./entity.actions";
import { EntityTypesId } from "../types/entityTypes";
import cloneDeep from "lodash-es/cloneDeep";
import { ModerationSate } from "../../../store/news-post/news-post";
import { approveOrRejectChamberInfoSuccess } from "../../../store/approval/approval.actions";

export interface State extends EntityState<Entity> {}

export const adapter: EntityAdapter<Entity> = createEntityAdapter<Entity>();

export const initialState: State = adapter.getInitialState({});

const entityReducer = createReducer(
  initialState,
  on(EntityActions.addEntity, (state, { entity, included }) => {
    return adapter.upsertOne(processEntityByType(entity, included), state);
  }),
  on(EntityActions.addEntities, (state, { entities, included }) => {
    return adapter.upsertMany(
      entities.map((entity: Entity) => {
        return processEntityByType(entity, included);
      }),
      state
    );
  }),
  on(EntityActions.loadEntityError, (state, { error, data }) => {
    return state;
  }),

  on(EntityActions.updateEntityReadStatusSuccess, (state, action) => {
    const update: Update<Entity> = {
      id: action.id,
      changes: {},
    };
    const beforeUpdate = state.entities[action.id];
    const afterUpdate = cloneDeep(beforeUpdate);
    if (afterUpdate) {
      afterUpdate.attributes.is_read = true;

      update.changes = {
        attributes: afterUpdate.attributes,
      };
    }

    return adapter.updateOne(update, state);
  }),

  on(approveOrRejectChamberInfoSuccess, (state, action) => {
    const update: Update<Entity> = {
      id: action.id,
      changes: {},
    };
    const beforeUpdate = state.entities[action.id];
    const afterUpdate = cloneDeep(beforeUpdate);
    if (afterUpdate) {
      // this is a hint for
      afterUpdate.attributes.ready_for_deletion = true;

      update.changes = {
        attributes: afterUpdate.attributes,
      };
    }
    // remove entity from list
    //return state;
    return adapter.updateOne(update, state);
    // return adapter.removeOne(action.id, state);
  }),

  on(EntityActions.updateEntityModerationStatusSuccess, (state, action) => {
    const update: Update<Entity> = {
      id: action.id,
      changes: {},
    };
    const beforeUpdate = state.entities[action.id];
    const afterUpdate = cloneDeep(beforeUpdate);
    if (afterUpdate) {
      afterUpdate.attributes.moderation_state = ModerationSate.DRAFT; // which state?

      update.changes = {
        attributes: afterUpdate.attributes,
      };
    }

    return adapter.updateOne(update, state);
  }),

  on(EntityActions.cleanUpEntities, (state) => {
    let idsToDelete: Array<string> = [];
    for (const [key, value] of Object.entries(state.entities)) {
      if (value?.attributes.ready_for_deletion === true) {
        idsToDelete.push(value.id);
      }
    }

    return adapter.removeMany(idsToDelete, state);
  }),
  on(EntityActions.purgeEntities, (state) => {
    return adapter.removeAll(state);
  })
);

export function EntityReducer(state: State | undefined, action: Action) {
  return entityReducer(state, action);
}

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

function processEntityByType(ent: Entity, included?: Array<any>) {
  let entity = cloneDeep(ent);

  switch (entity.type) {
    case EntityTypesId.MediaImage:
      if (included) {
        const fieldMediaImageData = included.find(
          (include) =>
            include.id === entity.relationships.field_media_image.data.id
        );
        entity.relationships.field_media_image.data = fieldMediaImageData
          ? fieldMediaImageData
          : entity.relationships.field_media_image.data;

        const thumbnailData = included.find(
          (include) => include.id === entity.relationships.thumbnail.data.id
        );

        entity.relationships.thumbnail.data = thumbnailData
          ? thumbnailData
          : entity.relationships.thumbnail.data;
      }

      break;
    case EntityTypesId.MediaDocument:
      if (included) {
        const fieldMediaDocumentData = included.find(
          (include) =>
            include.id === entity.relationships.field_media_document.data.id
        );
        entity.relationships.field_media_document.data = fieldMediaDocumentData
          ? fieldMediaDocumentData
          : entity.relationships.field_media_document.data;

        const thumbnailData = included.find(
          (include) => include.id === entity.relationships.thumbnail.data.id
        );

        entity.relationships.thumbnail.data = thumbnailData
          ? thumbnailData
          : entity.relationships.thumbnail.data;
      }
      break;
    case EntityTypesId.NewsPost:
      if (included) {
        try {
          const fieldLastRejectedUser = included.find(
            (include) =>
              include.id ===
              entity.relationships.field_last_rejected_user.data.id
          );

          entity.relationships.field_last_rejected_user.data =
            fieldLastRejectedUser
              ? fieldLastRejectedUser
              : entity.relationships.field_last_rejected_user.data;
        } catch (e) {}
      }

      break;
  }

  return entity;
}
