import { Injectable } from "@angular/core";
import { AppStore } from "../store/appStore";
import { Store } from "@ngrx/store";
import { Actions } from "@ngrx/effects";
import { alertError, alertInfo } from "../store/alerts/alerts.actions";
import { PushNotificationSchema } from "@capacitor/push-notifications";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private _store: Store<AppStore>, private _actions$: Actions) {
    // we could implement a listener on any error actions to catch them here and display error
  }

  public DispatchAlert(
    title: string,
    message: string = "",
    duration: number = -1
  ) {
    this._store.dispatch(
      alertError({
        title: title,
        duration: duration,
        message: message,
      })
    );
  }

  public DispatchInfo(
    title: string,
    message: string = "",
    duration: number = 10,
    data?: {
      action: "push_notification_click";
      notification?: PushNotificationSchema;
    }
  ) {
    this._store.dispatch(
      alertInfo({
        title: title,
        duration: duration,
        message: message,
        data: data,
      })
    );
  }
}
