<div class="header__background"></div>
<div class="header__safe-area-spacer"></div>
<div class="header__action-bar" [ngClass]="{hide: showSearchInput}">
    <app-svg-icon
        class="icon"
        [src]="'logo.svg'"
        [svgStyle]="{ 'width.px': '76', 'height.px': '47' }"
    ></app-svg-icon>
    <div class="header__actions">
        <app-icon-button (action)="showSearchInput = true" [addPadding]="true" [style]="{width: '32', height: '32'}" src="icons/32/search.svg"></app-icon-button>
        <app-profile-icon (action)="SetEvent($event, DashboardRoutesType.Settings)" [initials]="initials$ | async"></app-profile-icon>
    </div>
</div>
<div class="spacer-2"></div>
<div class="header__content">
    <h1 class="schmuck">{{ 'PAGES.DASHBOARD.HEADER.TITLE' | translate:{name: fullname$ | async} }}</h1>
    <p class="label">{{ 'PAGES.DASHBOARD.HEADER.TEXT' | translate }}</p>
</div>
<div class="spacer-5"></div>
<app-input-search-text [@enterLeave] *ngIf="showSearchInput" (action)="SetEvent($event)" id="search-input" [autofocus]="true" [closeInputOption]="true"></app-input-search-text>
<div *ngIf="((searchResult$ | async) || firstSearchHappendAlready) && currentTextFilter.value !== ''" class="header__search-results">
    <div class="header__search-results-header"></div>
    <div class="header__search-results-wrapper content">
        <ng-container *ngIf="searchResult$ | async as searchResults; else loading">
            <p *ngIf="searchResults.length > 0" class="filter">{{ 'PAGES.DASHBOARD.HEADER.SEARCH_RESULT.TEXT' | translate:{value: (searchResult$ | async).length} }}</p>
            <p *ngIf="searchResults.length == 0" class="filter center">{{ 'PAGES.DASHBOARD.HEADER.SEARCH_RESULT.NO_RESULTS' | translate }}</p>
            <div class="spacer"></div>
            <app-info-card
                *ngFor="let searchResult of searchResults"
                (action)="SetEvent($event, DashboardRoutesType.NewsDetail, searchResult.id)"
                [title]="searchResult.attributes.field_teaser_title || searchResult.attributes.title"
                [infoDate]="searchResult.attributes.publication_date"
                [new]="!searchResult.attributes.is_read"
            >
            </app-info-card>
        </ng-container>
    </div>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
