import { createSelector } from "@ngrx/store";
import { selectEntity } from "../../io/entity/store/entity.selector";
import { MediaImage } from "./media-image";
import cloneDeep from "lodash-es/cloneDeep";

export const selectMediaImage = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    if (!entity) return entity;

    return transformEntityToMediaImage(
      entity as MediaImage,
      entity.relationships.field_media_image.data.attributes.uri.url
    );
  });

export const selectMediaImageThumbnail = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    if (!entity) return entity;

    return transformEntityToMediaImage(
      entity as MediaImage,
      entity.relationships.thumbnail.data.attributes.uri.url
    );
  });

function transformEntityToMediaImage(
  entity: MediaImage | undefined,
  url: string
): MediaImage | undefined {
  if (!entity) return entity;

  const image = cloneDeep(entity);

  image.attributes.url = url;

  return image;
}
