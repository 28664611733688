import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { InputActionsType } from "./input-actions.type";
import { InputOutput } from "../input-output";

@Component({
  selector: "app-input-checkbox",
  templateUrl: "./input-checkbox.component.html",
  styleUrls: ["./input-checkbox.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class InputCheckboxComponent implements OnInit, OnChanges {
  /**
   * Checked state of checkbox
   */
  @Input() checked: boolean | null;

  /**
   * output current state
   */
  @Output() action: EventEmitter<InputOutput> = new EventEmitter<InputOutput>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  public valueChanged(event: any) {
    this.action.emit({
      type:
        event && event.target && event.target.checked
          ? InputActionsType.Checked
          : InputActionsType.Unchecked,
    });
  }
}
