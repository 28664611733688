export enum DashboardRoutesType {
  NewsOverview = "app/kammerinfos",
  NewsDetail = "app/kammerinfo/",
  EventOverview = "app/event",
  Settings = "app/settings",
  Onboarding = "app/welcome",
  Dashboard = "app/dashboard",
  MediFinder = "app/medifinder",
  MediFinderDetail = "app/medifinder/",
}
