import { ApoDepartment } from "./apo-department";
import { createSelector } from "@ngrx/store";
import { selectEntity } from "../../io/entity/store/entity.selector";

export const selectApoDepartment = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    return transformEntityToApoDepartment(entity as ApoDepartment);
  });

function transformEntityToApoDepartment(
  entity: ApoDepartment | undefined
): ApoDepartment | undefined {
  if (!entity) return entity;

  return entity;
}
