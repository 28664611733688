import { Injectable } from "@angular/core";
import { ViewHelper } from "./view-helper";
import { Actions } from "@ngrx/effects";
import { ViewService } from "../view.service";
import { AppStore } from "../../../store/appStore";
import { Store } from "@ngrx/store";
import { CacheService } from "../cache.service";

@Injectable({
  providedIn: "root",
})
export class ViewHelperService {
  private _viewHelpers: Array<ViewHelper> = [];
  constructor(
    private _actions$: Actions,
    private _viewService: ViewService,
    private _store: Store<AppStore>,
    private _cacheService: CacheService
  ) {}

  getViewHelper(id: string, purgeable: boolean = false): ViewHelper {
    let viewHelper = this._viewHelpers.find(
      (viewHelper) => viewHelper.id === id
    );

    if (viewHelper) {
      return viewHelper;
    } else {
      this.purgeViewHelpers();

      viewHelper = new ViewHelper(
        id,
        this._actions$,
        this._viewService,
        this._store,
        this._cacheService,
        purgeable
      );
      this._viewHelpers.push(viewHelper);
      return viewHelper;
    }
  }

  private purgeViewHelpers() {
    const before = this._viewHelpers.length;

    //call onPurge on all purgeable viewHelpers
    this._viewHelpers
      .filter((viewHelper) => viewHelper.purgeable)
      .forEach((viewHelper) => {
        viewHelper.onPurge();
      });

    // Purge all purgeable viewHelpers
    this._viewHelpers = this._viewHelpers.filter(
      (viewHelper) => !viewHelper.purgeable
    );

    console.info(`Purged ${before - this._viewHelpers.length} viewHelpers`);
  }

  public purgeViewHelpersWithName(partialName: string) {
    this._viewHelpers.forEach((viewHelper) => {
      if (viewHelper.id.indexOf(partialName) > -1) viewHelper.purgeable = true;
    });
    this.purgeViewHelpers();
  }
}
