import { Component, Input, OnInit } from "@angular/core";
import { EntityService } from "../../../../io/entity/entity.service";
import { EntityTypesId } from "../../../../io/entity/types/entityTypes";

import { ConfigService } from "../../../../config/config.service";
import { Observable } from "rxjs";
import { MediaImage } from "../../../../store/media/media-image";
import {
  selectMediaImage,
  selectMediaImageThumbnail,
} from "../../../../store/media/media-image.selector";

export enum MediaImageSizes {
  default,
  thumbnail,
}

@Component({
  selector: "app-media-image",
  templateUrl: "./media-image.component.html",
  styleUrls: ["./media-image.component.scss"],
})
export class MediaImageComponent implements OnInit {
  @Input() id: string;
  @Input() mediaImageSize: MediaImageSizes = MediaImageSizes.default;

  public cmsURL: string;
  public media$: any;

  constructor(
    private _entityService: EntityService,
    private _configService: ConfigService
  ) {
    this.cmsURL = this._configService.config.cms.url;
  }

  ngOnInit() {
    this.media$ = this._entityService.getEntity(
      EntityTypesId.MediaImage,
      this.id,
      this.getMediaImageSelector()
    ) as Observable<MediaImage>;
  }

  getMediaImageSelector() {
    switch (this.mediaImageSize) {
      case MediaImageSizes.thumbnail:
        return selectMediaImageThumbnail;
      default:
        return selectMediaImage;
    }
  }
}
