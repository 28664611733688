
<div class="container">
    <app-header></app-header>
    <div class="dashboard__content content">
        <div class="row">
            <div class="col-xsmall-12 dashboard-cards">
                <app-dashboard-card *ngIf="event$ | async as event" (action)="SetEvent($event, DashboardRoutesType.EventOverview)" [title]="event.title" [type]="DashboardCardType.Event" [event]="event"></app-dashboard-card>
                <div class="medifinder-wrapper">
                <app-dashboard-card (action)="SetEvent($event, DashboardRoutesType.MediFinder)" [type]="DashboardCardType.MediFinder" [title]="'MEDIFINDER.TITLE' | translate" [text]="'MEDIFINDER.DASHBOARD.TEXT' | translate"></app-dashboard-card>
                    <app-icon-button class="icon-button" src="icons/32/info.svg" (click)="medifinderInfoModalOpen = true"></app-icon-button>
                </div>
            </div>
        </div>
        <div class="spacer-2"></div>


        <div class="row">
            <div class="col-xsmall-12">
                <div class="dashboard__infos-header">
                    <div class="dashboard__infos-title card-title">{{ 'PAGES.INFO_OVERVIEW.TITLE' | translate}}</div>
                    <div class="dashboard__infos-button"></div>
                    <app-text-button (action)="SetEvent($event, DashboardRoutesType.NewsOverview, '', {tab: ModerationSate.PUBLISHED})" label="{{'PAGES.DASHBOARD.NEWS_ALL'}}"></app-text-button>
                </div>
                <ng-container *ngIf="(newsToApprove$ | async)?.count > 0">
                    <div class="spacer-1"></div>
                    <app-notification-banner (action)="SetEvent($event, DashboardRoutesType.NewsOverview, '', {tab: ModerationSate.WAITING_FOR_APPROVAL})" [count]="(newsToApprove$ | async)?.count">
                        <p class="button-text">{{ ((ownRejectedNewsPosts$ | async)?.count === 1 ? 'PAGES.DASHBOARD.NOTIFICATION_BANNER.SINGULAR' : 'PAGES.DASHBOARD.NOTIFICATION_BANNER.PLURAL') | translate: {value: ((newsToApprove$ | async)?.count)} }}</p>
                    </app-notification-banner>
                </ng-container>
                <ng-container *ngIf="(ownRejectedNewsPosts$ | async)?.count > 0">
                    <div class="spacer-1"></div>
                    <app-notification-banner (action)="SetEvent($event, DashboardRoutesType.NewsOverview, '', {tab: ModerationSate.OWN_REJECTED})" [count]="(ownRejectedNewsPosts$ | async)?.count">
                        <p class="button-text">{{ ((ownRejectedNewsPosts$ | async)?.count === 1 ? 'PAGES.DASHBOARD.NOTIFICATION_BANNER_REJECTED.SINGULAR' : 'PAGES.DASHBOARD.NOTIFICATION_BANNER_REJECTED.PLURAL') | translate: {value: ((ownRejectedNewsPosts$ | async)?.count)} }}</p>
                    </app-notification-banner>
                </ng-container>
                <div class="spacer-1"></div>
                <div *ngIf="newsPosts; else loading" [@listAnimation]="newsPosts.length">
                    <ng-container *ngFor="let newsPost of newsPosts, trackBy: track; let i = index">

                    <app-info-card *ngIf="i < NEWS_ITEMS_TO_SHOW"

                        (action)="SetEvent($event, DashboardRoutesType.NewsDetail, newsPost.id)"
                        [title]="newsPost.attributes.field_teaser_title || newsPost.attributes.title"
                        [federalStates]="newsPost.attributes.federalStates"
                        [infoDate]="newsPost.attributes.publication_date"
                        [new]="!newsPost.attributes.is_read"
                    >
                    </app-info-card>
                    </ng-container>
                </div>
                <app-load-more-button [viewHelper]="viewHelper" [hideOnElements]="NEWS_ITEMS_TO_SHOW"></app-load-more-button>
                <div class="spacer-2"></div>
            </div>
        </div>
    </div>
</div>

<app-medifinder-info-modal [(open)]="medifinderInfoModalOpen"></app-medifinder-info-modal>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
