import { Injectable } from "@angular/core";
import { ApiConnectorService } from "../api-connector.service";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorMedicationService extends ApiConnectorService {
  constructor(public configService: ConfigService, public http: HttpClient) {
    super(configService);
  }

  public annulateMedicationSearch(payload: string): Observable<any> {
    return this.http.patch(
      this._configService.config.cms.url +
        "/api/medi-finder/medication-search/" +
        payload,
      {
        status: 0,
      }
    ) as Observable<any>;
  }

  public austriaCodexSearch(searchString: string): Observable<any> {
    return this.http.get(
      this._configService.config.cms.url +
        "/api/austria-codex/webservice/v1/find?cacheTTL=300&query=" +
        encodeURIComponent(searchString)
    ) as Observable<any>;
  }

  public createSearch(payload: any): Observable<any> {
    return this.http.post(
      this._configService.config.cms.url + "/api/medi-finder/medication-search",
      payload
    ) as Observable<any>;
  }
}
