<app-back-header  (action)="SetEvent($event, DashboardRoutesType.Dashboard)" title="{{ 'PAGES.INFO_OVERVIEW.TITLE' | translate }}" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }"></app-back-header>
<div class="container" [ngClass]="{'active-modal': filterModalIsOpen}"  (scroll)="onContainerScroll($event)">

    <div class="content" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }">
        <div class="row" *ngIf="tabs$ | async as tabs  " >
            <div class="col-xsmall-12" *ngIf="tabs.length > 1">
                <div class="info-overview__tab-wrapper">
                    <app-tabs (action)="SetEvent($event)" [activeItem]="currentModerationState" [items]="tabs"></app-tabs>
                    <div class="spacer"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xsmall-12">
                <div class="info-overview__filter-wrapper">
                    <app-input-search-text [initValue]="currentTextFilter.value" (action)="SetEvent($event)" placeholder="Suchbegriff eingeben..."></app-input-search-text>
                    <app-icon-button (action)="openModal()" [addPadding]="true" [style]="{width: '24', height: '24'}" src="icons/24/filter.svg" [ngClass]="{disabled: currentModerationState !== ModerationSate.PUBLISHED}"></app-icon-button>
                </div>
            </div>
        </div>
        <div class="row" appSwipe (leftSwipePosition)="leftSwipePosition = $event" [ngStyle]="{'left.px': leftSwipePosition }" (leftSwipe)="BackToDashboard()">
            <div class="col-xsmall-12" >
                <div class="spacer-and-half"></div>
                <div class="info-overview__container" *ngIf="(newsPosts$ | async) as newsPosts else loading" [@listAnimation]="newsPosts.length" [@.disabled]="newsPosts.length > 16">
                    <p *ngIf="newsPosts.length == 0" class="center info-overview__no-results">{{ 'PAGES.INFO_OVERVIEW.SEARCH_RESULT.NO_RESULT' | translate}}</p>
                    <ngx-pull-to-refresh spinnerColor="#ff0000"
                                         spinnerSize="50"
                                         distanceForRefresh="100"
                                         [isEnable]="enablePullToRefresh"
                                         [targetElement]="targetElement"
                                         (refresh)="refresh($event, 'refresh')"
                                         style="display:block;height:100%;">
                    <app-info-card *ngFor="let newsPost of newsPosts; trackBy: track"
                        (action)="SetEvent($event, DashboardRoutesType.NewsDetail, newsPost.id)"
                        [title]="newsPost.attributes.field_teaser_title || newsPost.attributes.title"
                        [infoDate]="newsPost.attributes.publication_date"
                        [new]="!newsPost.attributes.is_read"
                                   [federalStates]="newsPost.attributes.federalStates"
                                   id="{{newsPost.id}}"
                    >
                    </app-info-card>
                    </ngx-pull-to-refresh>
                </div>
                <app-load-more-button [viewHelper]="viewHelper"></app-load-more-button>
                <div class="spacer-4"></div>
            </div>
        </div>
    </div>


</div>

<app-filter-modal [open]="filterModalIsOpen" (openChange)="filterModalIsOpen = $event" (output)="SetAction($event)"></app-filter-modal>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
