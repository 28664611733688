import { Component, EventEmitter, Output } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { UserPreferenceType } from "../../../store/user/user.type";
import { AppStore } from "../../../store/appStore";
import { Store } from "@ngrx/store";
import {
  GetUserFederalStatesSettingForDashboard,
  GetUserSettingForPushNotification,
} from "../../../store/user/user.selector";
import {
  cancelSetUserPreferences,
  setUserPreferences,
} from "../../../store/user/user.actions";
import { InputActionsType } from "../../input/input-checkbox/input-actions.type";
import { MODAL_ACTIONS, ModalAction } from "../modal-actions";
import { filterModalAnimationsEnterLeave } from "../filter-modal/filter-modal.animations";

@Component({
  selector: "app-medifinder-filter-modal",
  templateUrl: "./medifinder-filter-modal.component.html",
  styleUrls: ["./medifinder-filter-modal.component.scss"],
  animations: [filterModalAnimationsEnterLeave],
})
export class MedifinderFilterModalComponent extends BaseModalComponent {
  public mediFederalStates$;
  public mediPushNotifications$;

  @Output() output: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  constructor(private _store: Store<AppStore>) {
    super();
    this.mediFederalStates$ = this._store.select(
      GetUserFederalStatesSettingForDashboard("field_medifinder_federal_states")
    );

    this.mediPushNotifications$ = this._store.select(
      GetUserSettingForPushNotification("field_medifinder_push_enabled")
    );
  }
  public SetFilter(event: any, preference: UserPreferenceType) {
    switch (preference) {
      case UserPreferenceType.MedifinderFederalStates:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          setUserPreferences({
            id: preference,
            value: event.data
              .filter((filter: any) => filter.active)
              .map((f: any) => f.id),
          })
        );
        break;
      case UserPreferenceType.MedifinderPushNotifications:
        console.log({
          id: preference,
          value: event.type == InputActionsType.Checked,
        });
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          setUserPreferences({
            id: preference,
            value: event.type == InputActionsType.Checked,
          })
        );
        break;
    }
  }

  public EmitFilter() {
    this.output.emit({
      action: MODAL_ACTIONS.filter,
    });
  }

  protected readonly UserPreferenceType = UserPreferenceType;
}
