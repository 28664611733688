import { Component, OnDestroy } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { ButtonType } from "../../button/button.type";
import { filterModalAnimationsEnterLeave } from "../filter-modal/filter-modal.animations";

@Component({
  selector: "app-medifinder-info-modal",
  templateUrl: "./medifinder-info-modal.component.html",
  styleUrls: ["./medifinder-info-modal.component.scss"],
  animations: [filterModalAnimationsEnterLeave],
})
export class MedifinderInfoModalComponent extends BaseModalComponent {
  constructor() {
    super();
  }

  protected readonly ButtonType = ButtonType;
}
