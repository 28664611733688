import { Component } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent {
  options: AnimationOptions = {
    path: "/assets/animations/logo-loading.json",
  };
}
