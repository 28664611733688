export interface ModalAction {
  action: MODAL_ACTIONS;
  value?: any;
}

export enum MODAL_ACTIONS {
  select,
  do,
  filter,
}
