import { Injectable } from "@angular/core";
import { filter, Observable, Subscriber, Subscription, take } from "rxjs";
import { AppStore } from "../../store/appStore";
import { Selector, Store } from "@ngrx/store";
import { addEntities, addEntity, loadEntity } from "./store/entity.actions";
import { EntityTypesId, getEntityTypeById } from "./types/entityTypes";
import { selectEntitiesByType, selectEntity } from "./store/entity.selector";
import { Entity } from "./types/entity";
import { CacheRefresh } from "./types/cache";
import { CacheService } from "./cache.service";
import { AliasService } from "./alias.service";
import { Actions, ofType } from "@ngrx/effects";
import { NavigationEnd, Router } from "@angular/router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EntityService {
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _store: Store<AppStore>,
    private _cacheService: CacheService,
    private _aliasService: AliasService,
    private _actions$: Actions,
    private _router: Router
  ) {
    const events = _router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe((subs) => {
        this._subscriptions.forEach((subs) => {
          if (subs) {
            subs.unsubscribe();
          }
        });
        this._subscriptions = [];
      });
  }

  /**
   * retrieve an entity either from the store (cached) or load it from the backend
   * @param type
   * @param resources
   * @param selector
   * @param cacheRefresh
   */
  public getEntities(
    type: EntityTypesId,
    resources?: Array<string>,
    selector: Selector<any, any> = selectEntitiesByType,
    cacheRefresh: CacheRefresh = CacheRefresh.always
  ): Observable<Array<Entity>> {
    return new Observable((obs) => {
      const subscription = this._store
        .select(selector(type))
        .subscribe((res) => {
          if (Array.isArray(res)) {
            //if resources is not empty, filter the selector result to only return resources specified
            if (resources) {
              obs.next(res.filter((value) => resources.indexOf(value.id) >= 0));

              let toLoad = 0;
              resources.forEach((resource) => {
                //check all resources if they are eligible to be loaded again, otherwise don't load it

                if (
                  this._cacheService.shouldLoad(
                    this.resolveURI(type, resource),
                    cacheRefresh
                  )
                ) {
                  this._cacheService.addToCache(
                    this.resolveURI(type, resource),
                    undefined,
                    true
                  );
                  //this._aliasService.addAlias()
                  this.dispatchLoad(type, resource);
                }
              });

              if (toLoad === 0) {
                obs.complete();
                setTimeout(() => {
                  subscription.unsubscribe();
                }, 1);
              }
            } else {
              obs.next(res);

              //check if bundle is already loaded
              if (
                this._cacheService.shouldLoad(
                  this.resolveURI(type),
                  cacheRefresh
                )
              ) {
                this._cacheService.addToCache(this.resolveURI(type));
                this.dispatchLoad(type);
              }

              if (res) {
                obs.complete();

                setTimeout(() => {
                  subscription.unsubscribe();
                }, 1);
              }
            }
          } else {
            console.error("returned value is no array");
            obs.next(undefined);
            obs.complete();

            setTimeout(() => {
              subscription.unsubscribe();
            }, 1);
          }
        });

      this._subscriptions.push(subscription);
    });
  }

  public getEntity(
    type: EntityTypesId,
    resource: string,
    selector: Selector<any, any> = selectEntity,
    cacheRefresh: CacheRefresh = CacheRefresh.one_minute,
    suppressError: boolean = false
  ): Observable<any> {
    return new Observable((obs) => {
      const resourceIsUUID = this.isUUID(resource);

      //if it's a uuid, load right away
      if (resourceIsUUID) {
        if (
          this._cacheService.shouldLoad(
            this.resolveURI(type, resource),
            cacheRefresh
          )
        ) {
          //subscribe to any changes to the entity
          this.handleEntitySubscription(resource, obs, selector);

          this.dispatchLoad(type, resource, suppressError);
        } else {
          //check if it's already loaded

          this._store
            .select(selector(resource))
            .pipe(take(1))
            .subscribe((res) => {
              //if its already loaded, return it
              if (res) {
                obs.next(res);
                obs.complete();
                return;
              } else {
                //subscribe to any changes to the entity
                this.handleEntitySubscription(resource, obs, selector);

                this.dispatchLoad(type, resource);
              }
            });
        }

        return;
      } else {
        const entityType = getEntityTypeById(type);
        let uuid = this._aliasService.retrieveResourceFromAlias(
          `${entityType ? "/" + entityType.path + "/" : ""}${resource}`
        );
        //check if we get a uuid from the alias service, we can then select the entity by uuid
        if (uuid) {
          this._store
            .select(selector(uuid))
            .pipe(take(1))
            .subscribe((res) => {
              obs.next(res);
              obs.complete();
            });
        } else {
          //subscribe to any changes to the entity
          const subscription = this._actions$
            .pipe(ofType(addEntity.type, addEntities.type))
            .subscribe((res) => {
              let uuid = this._aliasService.retrieveResourceFromAlias(
                `${entityType ? "/" + entityType.path + "/" : ""}${resource}`
              );

              if (uuid) {
                this._store.select(selector(uuid)).subscribe((res) => {
                  obs.next(res);
                });
                subscription.unsubscribe();
              }
            });
          this.dispatchLoad(type, resource);
        }
      }
    });
  }

  public getMultipleEntities(
    type: EntityTypesId,
    resources: Array<string>,
    selector: Selector<any, any> = selectEntity,
    cacheRefresh: CacheRefresh = CacheRefresh.always
  ) {
    return resources.map((resource) =>
      this.getEntity(type, resource, selector, cacheRefresh)
    );
  }

  /**
   * dispatches the correct entity load action
   * @param type
   * @param resource
   */
  public dispatchLoad(
    type: EntityTypesId,
    resource?: string,
    suppressError: boolean = false
  ) {
    this._store.dispatch(
      loadEntity({
        url: this.resolveURI(type, resource),
        suppressError: suppressError,
      })
    );
  }

  public resolveURI(type: EntityTypesId, resource?: string) {
    const entityType = getEntityTypeById(type);

    let includes = "";
    switch (type) {
      case EntityTypesId.MediaImage:
        includes += "?include=field_media_image";
        break;
      case EntityTypesId.MediaDocument:
        includes += "?include=field_media_document";
        break;
      case EntityTypesId.NewsPost:
        includes += "?include=field_federal_state,field_last_rejected_user";
        break;
      case EntityTypesId.MediFinderSearch:
        includes += "?include=owner_uid,owner_pharmacy";
        break;
    }

    if (!this.isUUID(resource ? resource : "") && entityType) {
      return `${entityType.path}/${resource}${includes}`;
    } else {
      return `${type.replace("--", "/")}${
        resource ? "/" + resource : ""
      }${includes}`;
    }
  }

  public isUUID(id: string): boolean {
    const uuid =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return uuid.test(id);
  }

  private handleEntitySubscription(
    resource: string,
    obs: Subscriber<any>,
    selector: Selector<any, any>
  ) {
    const subscription = this._actions$
      .pipe(ofType(addEntity.type, addEntities.type))
      .subscribe((res: { entities: Array<Entity> }) => {
        const isRequestedEntity = res.entities.find(
          (entity) => entity.id === resource
        );

        if (isRequestedEntity) {
          this._store.select(selector(resource)).subscribe((res) => {
            if (res) {
              obs.next(res);
              obs.complete();
              subscription.unsubscribe();
            }
          });
        }
      });
  }
}
