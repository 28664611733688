import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "../../store/status/auth/auth.service";
import { DeviceService } from "../../io/device/device.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-blacklist",
  templateUrl: "./login-blacklist.component.html",
  styleUrls: ["./login-blacklist.component.scss"],
})
export class LoginBlacklistComponent implements OnDestroy {
  constructor(
    private _authService: AuthService,
    private _deviceService: DeviceService,
    private _router: Router
  ) {
    this.checkToken();
  }

  public checkToken() {
    if (this._authService.isAuthTokenExpired()) {
      this._router.navigateByUrl("/logout");
    } else {
      setTimeout(() => {
        this.checkToken();
      }, 500);
    }
  }

  logout() {
    this._authService.logout();
  }

  ngOnDestroy(): void {}
}
