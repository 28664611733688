<app-back-header [filterOption]="this.activeTab === 'global' ? FilterOption.enabled : FilterOption.disabled " (action)="SetEvent($event, DashboardRoutesType.Dashboard)" title="{{ 'MEDIFINDER.TITLE' | translate }}" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }"></app-back-header>
<div class="container" >


    <div class="content" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }">
        <div class="spacer spacer-2" *ngIf="!userHasPharmacies"></div>
        <div class="button-wrapper">
        <app-button label="MEDIFINDER.NEW_SEARCH.BUTTON" [fullWidth]="true" (click)="triggerNewSearchModal()" *ngIf="userHasPharmacies"></app-button>
            <app-icon-button class="icon-button" src="icons/32/info.svg" (click)="mediFinderInfoModalOpen = true"></app-icon-button>
        </div>
        <div class="spacer spacer-2"></div>


        <div class="row" *ngIf="showPersonalTab" >
            <div class="col-xsmall-12">
                <div class="info-overview__tab-wrapper">
                    <app-tabs (action)="SetEvent($event)"  [items]="[{label: 'Gesucht wird', active: this.activeTab === 'global', value: 'global',weight: 0 },{label: 'Meine Suchen', active: this.activeTab === 'personal', value: 'personal',weight: 10 }]"></app-tabs>
                    <div class="spacer"></div>
                </div>
            </div>
        </div>
        <div class="row" appSwipe (leftSwipePosition)="leftSwipePosition = $event" [ngStyle]="{'left.px': leftSwipePosition }" (leftSwipe)="BackToDashboard()">
            <div class="col-xsmall-12" >
                <div class="spacer-and-half"></div>
                <div class="info-overview__container" *ngIf="(search$ | async) as globalSearches else loading" [@listAnimation]="globalSearches.length" [@.disabled]="globalSearches.length > 16">
                    <p *ngIf="globalSearches.length == 0" class="center info-overview__no-results">{{ 'MEDIFINDER.SEARCH_RESULT.NO_RESULT' | translate}}</p>
                    <ngx-pull-to-refresh spinnerColor="#ff0000"
                                         spinnerSize="50"
                                         distanceForRefresh="100"
                                         [isEnable]="enablePullToRefresh"
                                         [targetElement]="targetElement"
                                         (refresh)="refresh($event, 'refresh')"
                                         style="display:block;height:100%;">
                        <div *ngFor="let search of globalSearches; trackBy: track">
                            <app-medifinder-search-card
                                    class="box"
                                [infoDate]="search.attributes.created"
                                [title]="search.attributes.package_name"
                                [pharmacyId]="search.relationships.owner_pharmacy.data.id"
                                    [own]="search.attributes.own"
                                    (action)="SetEvent($event, DashboardRoutesType.MediFinderDetail, search.id)"
                                    id="{{search.id}}"
                            >

                            </app-medifinder-search-card>
                        </div>
                    </ngx-pull-to-refresh>
                </div>
                <app-load-more-button [viewHelper]="view"></app-load-more-button>
                <div class="spacer-4"></div>
            </div>
        </div>
    </div>



</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<app-medifinder-filter-modal [open]="filterModalIsOpen" (openChange)="filterModalIsOpen = $event" (output)="SetAction($event)"></app-medifinder-filter-modal>

<app-new-medifinder-search-modal [(open)]="newSearchModal" (reloadView)="changeView(true)"></app-new-medifinder-search-modal>

<app-medifinder-info-modal [(open)]="mediFinderInfoModalOpen"></app-medifinder-info-modal>
