import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessService } from "../../services/access.service";
import { BiometricAccessService } from "../../services/biometric-access.service";

@Component({
  selector: "app-login-error",
  templateUrl: "./login-error.component.html",
  styleUrls: ["./login-error.component.scss"],
})
export class LoginErrorComponent implements OnInit {
  public errorString = "";
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _biometricAccessService: BiometricAccessService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((params) => {
      const error = params["error"] ? params["error"] : "default";

      this.errorString = "LOGIN_ERROR." + error;
    });
  }

  public retry() {
    this._router.navigateByUrl("/app/dashboard");
  }
}
