<div class="container info-detail" appSwipe [allowDirections]="{
left: viewHelperPrevNext.prev,
right: viewHelperPrevNext.next
}" (leftSwipePosition)="leftSwipePosition = $event"
     [ngStyle]="{'left.px': leftSwipePosition }" (leftSwipe)="swipeBack(viewHelperPrevNext.prevId)" (rightSwipe)="swipeNext(viewHelperPrevNext.nextId)">

    <app-back-header (action)="SetAction($event)" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }"
                     [detailView]="true" title="{{ 'PAGES.INFO_DETAIL.TITLE' | translate }}"></app-back-header>



    <ng-container *ngIf="(content$ | async) as content">
        <div class="content info-detail__header"
             [ngClass]="{'no-bottom': !content.relationships.field_main_image.data}">
            <h1 class="bold" [innerHTML]="content.attributes.title"></h1>
            <div class="caption">
                <span *ngIf="content.attributes.publication_date else noDate">{{content.attributes.publication_date | date:dateTimeStandard}} {{ "STRINGS.TIME" | translate}}</span>
                <!-- in case of no date, show a no date info -->
                <ng-template #noDate>
                    <span>{{ "STRINGS.DRAFT" | translate}}</span>
                </ng-template>
                <span *ngIf="content.attributes.federalStates"> - </span>
                <app-federal-state [ids]="content.attributes.federalStates"></app-federal-state>
            </div>
            <div class="spacer-and-half"></div>
            <ng-container *ngIf="rejectMessageState === rejectMessageStates.OWN">
                <app-callout>
                    <p>

                        <b *ngIf="rejector else noUser" [innerHTML]="'PAGES.INFO_DETAIL.REJECTED.TITLE' | translate:{user: rejector}"></b>
                        <ng-template #noUser><b>{{ 'PAGES.INFO_DETAIL.REJECTED.TITLE_NO_USER' | translate}}</b>
                        </ng-template>

                        {{ 'PAGES.INFO_DETAIL.REJECTED.REASON' | translate:{reason: rejectedReason} }}
                    </p>
                </app-callout>
                <div class="spacer-and-half"></div>
            </ng-container>
            <ng-container
                    *ngIf="rejectMessageState === rejectMessageStates.RESUBMITTED">
                <app-callout>
                    <p>

                        <b *ngIf="rejector else noUser" [innerHTML]="'PAGES.INFO_DETAIL.REJECTED_REDACTED.TITLE' | translate:{user: rejector}"></b>
                        <ng-template #noUser><b>{{ 'PAGES.INFO_DETAIL.REJECTED_REDACTED.TITLE_NO_USER' | translate}}</b>
                        </ng-template>

                        {{ 'PAGES.INFO_DETAIL.REJECTED_REDACTED.REASON' | translate:{reason: rejectedReason} }}
                    </p>
                </app-callout>
                <div class="spacer-and-half"></div>
            </ng-container>
            <div class="paragraph-highlight" [innerHTML]="content.attributes?.field_intro_text?.processed"></div>
        </div>

        <div class="content info-detail__image" *ngIf="content.relationships.field_main_image.data">
            <app-media-image [id]="content.relationships.field_main_image.data.id"></app-media-image>
        </div>
        <div class="content info-detail__content"
             [innerHTML]="content.attributes?.field_main_content?.processed | embeddedContent"></div>
        <div class="content info-detail__attachments" *ngIf="content.relationships.field_attachments.data.length">
            <app-attachments
                    [data]="content.relationships.field_attachments.data"
            ></app-attachments>
        </div>
        <div class="content info-detail__footer"
             [ngClass]="{'padding-bottom': content.attributes.moderation_state === ModerationState.WAITING_FOR_APPROVAL}">
            <app-department-footer
                    [id]="content.relationships.field_department.data"
            ></app-department-footer>
        </div>
        <!-- APPROVAL BUTTONS -->
        <ng-container
                *ngIf="content.attributes.moderation_state === ModerationState.WAITING_FOR_APPROVAL && approvalNecessary">
            <div class="info-detail__approval-buttons">
                <app-big-button (action)="OpenApprovalModal(ModalType.Decline)" [buttonType]="ButtonType.Secondary"
                                [label]="'MODAL.APPROVAL_PROCESS.BUTTONS.INFO_DECLINE' | translate"></app-big-button>
                <app-big-button (action)="OpenApprovalModal(ModalType.Approve)" [buttonType]="ButtonType.Primary"
                                [label]="'MODAL.APPROVAL_PROCESS.BUTTONS.INFO_APPROVE' | translate"></app-big-button>
            </div>
            <div [@enterLeaveChild]="approvalModalIsOpen ? 'open' : 'closed' ">
                <app-approval-modal *ngIf="approvalModalIsOpen" [id]="content.id" [open]="approvalModalIsOpen"
                                    [type]="currentModalType"
                                    (openChange)="approvalModalIsOpen = $event" (approvalAction)="onApprovalOrRejection($event)"></app-approval-modal>
            </div>

        </ng-container>


    </ng-container>
</div>

