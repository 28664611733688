import { Component, HostBinding, Input } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
  selector: "app-skeleton-text",
  templateUrl: "./skeleton-text.component.html",
  styleUrls: ["./skeleton-text.component.scss"],
})
export class SkeletonTextComponent {
  @Input() width: number = 0;

  @HostBinding("style")
  get myStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `min-width: ${this.width}px`
    );
  }

  constructor(private sanitizer: DomSanitizer) {}
}
