import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { Store } from "@ngrx/store";
import { GetEventUrl } from "../../store/user/user.selector";
import { BackHeaderImageType } from "../../ui/header/back-header/back-header.type";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";

@Component({
  selector: "app-info-overview",
  templateUrl: "./event-overview.component.html",
  styleUrls: [
    "./event-overview.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
})
export class EventOverviewComponent
  extends AnimationBaseComponent
  implements OnDestroy
{
  public eventUrl$: any;
  public BackHeaderImageType = BackHeaderImageType;

  constructor(
    private _router: Router,
    private _store: Store,
    protected override _navigationService: NavigationService
  ) {
    super(_navigationService);
    this.eventUrl$ = this._store.select(GetEventUrl);
  }

  public SetAction(event: any) {
    switch (event.type) {
      case ButtonActionsType.Back:
        this._navigationService.navigateByUrl(
          DashboardRoutesType.Dashboard,
          BasePageAnimationDirection.RIGHT
        );
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
