import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { ButtonActionsType } from "../button/button-actions.type";

@Directive({
  selector: "[appSwipe]",
})
export class SwipeDirective {
  private touchStart: TouchEvent;

  @Output() leftSwipePosition: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() leftSwipe: EventEmitter<number> = new EventEmitter<number>();
  @Output() rightSwipe: EventEmitter<number> = new EventEmitter<number>();

  @Input() allowDirections: {
    left: boolean;
    right: boolean;
  } = { left: true, right: false };

  constructor() {}

  @HostListener("touchstart", ["$event"])
  public touchstart(evt: TouchEvent) {
    this.touchStart = evt;
  }

  @HostListener("touchmove", ["$event"])
  public touchmove(evt: TouchEvent) {
    const swipePositionX = this.getX(evt) - this.getX(this.touchStart);

    if (swipePositionX > 100 && this.allowDirections.left) {
      this.leftSwipe.emit(new Date().getTime());
    } else if (swipePositionX > 30 && this.allowDirections.left) {
      this.leftSwipePosition.emit(swipePositionX);
    } else if (
      swipePositionX < -30 &&
      swipePositionX > -100 &&
      this.allowDirections.right
    ) {
      this.leftSwipePosition.emit(swipePositionX);
    } else if (swipePositionX < -100 && this.allowDirections.right) {
      this.rightSwipe.emit(new Date().getTime());
    } else {
      this.leftSwipePosition.emit(0);
    }
  }

  @HostListener("touchend", ["$event"])
  public touchend(evt: TouchEvent) {
    this.leftSwipePosition.emit(0);
  }

  private getX(evt: TouchEvent): number {
    try {
      return evt["touches"][0].clientX;
    } catch (e) {
      return 0;
    }
  }
}
