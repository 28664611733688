<div class="info_card__header">
    <div class="caption"
         *ngIf="infoDate">{{infoDate | date:dateTimeStandard}} {{ "STRINGS.TIME" | translate}}
    </div>
    <div *ngIf="own" class="caption-flag">{{"MEDIFINDER.CARD.OWN" | translate}}</div>
</div>
<div class="info_card__content">
    <div class="info_card__content__text">
        <div class="card-title">{{title}}</div>
        <p class="info_card__content__text__address caption">
        <ng-container *ngIf="pharmacy$ | async as pharmacy">
            {{pharmacy.attributes.name}}<br />
            {{pharmacy.attributes.address}},
            {{pharmacy.attributes.zip}} {{ pharmacy.attributes.city}}
        </ng-container>
        </p>
    </div>



    <app-svg-icon
            class="icon"
            [src]="'icons/24/arrow-right.svg'"
            [svgStyle]="{ 'width.px': '24', 'height.px': '24' }"
    ></app-svg-icon>

</div>
