import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ButtonComponent } from "../button/button.component";
import {
  ViewHelper,
  ViewHelperViewState,
} from "../../../io/entity/view-helper/view-helper";
import { Subscription } from "rxjs";

@Component({
  selector: "app-load-more-button",
  templateUrl: "./load-more-button.component.html",
  styleUrls: [
    "./load-more-button.component.scss",
    "../button/button.component.scss",
  ],
})
export class LoadMoreButtonComponent
  extends ButtonComponent
  implements OnDestroy, OnInit, OnChanges
{
  @Input() viewHelper: ViewHelper;
  public viewHelperViewState = ViewHelperViewState;

  public viewHelperStateSubscription$: Subscription;

  @Input() override label = "LOAD_MORE.NEXT";
  @Input() labelSingular = "LOAD_MORE.NEXT_SINGULAR";
  @Input() labelLoading = "LOAD_MORE.LOADING";
  @Input() showPager = true;

  //hides the element if this number of elements is loaded
  @Input() hideOnElements: number;

  constructor() {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {
    if (this.viewHelperStateSubscription$) {
      this.viewHelperStateSubscription$.unsubscribe();
    }
  }
}
