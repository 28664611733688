import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { Router } from "@angular/router";
import { EntityService } from "../../io/entity/entity.service";
import { EntityTypesId, ViewType } from "../../io/entity/types/entityTypes";
import { DashboardRoutesType } from "./dashboard-routes.type";
import { DashboardCardType } from "../../ui/box/dashboard-card/dashboard-card.type";
import { ModerationSate } from "../../store/news-post/news-post";
import { ViewService } from "../../io/entity/view.service";
import { Store } from "@ngrx/store";
import {
  GetDashboardLoadingStatus,
  GetEvent,
  GetEventUrl,
  getNumberOfOwnRejectedNewsPosts,
  GetUserFederalStatesPreferencesForDashboard,
  GetUserOnboardingCompleteStatus,
} from "../../store/user/user.selector";
import { Event } from "../../store/user/user";
import { first, Observable, Subject, take, takeUntil } from "rxjs";
import { loadOnboardingScreens } from "../../store/onboarding/onboarding.actions";
import { APP_INIT_STATES } from "../../store/status/status";
import { OnboardingCompletedType } from "../../store/user/user.type";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { NavigationService } from "../../services/navigation.service";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { ViewHelper } from "../../io/entity/view-helper/view-helper";
import { selectNewsPostByIds } from "../../store/news-post/news-post.selector";
import { listAnimation } from "../../animations/animation-base/element-animation";
import { PushNotificationService } from "../../io/push-notification.service";

import {
  fetchApprovalMetaData,
  fetchApprovalMetaDataSuccess,
} from "../../store/approval/approval.actions";
import { getNumberOfWaitingForApprovals } from "../../store/approval/approval.selector";
import { AlertService } from "../../alerts/alert.service";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { GetRefreshState } from "../../store/status/store/status.selector";
import { RefreshState } from "../../store/status/store/status.actions";
import { base64UrlEncode } from "../../helper/helper-functions";
import { AuthService } from "../../store/status/auth/auth.service";
import { DeviceService } from "../../io/device/device.service";
import { Actions, ofType } from "@ngrx/effects";
import { addEntities, addEntity } from "../../io/entity/store/entity.actions";
import { loadDashboardSuccess } from "../../store/user/user.actions";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: [
    "./dashboard.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
  animations: [listAnimation],
})
export class DashboardComponent
  extends AnimationBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public newsPostSubscription: any;
  public newsPosts: any;
  private _newsPostsFilterSub: any;
  private _refreshNewsPostsSub: any;
  private _dashboardLoadingStatusSub: any;
  public DashboardRoutesType = DashboardRoutesType;
  public DashboardCardType = DashboardCardType;
  public ModerationSate = ModerationSate;
  public event$: Observable<Event>;
  private _killFilterSubscription = new Subject();

  public medifinderInfoModalOpen = false;

  private _approvalCountSubscription: any;

  public NEWS_ITEMS_TO_SHOW = 3;

  public newsToApprove$: Observable<{ count: number }>;
  public ownRejectedNewsPosts$: Observable<{ count: number }>;

  public viewHelper: ViewHelper;

  private _appActiveSubscription;

  constructor(
    private _router: Router,
    private _entityService: EntityService,
    private _viewService: ViewService,
    private _store: Store,
    protected override _navigationService: NavigationService,
    private _alertService: AlertService,
    private _viewHelperService: ViewHelperService,
    private _pushNotificationService: PushNotificationService,
    private _authService: AuthService,
    private _deviceService: DeviceService,
    private _actions: Actions,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_navigationService);

    this.event$ = this._store.select(GetEvent) as Observable<Event>;

    if (_pushNotificationService.isAvailable()) {
      this._pushNotificationService.checkPermissions();
    }

    // first check if dashboard call is finished
    this._dashboardLoadingStatusSub = this._store
      .select(GetDashboardLoadingStatus)
      .subscribe((dashboardLoadingStatus) => {
        if (dashboardLoadingStatus === APP_INIT_STATES.SUCCESS) {
          this._store
            .select(GetUserOnboardingCompleteStatus)
            .pipe(first())
            .subscribe((status) => {
              if (
                status === OnboardingCompletedType.Completed &&
                !this._pushNotificationService.requested
              ) {
                this._pushNotificationService.requestPermissions();
              }

              // then check onboarding completed status - can be ether string (when coming from dashbaord call) or boolean (when coming from user preferences call)
              if (
                !(
                  status === OnboardingCompletedType.Completed || status == true
                )
              ) {
                // if not completed then load onboarding screens and navigate to welcome page
                this._store.dispatch(loadOnboardingScreens());

                this._navigationService.navigateByUrl(
                  DashboardRoutesType.Onboarding,
                  BasePageAnimationDirection.DOWN
                );
              }
            });

          if (this._dashboardLoadingStatusSub) {
            this._dashboardLoadingStatusSub.unsubscribe();
          }
        }
      });

    this._entityService
      .getEntities(EntityTypesId.FederalState)
      .subscribe((federal) => {});

    // load approval meta data
    this._appActiveSubscription = this._deviceService.appBecameActive.subscribe(
      () => {
        this._store.dispatch(fetchApprovalMetaData());
      }
    );

    this._approvalCountSubscription = this._actions
      .pipe(
        ofType(
          fetchApprovalMetaDataSuccess.type,
          loadDashboardSuccess.type,
          addEntity.type,
          addEntities.type
        )
      )
      .subscribe((el: any) => {
        this.newsToApprove$ = this._store.select(
          getNumberOfWaitingForApprovals()
        );

        this.ownRejectedNewsPosts$ = this._store.select(
          getNumberOfOwnRejectedNewsPosts()
        );

        this._changeDetectorRef.markForCheck();

        this._changeDetectorRef.detectChanges();
      });

    this.newsToApprove$ = this._store.select(getNumberOfWaitingForApprovals());
    this.ownRejectedNewsPosts$ = this._store.select(
      getNumberOfOwnRejectedNewsPosts()
    );

    this._refreshNewsPostsSub = this._store
      .select(GetRefreshState)
      .subscribe((refresh) => {
        if (refresh) {
          this.setNewsPosts();
          this._store.dispatch(RefreshState({ refresh: false }));
        }
      });

    this._deviceService.activateDeviceStatus();
  }

  private setNewsPosts() {
    this._killFilterSubscription.next(false);
    this._store
      .select(GetUserFederalStatesPreferencesForDashboard)
      .pipe(takeUntil(this._killFilterSubscription))
      .subscribe((federalStates) => {
        if (federalStates) {
          this._killFilterSubscription.next(true);

          this.viewHelper = this._viewHelperService.getViewHelper(
            "dashboard_news" +
              base64UrlEncode(
                JSON.stringify(
                  this.GenerateViewFilterForDashboardNews(federalStates)
                )
              )
          );

          this.viewHelper.setView("global_search", "query");
          this.viewHelper.setFilter(
            this.GenerateViewFilterForDashboardNews(federalStates)
          );
          this.viewHelper.setInclude([
            "field_federal_state",
            "field_last_rejected_user",
          ]);
          if (this.newsPostSubscription)
            this.newsPostSubscription.unsubscribe();

          this.viewHelper.setActive(false);

          this.newsPostSubscription = this.viewHelper
            .getView$(selectNewsPostByIds)
            .subscribe((newsPosts) => {
              this.newsPosts = newsPosts;

              this._changeDetectorRef.detectChanges();
              this._changeDetectorRef.markForCheck();
            });

          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setNewsPosts();
  }

  override ngOnDestroy(): void {
    if (this._newsPostsFilterSub) {
      this._newsPostsFilterSub.unsubscribe();
    }

    if (this._appActiveSubscription) {
      this._appActiveSubscription.unsubscribe();
    }

    if (this._approvalCountSubscription) {
      this._approvalCountSubscription.unsubscribe();
    }

    if (this.newsPostSubscription) {
      this.newsPostSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  public SetEvent(
    event: any,
    routeType: DashboardRoutesType,
    id?: string,
    param?: any
  ) {
    // first check which button action type
    switch (event.type) {
      case ButtonActionsType.Click:
        // navigate to url based on route type and add id if existing
        if (
          routeType !== "app/event" ||
          (routeType === "app/event" && Capacitor.getPlatform() === "web")
        ) {
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            BasePageAnimationDirection.LEFT,
            param
          );
        } else {
          this._store
            .select(GetEventUrl)
            .pipe(take(1))
            .subscribe((url) => {
              if (url) {
                Browser.open({ url: url, presentationStyle: "popover" });
              }
            });
        }
        break;
    }
  }

  private GenerateViewFilterForDashboardNews(
    federalStates: Array<number> | undefined
  ) {
    let newsPostsFilter = [
      {
        id: ViewType.Bundle,
        value: "news_post",
      },
      {
        id: ViewType.ModerationState,
        value: ModerationSate.PUBLISHED,
      },
    ];

    if (federalStates && federalStates?.length > 0) {
      federalStates?.forEach((f) => {
        newsPostsFilter.push({
          id: ViewType.FederalStates,
          value: f.toString(),
        });
      });
    }

    return newsPostsFilter;
  }

  public track(index: number, item: { id: string }) {
    return item.id;
  }
}
