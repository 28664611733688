import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { ResponseStructureError } from "../../error/types/response-structure-error";
import { EMPTY, of } from "rxjs";
import { createAction, Store } from "@ngrx/store";
import { AppStore } from "../appStore";
import {
  approveOrRejectChamberInfo,
  approveOrRejectChamberInfoError,
  approveOrRejectChamberInfoSuccess,
  fetchApprovalMetaData,
  fetchApprovalMetaDataSuccess,
} from "./approval.actions";
import { ApiConnectorEntityService } from "../../io/entity/api-connector-entity.service";
import { ApiConnectorApprovalContentService } from "../../io/entity/api-connector-approval-content.service";
import Bugsnag from "@bugsnag/js";

@Injectable()
export class ApprovalEffects {
  loadApprovalMetaData = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchApprovalMetaData),
      mergeMap((payload) => {
        return this._approvalService.loadApprovalMetaData().pipe(
          map((response) => {
            try {
              return fetchApprovalMetaDataSuccess({ response: response });
            } catch (e) {
              Bugsnag.notify(`Error parsing approvalMetadata, ${e.message}`);
              throw ResponseStructureError(e);
            }
          }),
          catchError((e) => {
            Bugsnag.notify(`Error loading approvalMetadata, ${e.message}`);
            return EMPTY;
          })
        );
      })
    )
  );

  setApproval$ = createEffect(() =>
    this._actions$.pipe(
      ofType(approveOrRejectChamberInfo),
      mergeMap((initialPayload) => {
        return this._entityService.approveEntity(initialPayload).pipe(
          map((response) => {
            try {
              return approveOrRejectChamberInfoSuccess({
                response: response,
                id: initialPayload.id,
              });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError((error, source$) => {
            Bugsnag.notify(`Error approving chamber info, ${error.message}`);
            return of(
              approveOrRejectChamberInfoError({
                id: initialPayload.id,
                error: error,
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _entityService: ApiConnectorEntityService,
    private _approvalService: ApiConnectorApprovalContentService
  ) {}
}
