import { createAction, props } from "@ngrx/store";
import { APP_INIT_STATES } from "../status";

export const enum StatusActions {
  CHANGE_APP_INIT_STATUS = "CHANGE_APP_INIT_STATUS",
  REFRESH_STATE = "REFRESH_STATE",
}

export const ChangeAppInitStatus = createAction(
  StatusActions.CHANGE_APP_INIT_STATUS,
  props<{ state: APP_INIT_STATES }>()
);

export const RefreshState = createAction(
  StatusActions.REFRESH_STATE,
  props<{ refresh: boolean }>()
);
