export enum UserPreferenceType {
  DashboardFederalStates = "field_dashboard_federal_states",
  EmailNotifications = "field_email_notifications",
  EmailNotificationStates = "field_email_notifications_states",
  PushFederalStates = "field_push_federal_states",
  PushTags = "field_push_tags",
  PushNotifications = "field_push_notifications",
  OnboardingCompleted = "field_onboarding_completed",
  MedifinderFederalStates = "field_medifinder_federal_states",
  MedifinderPushNotifications = "field_medifinder_push_enabled",
}

export enum OnboardingCompletedType {
  Completed = "1",
  NotCompleted = "0",
}

export enum UserRoles {
  ADMIN = "apoapp_admin",
  CLIENT = "apoapp_client",
  EDITOR = "content_editor",
  APPROVER = "content_approver",
  GUIDLINES_EDITOR = "consulting_guidelines_editor",
}
