<div class="container">
    <div class="settings content">

        <div class="row">
            <div class="col-xsmall-12">
                <div class="settings__action-wrapper">
                    <app-icon-button (action)="SetEvents($event)" src="icons/32/close.svg"></app-icon-button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xsmall-12">
                <div class="settings__header">
                    <h1 class="schmuck">{{ fullName$ | async }}</h1>
                    <div class="spacer"></div>
                    <app-button (action)="Logout()" [fullWidth]="true" [buttonType]="ButtonType.Secondary"
                                label="{{ 'PAGES.SETTINGS.BUTTON' | translate }}"></app-button>
                    <div class="spacer-4"></div>
                    <div class="filter">{{'PAGES.SETTINGS.TITLE' | translate}}</div>
                    <div class="line"></div>
                    <div class="spacer"></div>
                    <app-tabs [items]="tabs" (action)="setTab($event)"></app-tabs>
                </div>
            </div>
        </div>

        <!--KammerInfo-Tab-->
        <ng-container *ngIf="activeTab === 'news'">
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="settings__section">

                        <div class="spacer-3"></div>
                        <h2>{{'PAGES.SETTINGS.SECTIONS.DASHBOARD.TITLE' | translate}}</h2>
                        <div class="spacer"></div>
                        <p>{{'PAGES.SETTINGS.SECTIONS.DASHBOARD.TEXT' | translate}}</p>
                        <div class="spacer-and-half"></div>
                        <div class="settings__filter">
                            <app-filter [transformedItems]="federalStates$ | async"
                                        (action)="SetFilter($event, UserPreferenceType.DashboardFederalStates)"></app-filter>
                        </div>
                        <div class="spacer-4"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="settings__section">
                        <div class="line"></div>
                        <div class="spacer-3"></div>
                        <div class="settings__section-header">
                            <div class="settings__section-header--left">
                                <h2>{{'PAGES.SETTINGS.SECTIONS.EMAIL.TITLE' | translate}}</h2>
                                <p class="bold">{{'PAGES.SETTINGS.SECTIONS.EMAIL.SUBTITLE' | translate}}</p>
                            </div>
                            <div class="settings__section-header--right">

                                <app-input-checkbox *ngIf="federalStateEmail$ | async as federalStatesEmail" [checked]="emailNotifications$ | async"
                                                    (action)="SetFilter($event, UserPreferenceType.EmailNotifications, federalStatesEmail)"></app-input-checkbox>
                            </div>
                        </div>
                        <ng-container *ngIf="emailNotifications$ | async">
                        <div class="spacer-2"></div>
                        <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.EMAIL.TEXT' | translate"></p>
                        <div class="spacer-and-half"></div>
                        <div class="settings__filter">
                            <app-filter [transformedItems]="federalStateEmail$ | async"
                                        (action)="SetFilter($event, UserPreferenceType.EmailNotificationStates)"></app-filter>
                        </div>


                        </ng-container>
                        <div class="spacer-2"></div>

                        <i>
                            <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.EMAIL.INFO' | translate"></p>
                        </i>

                        <div class="spacer-4"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="settings__section">
                        <div class="line"></div>
                        <div class="spacer-3"></div>
                        <div class="settings__section-header">
                            <div class="settings__section-header--left">
                                <h2>{{'PAGES.SETTINGS.SECTIONS.PUSH.TITLE' | translate}}</h2>
                                <p class="bold">{{'PAGES.SETTINGS.SECTIONS.PUSH.SUBTITLE' | translate}}</p>
                            </div>
                            <div class="settings__section-header--right">
                                <app-input-checkbox [checked]="pushNotifications$ | async"
                                                    (action)="SetFilter($event, UserPreferenceType.PushNotifications)"></app-input-checkbox>
                            </div>
                        </div>
                        <ng-container *ngIf="pushNotifications$ | async">
                            <div class="spacer-2"></div>
                            <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.PUSH.TEXT' | translate"></p>
                            <div class="spacer-and-half"></div>
                            <div class="settings__filter">
                                <app-filter [transformedItems]="federalStatesPush$ | async"
                                            (action)="SetFilter($event, UserPreferenceType.PushFederalStates)"></app-filter>
                            </div>
                            <div class="spacer-3"></div>
                            <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.PUSH.TAGS' | translate"></p>
                            <div class="spacer-and-half"></div>
                            <div class="settings__filter">
                                <app-filter [transformedItems]="tags$ | async" preLabel="#"
                                            (action)="SetFilter($event, UserPreferenceType.PushTags)"></app-filter>
                            </div>
                        </ng-container>
                        <div class="spacer-4"></div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--Medifinder Tab-->
        <ng-container *ngIf="activeTab === 'medifinder'">
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="settings__section">

                        <div class="spacer-3"></div>
                        <h2>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER.TITLE' | translate}}</h2>
                        <div class="spacer"></div>
                        <p>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER.TEXT' | translate}}</p>
                        <div class="spacer-and-half"></div>
                        <div class="settings__filter">
                            <app-filter [transformedItems]="mediFederalStates$ | async"
                                        (action)="SetFilter($event, UserPreferenceType.MedifinderFederalStates)"></app-filter>
                        </div>
                        <div class="spacer-4"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xsmall-12">
                    <div class="settings__section">
                        <div class="line"></div>
                        <div class="spacer-3"></div>
                        <div class="settings__section-header">
                            <div class="settings__section-header--left">
                                <h2>{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.TITLE' | translate}}</h2>
                                <p class="bold">{{'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.SUBTITLE' | translate}}</p>
                            </div>
                            <div class="settings__section-header--right">
                                <app-input-checkbox [checked]="mediPushNotifications$ | async"
                                                    (action)="SetFilter($event, UserPreferenceType.MedifinderPushNotifications)"></app-input-checkbox>
                            </div>

                        </div>
                        <div>
                            <div class="spacer-2"></div>
                            <p [innerHTML]="'PAGES.SETTINGS.SECTIONS.MEDIFINDER_PUSH.TEXT' | translate"></p>
                        </div>
                        <div class="spacer-4"></div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="environment !== Environments.production">
            <div class="line"></div>
        <br />
        <br />

            <app-button (action)="questionnaireService.reset()" [fullWidth]="true" [buttonType]="ButtonType.Secondary"
                        label="Paperform-Umfrage zurücksetzen"></app-button>
        <br />
        <br />
        </ng-container>


        <div class="version" (click)="debug()">
            {{version}}
        </div>
        <div class="spacer-4"></div>
    </div>
</div>
