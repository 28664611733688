import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import KeenSlider, { KeenSliderInstance } from "keen-slider";
import { ButtonType } from "../../ui/button/button.type";
import { Router } from "@angular/router";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import {
  GetUserFederalStatesSettingForDashboard,
  GetUserSettingForEmailNotification,
} from "../../store/user/user.selector";
import { Store } from "@ngrx/store";
import { ButtonOutput } from "../../ui/button/button-output";
import {
  cancelSetUserPreferences,
  setUserPreferences,
  toggleEmailNotifications,
} from "../../store/user/user.actions";
import { UserPreferenceType } from "../../store/user/user.type";
import { OnboardingActionsType } from "./onboarding-actions.type";
import { loadOnboardingScreens } from "../../store/onboarding/onboarding.actions";
import {
  GetOnboardingScreens,
  GetOnboardingScreensLoadingStatus,
} from "../../store/onboarding/onboarding.selector";
import { APP_INIT_STATES } from "../../store/status/status";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { PushNotificationService } from "../../io/push-notification.service";
import { map } from "rxjs/operators";
import { BehaviorSubject, take } from "rxjs";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: [
    "../../../../node_modules/keen-slider/keen-slider.min.css",
    "./onboarding.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
})
export class OnboardingComponent
  extends AnimationBaseComponent
  implements OnDestroy
{
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>;

  private _slide = 0;
  public dotHelper: Array<Number> = [];
  // @ts-ignore
  public slider: KeenSliderInstance = null;
  public federalStates$;

  public sliderContent$;
  public elementsLoaded: boolean = false;
  private readonly _sliderLoadingStatusSub: any;
  private federalStateSub: any;

  public federalStatesSelected: Boolean = false;
  public selectedFederalStates: Array<string> = [];
  private _currentSlide: BehaviorSubject<any> = new BehaviorSubject<any>(
    this._slide
  );
  public currentSlide$ = this._currentSlide.asObservable();
  private dragEnabled: boolean = true;
  private slideIndexOfFederalStateConfiguration: number = 2;

  public buttonType = ButtonType;
  public OnboardingActionsType = OnboardingActionsType;

  constructor(
    private _router: Router,
    private _store: Store,
    protected override _navigationService: NavigationService,
    private _pushNotificationService: PushNotificationService
  ) {
    super(_navigationService);

    // check if screens already loaded
    this._sliderLoadingStatusSub = this._store
      .select(GetOnboardingScreensLoadingStatus)
      .subscribe((status) => {
        switch (status) {
          // if not already loaded or loading then load
          case APP_INIT_STATES.INIT:
            this._store.dispatch(loadOnboardingScreens());
            break;
          // if already loaded then init slider
          case APP_INIT_STATES.SUCCESS:
            setTimeout(() => {
              this.initSlider();

              if (this._sliderLoadingStatusSub) {
                this._sliderLoadingStatusSub.unsubscribe();
              }
            }, 250);
            break;
        }
      });

    this.sliderContent$ = this._store.select(GetOnboardingScreens);
    this.federalStates$ = this._store.select(
      GetUserFederalStatesSettingForDashboard()
    );

    this.federalStateSub = this.federalStates$
      .pipe(map((federalStates) => federalStates))
      .subscribe((f) => {
        this.saveSelectedFederalStates(f);

        if (this.federalStatesSelected) {
          if (!this.dragEnabled) {
            this.slider.update(
              {
                drag: true,
                slideChanged: (s) => {
                  this.currentSlide = s.track.details.rel;
                },
              },
              this.currentSlide
            );
          }
          this.dragEnabled = true;
        }
      });

    // check if currently on federal states slide and if configured, if not then disable slider
    this.currentSlide$.subscribe((cSlide) => {
      if (
        cSlide == this.slideIndexOfFederalStateConfiguration &&
        !this.federalStatesSelected
      ) {
        this.dragEnabled = false;
        this.slider.update(
          {
            drag: false,
          },
          this.currentSlide
        );
      }
    });
  }

  public saveSelectedFederalStates(states: any) {
    // check if federal states are selected, if so user is allowed to go to next slide, enable slider again
    this.federalStatesSelected =
      states.filter((filter: any) => filter.active).map((f: any) => f.id)
        .length !== 0;

    this.selectedFederalStates = states
      .filter((filter: any) => filter.active)
      .map((f: any) => f.id);
  }

  set currentSlide(value) {
    this._slide = value;
    this._currentSlide.next(this._slide);
  }

  get currentSlide() {
    return this._slide;
  }

  public navigateToDashboard() {
    this._store.dispatch(
      setUserPreferences({
        id: UserPreferenceType.OnboardingCompleted,
        value: true,
      })
    );
    if (this._sliderLoadingStatusSub) {
      this._sliderLoadingStatusSub.unsubscribe();
    }
    this._navigationService.navigateByUrl(
      DashboardRoutesType.Dashboard,
      BasePageAnimationDirection.DOWN
    );

    //on exit, check if the user is already subscribed to emails. if not, auto-subscribe him
    this.subscribeToEmail();
  }

  public SetPreference(event: ButtonOutput, type: OnboardingActionsType) {
    switch (type) {
      case OnboardingActionsType.DashboardFederalStates:
        this._store.dispatch(cancelSetUserPreferences());
        this._store.dispatch(
          setUserPreferences({
            id: UserPreferenceType.DashboardFederalStates,
            value: event.data
              .filter((filter: any) => filter.active)
              .map((f: any) => f.id),
          })
        );
        this.saveSelectedFederalStates(event.data);

        break;
      case OnboardingActionsType.PushNotificationAccept:
        this._pushNotificationService.requestPermissions();
        // go to next slide
        this.HandleSliderNavigation(this.currentSlide + 1);
        break;
      case OnboardingActionsType.PushNotificationDecline:
        this.HandleSliderNavigation(this.currentSlide + 1);
        break;
    }
  }

  /**
   * check if the user is already subscribed to emails. If the return value is undefined (not false), the user is not subscribed yet
   * Subscribe to the same topics as the selected federal states
   * @private
   */
  private subscribeToEmail() {
    this._store
      .select(GetUserSettingForEmailNotification(true))
      .pipe(take(1))
      .subscribe((emailNotification) => {
        //explicitly check for undefined. if the user unsubscribed already, the value is false and the user shouldn't be subscribed again
        if (typeof emailNotification === "undefined") {
          this._store.dispatch(
            toggleEmailNotifications({
              value: {
                subscribed_federal_states: this.selectedFederalStates,
                status: "1",
              },
            })
          );
        }
      });
  }

  private initSlider() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
    });

    try {
      // check if DOM is already build and only if show slider -> without check slider looks horrible for a few seconds
      if (this.slider.track.details.slides.length) {
        this.elementsLoaded = true;
      }

      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ];
    } catch (e) {
      //slider not yet ready
    }
  }

  public HandleSliderNavigation(index: number, sliderContent?: Array<any>) {
    if (sliderContent) {
      if (
        index <=
          sliderContent.findIndex(
            (slide) => slide.id === "federal_states_configuration"
          ) ||
        this.federalStatesSelected
      ) {
        this.slider.moveToIdx(index);
        this.currentSlide = index;
      }
    } else {
      this.slider.moveToIdx(index);
      this.currentSlide = index;
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    setTimeout(() => {
      if (this.slider) this.slider.destroy();
    }, 500);

    try {
      this.federalStateSub.unsubscribe();
      this._sliderLoadingStatusSub.unsubscribe();
    } catch (e) {}
  }
}
