<div class="modal__background" (click)="Close()"></div>
<div class="modal" @enterLeave *ngIf="open">
    <div class="modal__header">
        <p class="header-title">{{ 'MODAL.INFO_FILTER.TITLE' | translate }}</p>
        <app-icon-button (action)="EmitFilter()" class="icon-button" src="icons/32/check.svg"></app-icon-button>
    </div>
    <div class="modal__content">
        <p class="label-form">{{'MODAL.INFO_FILTER.DATE.TITLE' | translate}}</p>
        <div class="spacer-and-half"></div>
        <div class="modal__date-wrapper">
            <app-input-date [max]="fromMax" [initValue]="fromInputValue" (currentValue)="SetInputValue($event, InputType.From)" label="{{'MODAL.INFO_FILTER.DATE.FROM' | translate}}" caption="{{'STRINGS.DATE_FORMAT' | translate}}"></app-input-date>
            <app-input-date [max]="today" [initValue]="toInputValue" (currentValue)="SetInputValue($event, InputType.To)" label="{{'MODAL.INFO_FILTER.DATE.TO' | translate}}" caption="{{'STRINGS.DATE_FORMAT' | translate}}"></app-input-date>
        </div>
        <div (click)="reset()" [ngClass]="{visible: toInputValue || fromInputValue}" class="reset">{{'MODAL.INFO_FILTER.DATE.RESET' | translate}}</div>


        <!-- new section state-->
        <div class="line"></div>
        <div class="spacer-3"></div>
        <p class="label-form">{{'MODAL.INFO_FILTER.STATE.TITLE' | translate}}</p>
        <div class="spacer-and-half"></div>
        <div class="modal__filter">
            <ng-container *ngIf="federalStates$ | async as federalStates; else loading">
                <app-filter [transformedItems]="federalStates" [initActiveItems]="activeFederalStates" (action)="SetFilter($event, InputType.FederalStates)"></app-filter>
            </ng-container>
        </div>
        <div class="spacer-3"></div>

        <!-- new section tags-->
        <div class="line"></div>
        <div class="spacer-3"></div>
        <p class="label-form">{{'MODAL.INFO_FILTER.TAGS.TITLE' | translate}}</p>
        <div class="spacer-and-half"></div>
        <div class="modal__filter">
            <ng-container *ngIf="tags$ | async as tags; else loading">
                <app-filter [transformedItems]="tags" [initActiveItems]="activeTags" preLabel="#" (action)="SetFilter($event, InputType.Tags)"></app-filter>
<!--                <app-filter [items]="tags" [initActiveItems]="activeTags" preLabel="#" (action)="SetFilter($event, InputType.Tags)"></app-filter>-->
            </ng-container>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
