import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  KeyValueChangeRecord,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChange,
  ViewEncapsulation
} from "@angular/core";
import { Subscription } from "rxjs";
import { SvgIconService } from "./svg-icon.service";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [SvgIconService]
})
export class SvgIconComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  @Input() src: string = "";
  @Input() module: string = "core";
  @Input() stretch = false;

  // Adapted from ngStyle
  @Input()
  set svgStyle(v: { [key: string]: string }) {
    this._svgStyle = v;
    if (!this.differ && v) {
      this.differ = this.differs.find(v).create();
    }
  }

  private svg: SVGElement | undefined;
  private icnSub: Subscription | undefined;
  private differ: KeyValueDiffer<string, string | number> | undefined;
  private _svgStyle: { [key: string]: string } = {};

  constructor(
    private element: ElementRef,
    private differs: KeyValueDiffers,
    private renderer: Renderer2,
    private iconReg: SvgIconService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngOnChanges(changeRecord: { [key: string]: SimpleChange }) {
    if (changeRecord["src"]) {
      if (this.svg) {
        this.destroy();
      }
      this.init();
    }
    if (changeRecord["stretch"]) {
      this.stylize();
    }
  }

  ngDoCheck() {
    if (this.svg && this.differ) {
      const changes = this.differ.diff(this._svgStyle);
      if (changes) {
        this.applyChanges(changes);
      }
    }
  }

  private init() {
    this.icnSub = this.iconReg.loadSvg(this.src, this.module).subscribe(svg => {
      this.setSvg(svg);
      this.resetDiffer();
    });
  }

  private destroy() {
    this.svg = undefined;
    this.differ = undefined;
    if (this.icnSub) {
      this.icnSub.unsubscribe();
    }
  }

  private resetDiffer() {
    if (this._svgStyle && !this.differ) {
      this.differ = this.differs.find(this._svgStyle).create();
    }
  }

  private setSvg(svg: SVGElement) {
    if (svg) {
      this.svg = svg;
      const icon = <SVGElement>svg.cloneNode(true);
      const elem = this.element.nativeElement;
      if (this._svgStyle["width.px"]) {
        elem.style["width"] = this._svgStyle["width.px"] + "px";
      }
      if (this._svgStyle["height.px"]) {
        elem.style["height"] = this._svgStyle["height.px"] + "px";
      }
      elem.innerHTML = "";
      this.renderer.appendChild(elem, icon);

      this.stylize();
    }
  }

  private stylize() {
    if (this.svg) {
      const svg = this.element.nativeElement.firstChild;

      if (this.stretch === true) {
        this.renderer.setAttribute(svg, "preserveAspectRatio", "none");
      } else if (this.stretch === false) {
        this.renderer.removeAttribute(svg, "preserveAspectRatio");
      }
    }
  }

  private applyChanges(changes: KeyValueChanges<string, string | number>) {
    changes.forEachRemovedItem(
      (record: KeyValueChangeRecord<string, string | number>) =>
        this.setStyle(record.key, null)
    );
    changes.forEachAddedItem(
      (record: KeyValueChangeRecord<string, string | number>) =>
        this.setStyle(record.key, record.currentValue)
    );
    changes.forEachChangedItem(
      (record: KeyValueChangeRecord<string, string | number>) =>
        this.setStyle(record.key, record.currentValue)
    );
  }

  private setStyle(
    nameAndUnit: string,
    value: string | number | null | undefined
  ) {
    const [name, unit] = nameAndUnit.split(".");
    value = value !== null && unit ? `${value}${unit}` : value;
    const svg = this.element.nativeElement.firstChild;

    if (value !== null) {
      this.renderer.setStyle(svg, name, value as string);
    } else {
      this.renderer.removeStyle(svg, name);
    }
  }
}
