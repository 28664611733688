import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import {
  coreConfig,
  coreConfigDevelop,
  coreConfigLocalCms,
  coreConfigProdCms,
  coreConfigProduction,
  coreConfigRc,
  coreConfigStaging,
} from "./core-config";
import { CoreConfig } from "./core-config.type";
import { Environments } from "../../environments/environments";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private _config = this.setConfig();

  get config(): CoreConfig {
    return this._config;
  }

  set config(value: CoreConfig) {
    this._config = value;
  }

  constructor() {}

  /**
   * overwrites the current config with the correct runtime config (testing | production)
   * @private
   */
  public setConfig(env = environment.deployment) {
    let overwrite = {};

    switch (env) {
      case Environments.production:
        overwrite = coreConfigProduction;
        break;
      case Environments.rc:
        overwrite = coreConfigRc;
        break;
      case Environments.staging:
        overwrite = coreConfigStaging;
        break;
      case Environments.develop:
        overwrite = coreConfigDevelop;
        break;
      case Environments.localcms:
        overwrite = coreConfigLocalCms;
        break;
      case Environments.prodcms:
        overwrite = coreConfigProdCms;
        break;
    }

    return { ...coreConfig, ...overwrite };
  }
}
