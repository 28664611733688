export function base64UrlEncode(input: string) {
  try {
    input = window.btoa(input);
    input = input.replaceAll("+", ".");
    input = input.replaceAll("/", "_");
    input = input.replaceAll("=", "-");
    return input;
  } catch (e) {
    return input;
  }
}

export function base64UrlDecode(input: string) {
  try {
    input = input.replaceAll(".", "+");
    input = input.replaceAll("_", "/");
    input = input.replaceAll("-", "=");
    input = window.atob(input);
    return input;
  } catch (e) {
    return input;
  }
}

export function hash(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function transformPhoneNumber(phone: string) {
  try {
    let tmp = phone.replaceAll(" ", "");
    tmp = tmp.replaceAll("-", "");
    return tmp;
  } catch (e) {
    return phone;
  }
}
