import { ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import {
  EmailDataStates,
  EmailSelfServiceComponent,
} from "../email-self-service/email-self-service.component";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../store/status/auth/auth.service";
import { ConfigService } from "../../config/config.service";
import { ApiEmailConnectorService } from "../../store/email/api-email-connector.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { UiModule } from "../../ui/ui.module";
import { BackHeaderImageType } from "../../ui/header/back-header/back-header.type";
import { ButtonType } from "../../ui/button/button.type";

@Component({
  selector: "app-email-data-confirm",
  standalone: true,
  imports: [FormsModule, NgIf, ReactiveFormsModule, TranslateModule, UiModule],
  templateUrl: "./email-data-confirm.component.html",
  styleUrls: [
    "./email-data-confirm.component.scss",
    "../email-self-service/email-self-service.component.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EmailDataConfirmComponent extends EmailSelfServiceComponent {
  constructor(
    private _http: HttpClient,
    private _routeSnapshot: ActivatedRoute,
    protected override _authService: AuthService,
    protected override _changeDetectorRef: ChangeDetectorRef,
    private _configService: ConfigService,
    protected override _router: Router,
    protected override _apiEmailService: ApiEmailConnectorService
  ) {
    super(_authService, _changeDetectorRef, _router, _apiEmailService);

    this.state = EmailDataStates.CONFIRMATION;
    this.confirmEmail();
  }

  private confirmEmail() {
    this._http
      .patch(
        this._configService.config.cms.url +
          "/api/self-service/email-recipient/register/confirm",

        this._routeSnapshot.snapshot.queryParams
      )
      .subscribe({
        next: (response) => {
          this.state = EmailDataStates.CONFIRMATION_SUCCESS;
        },
        error: (error) => {
          console.log(error);
          this.state = EmailDataStates.ERROR;
          this.errorMessage = `<br /><b>${error.message}</b><br />${error.error.message}`;
        },
      });
  }

  protected readonly BackHeaderImageType = BackHeaderImageType;
  protected readonly EmailDataStates = EmailDataStates;
  protected readonly ButtonType = ButtonType;
}
