import { ModerationSate, NewsPost } from "../news-post/news-post";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./approval.reducer";
import { selectNewsPostById } from "../news-post/news-post.selector";
import { WaitingForApprovalNewsPost } from "./approval";
import { TabItem } from "../../ui/tabs/tabs";
import { clone } from "lodash-es";

export const GetApproval = createFeatureSelector<State>("approval");
export const GetApprovalPosts = createSelector(
  GetApproval,
  (approval: State) => {
    return approval.waitingForApprovalPosts;
  }
);
export const GetDeleteFromCacheList = createSelector(
  GetApproval,
  (approval: State) => {
    return approval.deleteFromCache;
  }
);
export const GetTabAccessObj = createSelector(
  GetApproval,
  (approval: State) => {
    return approval.tabAccess;
  }
);

export const getActionUrisFromApprovalPost = (id: string) =>
  createSelector(
    GetApprovalPosts,
    selectNewsPostById(id),
    (
      posts: Array<WaitingForApprovalNewsPost> | undefined,
      entity: NewsPost | undefined
    ) => {
      if (entity !== undefined && posts !== undefined) {
        return posts.find((post) => post.entity.entity_uuid === entity.id)
          ?.actions;
      } else {
        return undefined;
      }
    }
  );

export const getModerationStatusById = (id: string) =>
  createSelector(GetApprovalPosts, (posts) => {
    let status = "";
    if (posts && posts.length > 0) {
      let post = posts.find((post) => post.entity?.entity_uuid === id);
      status = post ? (post.currentStatus ? post.currentStatus : "") : "";
    }

    return status !== undefined ? status : "";
  });

export const getNextInfoForApproval = (id: string) =>
  createSelector(GetApprovalPosts, (posts) => {
    let index = posts.findIndex((post) => post.entity.entity_uuid === id);
    if (index === -1) {
      return undefined;
    }

    if (posts.length - 1 > index) {
      return posts[++index];
    } else {
      if (index > 0) {
        return posts[--index];
      }
      return undefined;
    }
  });

export const getNumberOfWaitingForApprovals = () =>
  createSelector(GetApprovalPosts, (posts) => {
    return clone({ count: posts.length });
  });

export const getInfoOverviewTabs = () =>
  createSelector(GetTabAccessObj, (tabAccess: {} | undefined) => {
    let tabs: Array<TabItem> = [];
    if (tabAccess) {
      for (const [key, value] of Object.entries(tabAccess)) {
        if (value === true) {
          let tab = allTabs.find((tab) => tab.value === key);
          if (tab) {
            tabs.push(tab);
          }
        }
      }
    }

    tabs.sort((a, b) => {
      return a.weight <= b.weight ? -1 : 1;
    });

    return tabs;
  });

export const allTabs = [
  {
    label: "Alle",
    active: false,
    value: ModerationSate.PUBLISHED,
    weight: 10,
  },
  {
    label: "Freigabe von mir erforderlich",
    active: false,
    value: ModerationSate.WAITING_FOR_APPROVAL,
    weight: 20,
  },
  {
    label: "Meine Veröffentlichten",
    active: false,
    value: ModerationSate.OWN_PUBLISHED,
    weight: 30,
  },
  {
    label: "Meine Entwürfe",
    active: false,
    value: ModerationSate.DRAFT,
    weight: 40,
  },
  {
    label: "Warten auf Freigabe",
    active: false,
    value: ModerationSate.OWN_WAITING_FOR_APPROVAL,
    weight: 50,
  },
  {
    label: "Abgelehnt",
    active: false,
    value: ModerationSate.OWN_REJECTED,
    weight: 21,
  },
];
