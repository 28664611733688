export enum BasePageAnimationDirection {
    ZOOM_IN="zoom-in",
    ZOOM_OUT= "zoom-out",
    UP= "up",
    DOWN="down",
    FADE = "fade",
    LEFT= "left",
    RIGHT = "right",
    LEFT_SPECIAL = "leftWithScale",
    RIGHT_SPECIAL = "rightWithScale"
}
