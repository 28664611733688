import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../button/button-output";
import { ButtonActionsType } from "../button/button-actions.type";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-profile-icon",
  templateUrl: "./profile-icon.component.html",
  styleUrls: ["./profile-icon.component.scss"],
})
export class ProfileIconComponent {
  /**
   * Initials that should be displayed on the profile indicator
   */
  @Input() initials = "";

  @HostBinding("class") get hostClasses() {
    const classList = ["profile-icon"];
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
