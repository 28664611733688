import { Pipe, PipeTransform } from "@angular/core";
import { ConfigService } from "../config/config.service";

@Pipe({
  name: "embeddedContent",
})
export class EmbeddedContentPipe implements PipeTransform {
  private _cmsUrl;

  constructor(private _configService: ConfigService) {
    this._cmsUrl = this._configService.config.cms.url;
  }

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) return value;
    return this.addBaseURL(value);
  }

  addBaseURL(value: string) {
    const re = /\/sites\/default\/files/g;

    return value.replace(re, this._cmsUrl + "/sites/default/files");
  }
}
