import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { EntityService } from "../../../io/entity/entity.service";
import { EntityTypesId } from "../../../io/entity/types/entityTypes";
import { selectEntitiesByType } from "../../../io/entity/store/entity.selector";
import { CacheRefresh } from "../../../io/entity/types/cache";

@Component({
  selector: "app-federal-state",
  templateUrl: "./federal-state.component.html",
  styleUrls: ["./federal-state.component.scss"],
})
export class FederalStateComponent implements OnInit, OnChanges {
  /**
   * Array of ids for federal-state entities
   */
  @Input() ids: Array<string>;

  public federalStates$: any;

  constructor(
    private _entityService: EntityService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setFederalStates();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setFederalStates();
  }

  private setFederalStates() {
    this.federalStates$ = this._entityService.getEntities(
      EntityTypesId.FederalState,
      this.ids,
      selectEntitiesByType,
      CacheRefresh.one_hour
    );

    this._changeDetectorRef.detectChanges();
  }
}
