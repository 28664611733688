import { createSelector } from "@ngrx/store";
import { selectEntity } from "../../io/entity/store/entity.selector";
import cloneDeep from "lodash-es/cloneDeep";
import { MediaDocument } from "./media-document";

export const selectMediaDocument = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    if (!entity) return entity;

    return transformEntityToMediaDocument(
      entity as MediaDocument,
      entity.relationships.field_media_document.data.attributes.uri.url
    );
  });

export const selectMediaImageThumbnail = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    if (!entity) return entity;

    return transformEntityToMediaDocument(
      entity as MediaDocument,
      entity.relationships.thumbnail.data.attributes.uri.url
    );
  });

function transformEntityToMediaDocument(
  entity: MediaDocument | undefined,
  url: string
): MediaDocument | undefined {
  if (!entity) return entity;

  const document = cloneDeep(entity);

  document.attributes.url = url;

  return document;
}
