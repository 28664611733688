import { Injectable } from "@angular/core";
import { ApiConnectorService } from "../api-connector.service";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ApiEntityResponse } from "./types/api-entity-response";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorApprovalContentService extends ApiConnectorService {
  constructor(
    public configService: ConfigService,
    public http: HttpClient,
    private _store: Store
  ) {
    super(configService);
  }

  public loadApprovalMetaData() {
    return this.http.get(
      this._configService.config.cms.url + `/api/apo-content-approval/infos`,
      {}
    ) as Observable<ApiEntityResponse>;
  }
}
