import { Injectable } from "@angular/core";
import { CacheRefresh } from "./types/cache";
import { Store } from "@ngrx/store";
import { GetDeleteFromCacheList } from "../../store/approval/approval.selector";
import { AppStore } from "../../store/appStore";

import { Actions, ofType } from "@ngrx/effects";

export interface CacheStructure {
  timestamp: number;
  data?: any;
  intermediate?: boolean; //true while data is being loaded
  page: number;
  perPageData: { [key: number]: any };
}

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private _cache: { [key: string]: CacheStructure };

  get cache(): any {
    return this._cache;
  }

  constructor(private _store: Store<AppStore>, private _actions$: Actions) {
    this._cache = {};

    // this listens to deleteFromCache-List in approval store.
    // when approved or rejected, an entity gets deleted from entity-store
    // but remains in cache, which leads to still displaying entitiy
    // therefore we have to delete it from cache
    this._store.select(GetDeleteFromCacheList).subscribe((ids: string[]) => {
      if (ids && ids.length > 0) {
        this.deleteCachedEntityFromView(ids);
      }
    });

    this._actions$.pipe(ofType("[Entity] purgeEntities")).subscribe((res) => {
      this.purgeCache();
    });
  }

  /**
   * adds a resource to the cache
   * @param resource
   * @param data
   * @param intermediate
   */
  public addToCache(
    resource: string,
    data?: any,
    intermediate: boolean = false
  ) {
    if (!data && !intermediate) return;

    const cleanedURL = this.cleanupURL(resource);

    const cached = this.retrieveFromCache(resource).cache;

    const perPageData = cached && cached.perPageData ? cached.perPageData : {};
    perPageData[cleanedURL.page] = data;

    let dt;
    if (Array.isArray(data)) {
      dt = [];

      for (const [key, value] of Object.entries(perPageData)) {
        dt.push(...value);
      }
    } else {
      dt = data;
    }

    this._cache[cleanedURL.url] = {
      timestamp: new Date().getTime(),
      data: dt,
      intermediate: intermediate,
      page: cleanedURL.page ? cleanedURL.page : 0,
      perPageData: perPageData,
    };
  }

  public retrieveFromCache(resource: string): {
    cache: CacheStructure | undefined;
    requestedPageInCache: boolean;
  } {
    const cleanedURL = this.cleanupURL(resource);

    const cached = this._cache[cleanedURL.url];

    let requestedPageInCache = false;
    if (cached) {
      requestedPageInCache = cached.page >= cleanedURL.page;
    }

    return {
      cache: this._cache[cleanedURL.url],
      requestedPageInCache: requestedPageInCache,
    };
  }

  /**
   * checks the cache for the resource and decides if true (resource should be loaded) or false (should not be loaded)
   * @param resource
   * @param cachePolicy
   * @param pageLoaded
   */
  public shouldLoad(
    resource: string,
    cachePolicy: CacheRefresh,
    pageLoaded: boolean = true
  ) {
    if (!pageLoaded) return true;

    const time = this.retrieveFromCache(resource).cache?.timestamp;

    if (!time) return true;

    switch (cachePolicy) {
      case CacheRefresh.always:
        return true;
      case CacheRefresh.never:
        return false;
      case CacheRefresh.one_minute:
        return time <= Date.now() - 1000 * 60;
      case CacheRefresh.ten_seconds:
        return time <= Date.now() - 1000 * 10;
      case CacheRefresh.ten_minutes:
        return time <= Date.now() - 1000 * 60 * 10;
      case CacheRefresh.one_hour:
        return time <= Date.now() - 1000 * 60 * 60;
    }

    return false;
  }

  public cleanupURL(url: string): { url: string; page: number } {
    let cleanedURL = url.replace(/&page=(?<page>[0-9]+)/gm, "");
    let page = new RegExp(/&page=(?<page>[0-9]+)/gm).exec(url);
    return {
      url: cleanedURL,
      page:
        page && page.groups && page.groups["page"]
          ? parseInt(page.groups["page"])
          : 0,
    };
  }

  public deleteCachedEntityFromView(ids: Array<string>) {
    if (ids) {
      ids.forEach((id) => {
        for (const [key, value] of Object.entries(this._cache)) {
          if (value && value.data && Array.isArray(value.data)) {
            let index = value.data.findIndex((el: string) => el === id);
            if (index >= 0) {
              value.data.splice(index, 1);
            }
          }
        }
      });
    }
  }

  public deleteViewFromCache(resource: string) {
    const cleanedURL = this.cleanupURL(resource);

    delete this._cache[cleanedURL.url];
  }

  public purgeCache() {
    this._cache = {};
  }
}
