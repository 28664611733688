import { Injectable } from "@angular/core";
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { first, NEVER, of, retryWhen, throwError, timer } from "rxjs";
import { CoreConfig } from "../../../config/core-config.type";
import { ConfigService } from "../../../config/config.service";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { mergeMap, tap } from "rxjs/operators";
import { Capacitor } from "@capacitor/core";
import { noAuthURIs } from "./no-auth-uris";
import { AccessService } from "../../../services/access.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private _config: CoreConfig;

  private retryMaxAttempts = 2;
  private retryDelay = 500;

  private noAuthResources = noAuthURIs;

  constructor(
    private _configService: ConfigService,
    private _authService: AuthService,
    private _router: Router,
    private _accessService: AccessService
  ) {
    this._config = this._configService.config;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    //first check if the request is for a resource that does not require authentication
    if (
      this.noAuthResources.find((e) => {
        return request.url.includes(e);
      })
    ) {
      return next.handle(request);
    }

    //if the user is blacklisted, don't allow them to send a request
    if (this._accessService.isBlacklisted()) {
      return NEVER;
    }

    //if we are running in a web browser, we don't need to do anything because keycloak is handling it
    if (Capacitor.getPlatform() === "web") {
      return next.handle(request);
    }

    //otherwise, we need to check if we are authenticated
    return this._authService.isAuthenticated().pipe(
      first(),
      mergeMap((result) => {
        if (result) {
          return next
            .handle(this.modifyRequest(request, this._authService.accessToken))
            .pipe(this.retryAfterDelay());
        } else {
          return NEVER;
        }
      })
    );
  }

  retryAfterDelay(): any {
    // needs testing, but should be the
    // return retry({ delay: (errors) => {
    //     return errors.pipe(
    //         mergeMap((err, count) => {
    //           // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
    //           if (count === this.retryMaxAttempts) {
    //             return throwError(err);
    //           }
    //           return of(err).pipe(
    //             tap((error) =>
    //               console.log(`Retrying ${error.url}. Retry count ${count + 1}`)
    //             ),
    //             mergeMap(() => timer(this.retryDelay))
    //           );
    //         })
    //       );
    //   }
    // })
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          if (err.status === 403 && count === 0) {
            this._accessService.loadAndProcessAccessList(true);
          }

          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === this.retryMaxAttempts) {
            return throwError(err);
          }
          return of(err).pipe(
            tap((error) =>
              console.log(`Retrying ${error.url}. Retry count ${count + 1}`)
            ),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }

  private modifyRequest(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
