import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TabItem } from "./tabs";
import { ButtonOutput } from "../button/button-output";
import { ButtonActionsType } from "../button/button-actions.type";
import { ModerationSate } from "../../store/news-post/news-post";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * Tab items that tab wrapper should display
   */
  @Input() items: TabItem[] = [];

  /**
   * Active tab item
   */
  @Input() activeItem: ModerationSate;

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostBinding("class") get hostClasses() {
    const classList = ["tabs"];
    if (this.items.length === 2) {
      classList.push("tabs--two-items");
    }
    return classList;
  }

  constructor() {}

  ngOnInit() {
    this.SetInitialActiveState();
    setTimeout(() => {
      this.MakeTabsDraggable();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["items"] && changes["items"].currentValue.length) {
      this.SetInitialActiveState();
      if (this.activeItem) {
        let index = this.items.findIndex(
          (item) => item.value === this.activeItem
        );
        if (index >= 0) {
          this.SetActiveState(undefined, index);
        }
      }
    }

    if (
      changes &&
      changes["activeItem"] &&
      changes["activeItem"].currentValue
    ) {
      this.items.forEach((item) => {
        item.active = item.value === this.activeItem;
      });
    }
  }

  public SetInitialActiveState() {
    // check active states of items and set first one active if all are inactive
    let activeItems = this.items.filter((item) => item.active);
    if (activeItems.length === 0) {
      if (this.items.length) {
        this.items[0].active = true;
      }
    } else {
      // @ToDo set all items inactive except first active one
    }
  }

  public SetActiveState(event?: ButtonOutput, index?: number) {
    // check if clicked item is current active one, if so then do nothing
    if (index === undefined || index === null) {
      return;
    }
    if (this.items.findIndex((item) => item.active) !== index) {
      this.items[this.items.findIndex((item) => item.active)].active = false;
      this.items[index].active = true;

      // vertical scroll to clicked tab item
      const el = document.getElementById("tab-item--" + index);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
      if (event?.type !== undefined) {
        this.action.emit({
          type: ButtonActionsType.Tab,
          data: this.items[index].value,
        });
      }
    }
  }

  ngAfterViewInit() {
    const el = document.getElementsByClassName("app-tab-item.active")[0];
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }

  public MakeTabsDraggable() {
    const slider = document.querySelector(".tabs");
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    if (slider) {
      slider.addEventListener("mousedown", (e: any) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.clientLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.clientLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }
}
