<div class="container medifinder-detail" appSwipe [allowDirections]="{
left: viewHelperPrevNext.prev,
right: viewHelperPrevNext.next
}" (leftSwipePosition)="leftSwipePosition = $event"
     [ngStyle]="{'left.px': leftSwipePosition }" (leftSwipe)="swipeBack(viewHelperPrevNext.prevId)"
     (rightSwipe)="swipeNext(viewHelperPrevNext.nextId)">

    <app-back-header (action)="SetAction($event, backToTab)" [ngStyle]="{'transform': 'translateX(' + leftSwipePosition + 'px)' }"
                     [detailView]="true" title="{{ 'PAGES.INFO_DETAIL.TITLE' | translate }}"></app-back-header>


    <ng-container *ngIf="(content$ | async) as content">
        <div class="content info-detail__header">
            <div class="spacer spacer-6"></div>

            <div class="caption">
                <span *ngIf="content.attributes.created">{{content.attributes.created | date:dateTimeStandard}} {{ "STRINGS.TIME" | translate}}</span>
            </div>
            <h1 class="bold" [innerHTML]="content.attributes.package_name"></h1>

            <div class="spacer spacer-6"></div>
            <app-pharmacy
                    [id]="content.relationships.owner_pharmacy.data.id"
                    [drugTitle]="content.attributes.package_name"
                    [ownerId]="content.relationships.owner_uid.data.id"
            ></app-pharmacy>
        </div>

        <div class="medifinder-action-area  medifinder-action-area--padding" *ngIf="!content.attributes.own">
            <h2>{{"MEDIFINDER.DETAIL.HELP.TITLE" | translate}}</h2>
            <div class="spacer spacer-2"></div>
            <div class="medifinder-action-area__buttons">
                <app-button (action)="contact('phone')" [fullWidth]="true" label="{{'MEDIFINDER.DETAIL.HELP.CALL' | translate}}"></app-button>
                <app-button (action)="contact('email')" [fullWidth]="true" label="{{'MEDIFINDER.DETAIL.HELP.EMAIL' | translate}}"></app-button>
            </div>
            <div class="spacer-3"></div>
        </div>
        <div class="medifinder-action-area" *ngIf="content.attributes.own && annulationStep === AnnulationSteps.INIT">
            <app-big-button (action)="annulate(content.id)" [buttonType]="ButtonType.Primary"
                            [label]="'MEDIFINDER.DETAIL.ANNULATION.STEP1' | translate"></app-big-button>
        </div>
        <div class="medifinder-action-area  medifinder-action-area--padding" *ngIf="content.attributes.own && annulationStep === AnnulationSteps.CONFIRM">
            <h2>{{"MEDIFINDER.DETAIL.ANNULATION.TITLE" | translate}}</h2>
            <div class="spacer spacer-2"></div>
            <div class="medifinder-action-area__buttons">
                <app-button [buttonType]="ButtonType.Secondary" (action)="annulationStep = AnnulationSteps.INIT" [fullWidth]="true" label="{{'MEDIFINDER.DETAIL.ANNULATION.CANCEL' | translate}}"></app-button>
                <app-button (action)="annulate(content.id)" [fullWidth]="true" label="{{'MEDIFINDER.DETAIL.ANNULATION.CONFIRM' | translate}}"></app-button>
            </div>
            <div class="spacer-3"></div>
        </div>

        <div class="medifinder-action-area  medifinder-action-area--padding" *ngIf="content.attributes.own && annulationStep === AnnulationSteps.PROGRESS">
            <app-loading></app-loading>
        </div>

        <div class="medifinder-action-area medifinder-action-area--padding"  *ngIf="content.attributes.own && annulationStep === AnnulationSteps.SUCCESS">
            <div class="spacer-3"></div>
            <app-svg-icon
                    class="icon"
                    [src]="'icons/124/circle-check.svg'"
                    [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
            ></app-svg-icon>
            <div class="spacer-2"></div>
            <p class="header-title center">{{ 'MEDIFINDER.DETAIL.ANNULATION.SUCCESS.TITLE' | translate }}</p>
            <div class="spacer-3"></div>
            <app-button label="{{'MEDIFINDER.DETAIL.ANNULATION.BACK' | translate}}" (action)="backToOverview()" class="icon-button" src="icons/32/close.svg"></app-button>
            <div class="spacer-3"></div>
        </div>

    </ng-container>

    <ng-container *ngIf="entityError">
        <div class="entity-error content">
            <div class="spacer spacer-6"></div>
        <app-svg-icon
                class="icon"
                [src]="'icons/40/medifinder.svg'"
                [svgStyle]="{ 'width.px': '124', 'height.px': '124' }"
        ></app-svg-icon>
            <p>{{'MEDIFINDER.DETAIL.ENTITY_ERROR' | translate}}</p>
            <div class="spacer spacer-2"></div>
            <app-button (action)="SetAction({type: ButtonActionsType.Back}, backToTab)" [label]="'MEDIFINDER.DETAIL.ENTITY_ERROR_BACK'"></app-button>
        </div>
    </ng-container>
</div>

