import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppStore } from "../../../appStore";
import { State } from "./auth.reducer";

export const GetAuth = createFeatureSelector<any>("auth");

export const GetAuthStatus = createSelector(GetAuth, (state: State) => {
  return state.status;
});

export const GetAuthToken = createSelector(GetAuth, (state: State) => {
  return state.token;
});
