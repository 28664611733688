import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FilterButton } from "./filter";
import { Entity } from "../../../io/entity/types/entity";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";
import { debounceTime, Subject } from "rxjs";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, OnChanges {
  /**
   * Tab items that tab wrapper should display
   */
  @Input() items: Entity[] = [];

  /**
   * Text that should be displayed before label
   */
  @Input() preLabel: string = "";

  @Input() transformedItems: FilterButton[] | null = [];

  /**
   * list of ids which are active
   */
  @Input() initActiveItems: Array<string> = [];

  @Input() ignoreHidden: boolean = false;

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostBinding("class") get hostClasses() {
    const classList = ["filter"];
    return classList;
  }

  private output: Subject<any> = new Subject<any>();

  constructor() {}

  public ngOnInit() {
    if (this.items && this.items.length > 0) {
      this.transformedItems = this.items.map((item) => {
        return {
          label: item.attributes.name,
          active: !!this.initActiveItems.find(
            (initItem) =>
              initItem === item.attributes.drupal_internal__tid.toString()
          ),
          hidden: item.attributes.field_is_configurable === null,
          id: item.attributes.drupal_internal__tid,
        };
      });
    }

    this.output.pipe(debounceTime(1500)).subscribe((res) => {
      this.action.emit(res);
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["items"] && changes["items"].currentValue) {
      this.transformedItems = this.items.map((item) => {
        return {
          label: item.attributes.name,
          active: !!this.initActiveItems.find(
            (initItem) =>
              initItem === item.attributes.drupal_internal__tid.toString()
          ),
          hidden: item.attributes.field_is_configurable === null,
          id: item.attributes.drupal_internal__tid,
        };
      });

      // just to be sure that parent component has changes as well -> for the case that initActiveItems are already loaded before items
      this.action.emit({
        type: ButtonActionsType.Click,
        data: this.transformedItems,
      });
    }

    if (
      changes &&
      changes["initActiveItems"] &&
      changes["initActiveItems"].currentValue
    ) {
      this.initActiveItems.forEach((initItem) => {
        let foundItem = (
          this.transformedItems ? this.transformedItems : []
        ).find((item) => item.id.toString() === initItem);
        if (foundItem) foundItem.active = true;
      });
    }
  }

  public SetAction(event: ButtonOutput) {
    this.transformedItems = (
      this.transformedItems ? this.transformedItems : []
    ).map((item) => {
      return {
        label: item.label,
        active:
          item.label === event.data.label
            ? (item.active = event.data.active)
            : item.active,
        hidden: item.hidden,
        id: item.id,
      };
    });

    this.output.next({
      type: ButtonActionsType.Click,
      data: this.transformedItems,
    });
  }
}
