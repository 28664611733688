import {
  ViewService,
  ViewsExposedFilter,
  ViewsExposedUser,
} from "../view.service";
import { Actions, ofType } from "@ngrx/effects";
import { addEntities, addEntity } from "../store/entity.actions";
import { ApiEntityResponse } from "../types/api-entity-response";
import { isArray } from "lodash-es";
import { AppStore } from "../../../store/appStore";
import { Selector, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  Observable,
  Observer,
  Subscriber,
  Subscription,
  take,
} from "rxjs";
import { selectNewsPostEntitiesByIds } from "../store/entity.selector";
import { Entity } from "../types/entity";
import { CacheService } from "../cache.service";
import { CacheRefresh } from "../types/cache";
import { loadViewError } from "../store/view.actions";

export enum ViewHelperViewState {
  DEFAULT,
  LOADING,
  ERROR,
}

export class ViewHelper {
  private _obs: Observer<Array<Entity>>;
  private _selector: Selector<any, any> = selectNewsPostEntitiesByIds;
  private _connectedEntities: Array<string> = [];
  private _subscription;
  private _errorSubscription: Subscription;

  private _loadNotifierObservable: Subscriber<any>;

  constructor(
    private _id: string,
    private _actions$: Actions,
    private _viewService: ViewService,
    private _store: Store<AppStore>,
    private _cacheService: CacheService,
    private _purgeable: boolean = false
  ) {
    this._subscription = this._actions$
      .pipe(ofType(addEntities.type, addEntity.type))
      .subscribe((res: { url: string; raw: ApiEntityResponse }) => {
        if (!res || !res.url) return;

        const ownURL = this._viewService.resolveURI(
          this.viewId,
          this.viewDisplay,
          this.filter,
          this.user,
          this.page,
          this.include,
          true
        );

        if (this._debug) {
          console.log("*** own.url: ***");
          console.log(ownURL);
        }

        if (this._debug) {
          console.log("*** Res.url: ***");
          console.log(_viewService.stripPageFromURI(res.url));
        }

        if (ownURL === _viewService.stripPageFromURI(res.url)) {
          this.processViewResult(res.raw);

          this._loading = false;
        }
      });

    this._errorSubscription = this._actions$
      .pipe(ofType(loadViewError.type))
      .subscribe((res: { url: string; error: any }) => {
        if (!res || !res.url) return;

        if (this._debug) {
          console.log("*** Res.url: ***");
          console.log(_viewService.stripPageFromURI(res.url));
        }
        const ownURL = this._viewService.resolveURI(
          this.viewId,
          this.viewDisplay,
          this.filter,
          this.user,
          this.page,
          this.include,
          true
        );

        if (this._debug) {
          console.log("*** own.url: ***");
          console.log(ownURL);
        }
        if (ownURL === _viewService.stripPageFromURI(res.url))
          alert("Error loading view: " + res.error);
      });

    this._actions$.pipe(ofType("[Entity] purgeEntities")).subscribe((res) => {
      this.reload();
    });
  }

  //Defines if the viewHelper can be cleaned up, once it's not used anymore
  get purgeable(): boolean {
    return this._purgeable;
  }

  set purgeable(value: boolean) {
    this._purgeable = value;
  }

  get id(): string {
    return this._id;
  }

  private _viewId: string;

  get viewId(): string {
    return this._viewId;
  }

  private _viewDisplay: string;

  get viewDisplay(): string {
    return this._viewDisplay;
  }

  private _filter: Array<ViewsExposedFilter>;

  get filter(): Array<ViewsExposedFilter> {
    return this._filter;
  }

  private _user: ViewsExposedUser;

  get user(): ViewsExposedUser {
    return this._user;
  }

  private _page = 0;

  get page(): number {
    return this._page;
  }

  private _include: Array<string> = [];

  get include(): Array<string> {
    return this._include;
  }

  private _entriesPerPage = -1;

  get entriesPerPage(): number {
    return this._entriesPerPage;
  }

  private _totalEntities: number | undefined;

  get totalEntities(): number {
    return this._totalEntities ? this._totalEntities : 0;
  }

  private _loadedEntities: number = 0;

  get loadedEntities(): number {
    return this._loadedEntities;
  }

  private _pagerArray: Array<boolean> = [];

  get pagerArray(): Array<boolean> {
    return this._pagerArray;
  }

  private _state: ViewHelperViewState = ViewHelperViewState.DEFAULT;

  private _currentState: BehaviorSubject<ViewHelperViewState> =
    new BehaviorSubject<ViewHelperViewState>(this._state);
  public currentState = this._currentState.asObservable();

  get state(): ViewHelperViewState {
    return this._state;
  }

  public setView(viewId: string, viewDisplay: string) {
    this._viewId = viewId;
    this._viewDisplay = viewDisplay;
  }

  public setFilter(filter: Array<ViewsExposedFilter>) {
    this._filter = filter;
  }

  public setUser(user: ViewsExposedUser) {
    this._user = user;
  }

  public setInclude(include: Array<string>) {
    this._include = include;
  }

  private _debug = false;
  public setActive(
    debug = false,
    obs: Subscriber<{ time: number; elements: number }> | undefined = undefined,
    force = false
  ) {
    this._debug = debug;

    if (obs) {
      this.unsubscribeFromLoadNotifier();
      this._loadNotifierObservable = obs;
    }

    this._viewService.activeViewHelper = this;

    if (this._debug)
      console.log("ViewHelper: " + this.viewId + " is now active");
    this.loadNextPage(false, force);
  }

  public hasNextPage() {
    return this._loadedEntities < this.totalEntities;
  }

  /**
   * Returns the view
   */
  public getView$(selector: Selector<any, any> = selectNewsPostEntitiesByIds) {
    this._selector = selector;
    return new Observable<Array<Entity>>((observer) => {
      this._obs = observer;
      this.updateObserver();
    });
  }

  private _loading = false;
  public loadNextPage(increment: boolean = true, force = false): boolean {
    if (increment) this._page += 1;

    this._state = ViewHelperViewState.LOADING;
    this._currentState.next(this._state);

    const loading = this._viewService.loadView(
      this.viewId,
      this.viewDisplay,
      this.filter,
      this._user,
      undefined,
      force || typeof this._loadedEntities === "undefined"
        ? CacheRefresh.always
        : CacheRefresh.one_minute,
      this.page,
      this.include
    );

    //Set loading to true when the view is loading
    this._loading = loading;

    if (this._debug) console.log("Loading: " + loading);

    if (!loading) this._state = ViewHelperViewState.DEFAULT;

    return loading;
  }

  public reload() {
    this._page = 0;
    this._loadedEntities = 0;
    this._connectedEntities = [];

    this._totalEntities = undefined;
  }

  private processViewResult(raw: ApiEntityResponse) {
    //implicitly set the total entities to the count of the result and if not returned, to 0 to show that there are no entities

    this._totalEntities = raw.meta.count ? raw.meta.count : 0;

    //on the initial call, sett the entries per page to the loaded value
    if (this._loadedEntities === 0)
      this._entriesPerPage = isArray(raw.data) ? raw.data.length : 1;

    if (this._entriesPerPage != 0) {
      const cache = this._cacheService.retrieveFromCache(
        this._viewService.resolveURI(
          this.viewId,
          this.viewDisplay,
          this.filter,
          this.user,
          this.page,
          this.include,
          true
        )
      );

      this._connectedEntities = cache.cache?.data ? cache.cache.data : [];

      this._connectedEntities = [...new Set(this._connectedEntities)];

      this._loadedEntities = this._connectedEntities.length;

      this._state = ViewHelperViewState.DEFAULT;
      this._currentState.next(this._state);

      this._pagerArray = [];
      for (
        let i = 0;
        i < Math.ceil(this._totalEntities / this._entriesPerPage);
        i++
      ) {
        this._pagerArray.push(this._loadedEntities / this._entriesPerPage > i);
      }
    }

    this.updateObserver();
  }

  private updateObserver() {
    //if the observer is set, and the view is active, and there are entities to show, update the observer
    //scenarios:
    // - update entities is called before a view is loaded, so the total entities is not set yet -> there is no .next and the loading indicator is shown
    // - update entities is called after a view is loaded, so the total entities is set -> the observer is updated and shows the result
    // - update entities is called and the view is empty (e.g. no result) -> the observer is updated and shows an empty array because the total entities is 0 and not undefined

    if (this._obs && typeof this._totalEntities !== "undefined") {
      this._store
        .select(this._selector(this._connectedEntities))
        .pipe(take(1))
        .subscribe((res: any) => {
          this._obs.next(res as Array<Entity>);
        });
    } else if (typeof this._totalEntities === "undefined" && !this._loading) {
      console.error("ViewHelper: " + this.viewId + " has no entities to show");

      if (this._loadNotifierObservable) this._loadNotifierObservable.next(0);

      this.loadNextPage(false, true);
    }

    if (this._loadNotifierObservable) {
      try {
        setTimeout(() => {
          this._loadNotifierObservable.next({
            time: new Date().getTime(),
            elements: this._totalEntities,
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  public onPurge() {
    try {
      this._subscription.unsubscribe();
    } catch (e) {
      console.error(e);
    }
  }

  private _lockNextLoading = false;
  public nextEntity(
    currentEntity: string,
    obs: Subscriber<any> | undefined = undefined
  ) {
    const index = this._connectedEntities.indexOf(currentEntity);

    if (index === this._connectedEntities.length - 1) {
      if (!this._lockNextLoading && this.hasNextPage()) {
        if (obs) {
          this.unsubscribeFromLoadNotifier();
          this._loadNotifierObservable = obs;
        }
        this.loadNextPage(true);
      } else if (!this.hasNextPage()) {
        this.unsubscribeFromLoadNotifier();
      }
      return undefined;
    }

    this._lockNextLoading = false;
    this.unsubscribeFromLoadNotifier();

    return this._connectedEntities[index + 1];
  }

  private unsubscribeFromLoadNotifier() {
    try {
      if (this._loadNotifierObservable) {
        this._loadNotifierObservable.unsubscribe();
      }
    } catch (e) {}
  }

  public previousEntity(currentEntity: string) {
    const index = this._connectedEntities.indexOf(currentEntity);

    if (index == 0) return undefined;

    return this._connectedEntities[index - 1];
  }
}
