import { Component, ViewEncapsulation } from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";

@Component({
  selector: "app-info-modal",
  templateUrl: "./info-modal.component.html",
  styleUrls: ["./info-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InfoModalComponent extends BaseModalComponent {
  constructor() {
    super();
  }
}
