import { Component } from "@angular/core";
import { AuthService } from "../../store/status/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, take } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  private _redirectURI = "/app/dashboard";

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.queryParams.subscribe((params) => {
      this._redirectURI = params["redirect"]
        ? decodeURIComponent(params["redirect"])
        : "/app/dashboard";
    });

    this._authService
      .isAuthenticated()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.goToDefault();
        }
      });
  }

  private goToDefault() {
    this._router.navigateByUrl(this._redirectURI);
  }

  public authenticate() {
    const obs = new Observable((obs) => {
      this._authService.authenticate(obs);
    });

    obs.subscribe((result) => {
      if (result) {
        this.goToDefault();
      }
    });
  }
}
